let Data = [
  {
    id: 1,
    img: "/img1.png",
    name: "NATURE",
  },
  {
    id: 2,
    img: "/img2.png",
    name: "PILGRIMAGE",
  },
  {
    id: 3,
    img: "/img3.png",
    name: "WILDLIFE",
  },
  {
    id: 4,
    img: "/img4.png",
    name: "ADVENTURE",
  },
  {
    id: 5,
    img: "/img5.png",
    name: "HONEYMOON",
  },
  {
    id: 6,
    img: "/img6.png",
    name: "DELHI",
  },
  {
    id: 7,
    img: "/img7.png",
    name: "GOA",
  },
  {
    id: 8,
    img: "/img8.png",
    name: "MASOORI",
  },
  {
    id: 9,
    img: "/img9.png",
    name: "GUJRAT",
  },
  {
    id: 10,
    img: "/img10.png",
    name: "LADAKH",
  },
];

export default Data;
