import React, { useRef } from "react"
import "./css/index1.css"
import Header from "./Header"
import { Button, Container, Form, Modal, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaintBrush, faPlane } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { login } from "../Vendor/Re_values"
import { NavLink } from "react-router-dom"
import { useEffect } from "react"
import { BASE_URL } from "../BASE_URL"
import data from ".././CountryStateCity.json"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Footer from "./Footer"
import { Box, Stack, TextField } from "@mui/material"
import Badge from "@mui/material/Badge"
import { styled } from "@mui/material/styles"
import Avatar from "@mui/material/Avatar"
import EditIcon from "@mui/icons-material/Edit"

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}))

function Profile(props) {
  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setValidDetails("") // Clear the error message
    setShow(true)
  }

  const avtarRef = useRef()

  const [selectedCountry, setSelectedCountry] = useState("India")
  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState("")

  const countries = data.find((obj) => obj.name === "India")
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const [userProfile, setUserProfile] = useState({
    name: "",
    email_address: "",
    city: "",
    state: "",
    address: "",
    photo: "",
  })

  const [profilePhoto, setProfilePhoto] = useState(userProfile.photo)
  const [profilePhotoUrl, setProfilePhotoUrl] = useState("")

  const [error, setError] = useState({
    name: "",
    email_address: "",
    city: "",
    state: "",
    address: "",
  })

  const txt = (e) => {
    const { name, value } = e.target
    setUserProfile({ ...userProfile, [name]: value })
    setError({ ...error, [name]: "" })
    if (name === "state") {
      setSelectedState(value)
      setSelectedCity("")
      setUserProfile({ ...userProfile, city: "" })
    } else if (name === "city") {
      setSelectedCity(value)
    } else if (
      name === "email_address" &&
      !emailRegex.test(userProfile.email_address)
    ) {
      setError({ ...error, email_address: "Invalid Email Address" })
    }
  }

  const [userProfile2, setUserProfile2] = useState({
    phone: "",
    password: "",
  })

  const Profile = async () => {
    const token = localStorage.getItem("userToken")
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setUserProfile2(data.data[0])
    setUserProfile(data.data[0].user_details[0])
    setSelectedState(data.data[0].user_details[0].state)
    setSelectedCity(data.data[0].user_details[0].city)
    setProfilePhotoUrl(data.data[0].user_details[0].photo)
  }

  useEffect(() => {
    Profile()
  }, [])

  const btnn = async (e) => {
    e.preventDefault()

    const { name, email_address, city, state } = userProfile
    const { phone, password } = userProfile2

    if (name.trim() === "" || !name) {
      setError({ ...error, name: "Please Enter Full Name." })
      return
    }
    if (!email_address) {
      setError({ ...error, email_address: "Please Enter Email Id." })
      return
    }
    if (!phone) {
      setError({ ...error, phone: "Please Enter Mobile Number." })
      return
    }
    if (!state) {
      setError({ ...error, state: "Please Select State." })
      return
    }
    if (!city) {
      setError({ ...error, city: "Please Select City." })
      return
    }

    if (!name || !email_address || !phone || !city || !state) {
      toast.error("Please fill all fields!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!selectedCity) {
      toast.error("Please fill all fields!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!emailRegex.test(email_address)) {
      setError({ ...error, email_address: "Invalid email address!" })
      toast.error("Invalid email address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const token = localStorage.getItem("userToken")



    const formData = new FormData()
    if (profilePhoto) {
      formData.append('photo', profilePhoto)
    }
    formData.append("name", name)
    formData.append("email_address", email_address)

    formData.append("phone", Number(phone))

    formData.append("password", password)

    formData.append("city", selectedCity)
    formData.append("state", selectedState)

    // formData.append("address", address)

    const res = await fetch(`${BASE_URL}user/changeprofile`, {
      method: "PUT",
      headers: {
        Authorization: token,
      },
      // body: JSON.stringify({
      //   name,
      //   email_address,
      //   phone: Number(phone),
      //   password,
      //   city: selectedCity,
      //   state: selectedState,
      //   // address,
      //   photo: profilePhoto,
      // }),
      body:formData,
    })
    const data = await res.json()

    if (data.code === 200) {
      toast.success('Profile Updated', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  }

  const [password, setPassword] = useState({
    old_password: "",
    npass: "",
    new_password: "",
  })

  const [validDetails, setValidDetails] = useState("")

  const pass = (e) => {
    const { name, value } = e.target
    setPassword({ ...password, [name]: value })
  }

  const changePassword = async (e) => {
    e.preventDefault()
    const token = localStorage.getItem("userToken")

    const { old_password, npass, new_password } = password

    if (!old_password && !npass && !new_password) {
      setValidDetails("Please Enter Your Old Password")
      return
    }
    if (!old_password) {
      setValidDetails("Please Enter Your Old Password")
      return
    }
    if (!npass) {
      setValidDetails("Please Enter New Password")
      return
    }
    if (npass.length < 6) {
      setValidDetails("New Password Must Be 6 Character")
      return
    }
    if (!new_password) {
      setValidDetails("Please Enter Confirm Password")
      return
    }

    if (old_password === npass) {
      setValidDetails("Please Enter Old and New Password Different.")
      return
    }
    if (npass !== new_password) {
      setValidDetails("New Password And Confirm password Not Match.")
      return
    }

    const res = await fetch(`${BASE_URL}user/updatepassword`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        old_password,
        new_password,
      }),
    })

    const data = await res.json()

    if (data.code === 200) {
      handleClose()
      toast.success("Password Change Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    } else {
      setValidDetails(data.message)
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  const handleBooking = () => {
    navigate("/my_package")
  }

  const userToken = localStorage.getItem("userToken")

  const handlePhotoChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()

    setProfilePhoto(file)

    reader.onload = (event) => {
      setProfilePhotoUrl(event.target.result)
    }

    reader.readAsDataURL(file)
  }


  useEffect(() => {
    if (!userToken) {
      navigate("/")
    }
  }, [])

  return (
    <>
      <Header />
      <section className='py-3'>
        <Container>
          <div className='text-center'>
            <img src='profile.png' className='img-fluid w-100' alt='profile' />
          </div>
          <div className='py-sm-4 py-2'>
            <Row>
              <div className='col-lg-6 col-md-6 col-12'>
                <div className='cmn my-1 cmnpointer'>
                  <NavLink
                    to='/my_package'
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <p
                      className='cmnp p-2 text-center text20'
                      style={{ fontWeight: "600" }}
                    >
                      <FontAwesomeIcon icon={faPlane} /> My Booking
                    </p>
                  </NavLink>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-12' onClick={handleShow}>
                <div className='cmn my-1 cmnpointer'>
                  <p
                    className='cmnp p-2 text-center text20'
                    style={{ fontWeight: "600" }}
                  >
                    <FontAwesomeIcon icon={faPaintBrush} /> Change Password
                  </p>
                </div>
              </div>
            </Row>
          </div>
          <div className='py-2'>
            <div className='cmn py-sm-4 py-0 px-sm-4 px-3'>
              <div className='pt-2 pb-sm-2 pb-0 '>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  width='100%'
                  justifyContent={{ xs: "center", md: "space-between" }}
                  alignItems='center'
                >
                  <h1 className='media-edit-profile text24 font700'>Edit profile</h1>

                  <Box sx={{ position: "relative" }}>
                    <Avatar
                      alt=''
                      src={profilePhotoUrl}
                      sx={{ width: 100, height: 100 }}
                    />

                    <Box>
                      <input
                        type='file'
                        accept='image/*'
                        onChange={handlePhotoChange}
                        style={{ display: "none" }}
                        id='fileInput'
                      />
                      <label
                        htmlFor='fileInput'
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          transform: "rotate(-90deg)",
                          bottom: 0,
                          right: 0,
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          background: "#09646d",
                          color: "white",
                        }}
                      >
                        <EditIcon
                          fontSize='small'
                          sx={{
                            position: "relative",
                            top: "2px",
                            right: "-5px",
                          }}
                        />
                      </label>
                    </Box>
                  </Box>
                </Stack>
              </div>
              <ToastContainer />
              <div className=''>
                <Form>
                  <div className='pt-sm-2 pt-0 pb-sm-2 pb-2'>
                    <div className=''>
                      <div className='py-1'>
                        <p className='mb-1 text14 font600'>Full Name</p>
                        <Form.Control
                          type='text'
                          id='name'
                          name='name'
                          value={userProfile.name}
                          // onChange={txt}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z ]/g,
                              "",
                            )
                            txt(e)
                          }}
                        />
                        {error.name && (
                          <p className='text-danger mb-0'>{error.name}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='pt-sm-2 pt-0 pb-sm-2 pb-2'>
                    <p className='mb-1 text14 font600'>Email id</p>
                    <Form.Control
                      type='text'
                      id='email'
                      name='email_address'
                      value={userProfile.email_address}
                      onChange={txt}
                    />
                    {error.email_address && (
                      <p className='text-danger mb-0'>{error.email_address}</p>
                    )}
                  </div>
                  <div className='pt-sm-2 pt-0 pb-sm-2 pb-2'>
                    <p className='mb-1 text14 font600'>Mobile No</p>
                    <Form.Control
                      type='Number'
                      name='phone'
                      value={userProfile2.phone}
                    />
                  </div>
                  <div>
                    <Row className='pt-sm-2 pt-0'>
                      <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className=''>
                          <div className='py-1'>
                            <p className='mb-1 text14 font600'>Country</p>
                            <select
                              onChange={txt}
                              value={selectedCountry}
                              name='country'
                              id='country'
                              disabled
                              className='cmnp w-100'
                              style={{
                                padding: ".375rem 0.75rem",
                                borderRadius: "5px",
                              }}
                            >
                              <option value=''>Select Country</option>
                              {data &&
                                data.map((e) => {
                                  return (
                                    <option value={e.name}>{e.name}</option>
                                  )
                                })}
                            </select>

                            {/* {error.state && (
                              <p className='text-danger mb-0'>{error.state}</p>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className=''>
                          <div className='py-1'>
                            <p className='mb-1 text14 font600'>State</p>
                            <select
                              onChange={txt}
                              value={selectedState}
                              name='state'
                              id='state'
                              className='cmnp w-100'
                              style={{
                                padding: ".375rem 0.75rem",
                                borderRadius: "5px",
                              }}
                            >
                              <option value=''>Select State</option>
                              {countries.states &&
                                countries.states.map((e) => {
                                  return (
                                    <option value={e.name}>{e.name}</option>
                                  )
                                })}
                            </select>

                            {error.state && (
                              <p className='text-danger mb-0'>{error.state}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-6 col-sm-6'>
                        <div className=''>
                          <div className='py-1'>
                            <p className='mb-1 text14 font600'>City</p>
                            <select
                              value={selectedCity}
                              name='city'
                              onChange={txt}
                              id='city'
                              className='cmnp w-100'
                              style={{
                                padding: ".375rem 0.75rem",
                                borderRadius: "5px",
                              }}
                            >
                              <option value=''>Select City</option>
                              {countries.states &&
                                countries.states
                                  .filter(
                                    (state) =>
                                      state.name.toLowerCase() ===
                                      selectedState.toLowerCase(),
                                  )
                                  .map((state) =>
                                    state.cities.map((city) => (
                                      <option key={city.id} value={city.name}>
                                        {city.name}
                                      </option>
                                    )),
                                  )}
                            </select>
                            {error.city && (
                              <p className='text-danger mb-0'>{error.city}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                  {/* <div className='pt-sm-2 pt-0 pb-sm-2 pb-2'>
                    <p className='mb-1 text14 font600'>Address</p>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='address'
                      id='address'
                      value={userProfile.address}
                      onChange={txt}
                    />
                    {error.address && (
                      <p className='text-danger mb-0'>{error.address}</p>
                    )}
                  </div> */}
                  <div className='mt-sm-4 mt-2'>
                    <Row>
                      <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <NavLink
                          to='/'
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <div className='cmn my-1'>
                            <p className='cmnp p-2 text-center text16 cmnpointer'>
                              Cancel
                            </p>
                          </div>
                        </NavLink>
                      </div>
                      <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                        <div className='cmn my-1'>
                          <button
                            className='cmnp p-2 text-center w-100 text16'
                            style={{
                              background: "#09646D",
                              color: "#FFF",
                              cursor: "pointer",
                              borderRadius: "10px",
                            }}
                            onClick={btnn}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <div>
        <div>
          {/* <button onClick={handleShow}>Change Password</button> */}

          <Modal show={show} onHide={handleClose}>
            <Modal.Body>
              <div>
                <Container>
                  <div className='common p-sm-4 p-0'>
                    <Form>
                      <div className='media-new-password'>
                        <h2 className="text20 font700">Set New Password</h2>
                      </div>
                      <div className='py-2'>
                        <p className='mb-1 text14 font600'>Old Password</p>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className='w-100 p-1 ps-2'
                            name='old_password'
                            style={{
                              borderRadius: "5px",
                              border: "1px solid lightgrey",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                                "",
                              )
                            }}
                            onChange={pass}
                          />
                          <img
                            src={passwordVisible ? "/eye.png" : "/eye2.png"}
                            className='eye-image'
                            alt=''
                            style={{
                              height: "10px",
                              width: "15px",
                              position: "absolute",
                              top: "0",
                              bottom: "0",
                              right: "5%",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className='py-2'>
                        <p className='mb-1 text14 font600'>New Password</p>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type={newPasswordVisible ? "text" : "password"}
                            className='w-100 p-1'
                            name='npass'
                            style={{
                              borderRadius: "5px",
                              border: "1px solid lightgrey",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                                "",
                              )
                            }}
                            onChange={pass}
                          />

                          <img
                            src={newPasswordVisible ? "/eye.png" : "/eye2.png"}
                            className='eye-image'
                            alt=''
                            style={{
                              height: "10px",
                              width: "15px",
                              position: "absolute",
                              top: "0",
                              bottom: "0",
                              right: "5%",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                            onClick={toggleNewPasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className='py-2'>
                        <p className='mb-1 text14 font600'>Confirm New Password</p>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <input
                            type={confirmPasswordVisible ? "text" : "password"}
                            className='w-100 p-1'
                            name='new_password'
                            style={{
                              borderRadius: "5px",
                              border: "1px solid lightgrey",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                                "",
                              )
                            }}
                            onChange={pass}
                          />

                          <img
                            src={
                              confirmPasswordVisible ? "/eye.png" : "/eye2.png"
                            }
                            className='eye-image'
                            alt=''
                            style={{
                              height: "10px",
                              width: "15px",
                              position: "absolute",
                              top: "0",
                              bottom: "0",
                              right: "5%",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                            onClick={toggleConfirmPasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-center w-100'>
                        {validDetails && (
                          <span
                            className='mb-0'
                            style={{ color: "red", fontSize: "12px" }}
                          >
                            {validDetails}
                          </span>
                        )}
                      </div>
                      <div className='pt-sm-4 pt-2 text-center row gy-2'>
                        <div className='px-1 col-sm-6 col-6'>
                          <button
                            type='button'
                            variant='secondary'
                            className='w-100 text-white py-2 text16 media-change-pass-button'
                            onClick={handleClose}
                            style={{
                              backgroundColor: "#6c757d",
                              borderRadius: "5px",
                              border: "none",
                            }}
                          >
                            Cancel
                          </button>
                        </div>

                        <div className='px-1 col-sm-6 col-6'>
                          <button
                            type='submit'
                            variant='primary'
                            className='w-100 text-white py-2 text16 media-change-pass-button'
                            onClick={changePassword}
                            style={{
                              backgroundColor: "#155E75",
                              borderRadius: "5px",
                              border: "none",
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Container>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <hr />
      <Footer />
    </>
  )
}

export default Profile
