import React, { useEffect, useState } from "react"
import {
  faIndianRupeeSign
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Book_packega1 from "./Book_packega1"
import Book_packega2 from "./Book_packega2"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import Footer from "../Project/Footer"
import ProfileSection from "./SecondNavbar"

function Book_packega_details(props) {
  const navigate = useNavigate()

  const [details, setDetails] = useState([])
  const { id } = useParams()

  const DisplayBookPackageDetails = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(
      `${BASE_URL}bookpackage/bookpackagedetail_jaydev?_id=${id}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      },
    )
    const data = await res.json()
    setDetails(data.data)
  }

  useEffect(() => {
    DisplayBookPackageDetails()
  }, [])

  // const [details, setDetails] = useState([])

  // const { id } = useParams()

  // const DisplayBookPackageDetails = async () => {
  //   const token = localStorage.getItem("vendorToken")
  //   const res = await fetch(
  //     `${BASE_URL}bookpackage/bookpackagedetail?_id=${id}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: token,
  //         "Content-Type": "application/json",
  //       },
  //     },
  //   )
  //   const data = await res.json()
  //   setDetails(data.data)
  // }

  // useEffect(() => {
  //   DisplayBookPackageDetails()
  // }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div style={{ backgroundColor: "whitesmoke" }}>

          <ProfileSection title="Booked Package Details" />

          <Tabs className='mt-3 cba'>
            <Tab eventKey='home' title='Package Details'>
              <Book_packega1 details={details} />
            </Tab>

            <Tab eventKey='profile' title='Payment Details'>
              <Book_packega2 details={details} />
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <hr />
      <Footer/> */}
    </>
  )
}

export default Book_packega_details
