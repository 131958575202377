import {
    faArrowRight,
    faBus,
    faCar,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faIndianRupeeSign,
    faPlane,
    faStar,
    faTrain,
    faUser,
    faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState, useEffect, useRef } from "react"
import { Form, Modal, Row } from "react-bootstrap"
import Header from "./Header"
import "./css/index1.css"
import { useLocation, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { faTrainSubway, faBinoculars, faHotel, faUtensils, faTaxi } from "@fortawesome/free-solid-svg-icons"
import addressdata from "../CountryStateCity.json"
import Footer from "./Footer"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import Destination1 from "./Destination1"

function BookedCarDetails(props) {
    const overRef = useRef(null)

    const [show, setShow] = useState(false)
    const navigate = useNavigate();

    const [show1, setShow1] = useState(false)

    const handleClose1 = () => setShow1(false)
    const handleShow1 = () => setShow1(true)

    const { id } = useParams()
    const { merchentId } = useParams()
    const [pDetails, setPDetails] = useState([])

    const CarDetails = async () => {
        const token = localStorage.getItem("userToken");

        const res = await fetch(
            `${BASE_URL}car_booking_syt/displayDetails?car_booking_id=${id}`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            },
        )
        const data = await res.json()
        setPDetails(data.data)
    }

    useEffect(() => {
        CarDetails()
    }, [])

    return (
        <div>
            <ToastContainer />
            <Header setShow={setShow} show={show} />
            {pDetails.map((ele) => {
                return (
                    <>
                        <section className='container-customes padding-costume-container py-xl-4 py-sm-4 py-2'>
                            <div className='row'>
                                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                                    <div>
                                        <img
                                            src={ele.vendor_car_details[0].photos[0]}
                                            alt=''
                                            className='w-100 rounded h-md-50 meadia-for-package-details-img'
                                        />
                                    </div>
                                    <section>
                                        <div className=''>
                                            <div className='overview-btn mb-4' ref={overRef}>
                                                <button className='mb-3 mt-4'>Overview</button>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Insurance: </span>{ele.insurance ? "YES" : "NO"}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Registration Number: </span>{ele.registration_number}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Colour: </span> {ele.color}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>AC: </span> {ele.AC ? "YES" : "NO"}
                                                </div>
                                                <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                    <span>Number of Seats: </span>{ele.vendor_car_details[0].car_details[0].car_seats}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second'>
                                    <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3'>
                                        <div className='inner_packega'>
                                            <div className=''>
                                                <div className='border_bottom'>
                                                    <p className='text-19 mb-1'>{ele.vendor_car_details[0].car_details[0].car_name}</p>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            <p className='mb-0 mt-2'>
                                                                <button class='button-13' role='button'>
                                                                    {ele.vendor_car_details[0].car_details[0].fuel_type}
                                                                </button>
                                                            </p>
                                                            <p className='mb-2 ms-1 fw-bold'>
                                                                {ele.vendor_car_details[0].car_details[0].car_type}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {ele.price_per_km}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        Per Km
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div>
                                                            <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {ele.price_per_day}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        Per Day
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Pickup Address:</strong> {ele.pickup_address}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Drop Address:</strong> {ele.drop_address}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Pickup Date:</strong> {new Date(ele.pickup_date).toLocaleDateString()}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Pickup Time:</strong> {ele.pickup_time}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Return Date:</strong> {new Date(ele.return_date).toLocaleDateString()}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Status:</strong> {ele.status}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>User Name:</strong> {ele.name}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Email:</strong> {ele.email}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Mobile Number:</strong> {ele.mobile_number}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Gender:</strong> {ele.gender}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>City:</strong> {ele.city}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>State:</strong> {ele.state}</p>
                                                <p className='mb-2' style={{marginLeft:"8px"}}><strong>Pincode:</strong> {ele.pincode}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>
                )
            })}

            <hr />
            <Footer />
        </div>
    )
}

export default BookedCarDetails;
