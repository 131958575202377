import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import {
  faArrowLeft,
  faBell,
  faBrush,
  faCheck,
  faCheckCircle,
  faClipboard,
  faEnvelopeOpen,
  faLayerGroup,
  faLocationPin,
  faStar,
  faUser,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Header from "../Project/Header"
import My_pannel from "./My_pannel"
import Header2 from "./Header2"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import ProfileSection from "./SecondNavbar"


const rightDate = (dateString) => {
  const [year, month, day] = dateString?.split('-');
  return `${day}-${month}-${year}`;
};

function Booked_packega(props) {
  const [bookData, setBookData] = useState([])
  const navigate = useNavigate()

  const displayBookPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bookpackage/bookpackageforagency_jaydev`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBookData(data.data)
  }

  useEffect(() => {
    displayBookPackage()
  }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  const uniqueValues = new Set()

  const handleNavigate = (packageId) => {
    navigate(`/vendor/Book-packega/details/${packageId}`)
  }

  console.log(bookData)

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div style={{ backgroundColor: "whitesmoke" }}>
          <ProfileSection title="Booked Package List" />

          <section className='vendor_booked_packega_margin'>
            <div className="row">
              {bookData && bookData.map((ele, index) => {
                return (
                  <>
                    <div className='col-6' key={ele._id}>
                      <section className='pt-2 pb-3'>
                        <div>
                          <Row className='mx-auto'>
                            <div className='col-xl-12 col-lg-6 col-sm-12 col-12 m-0 p-0'>
                              <div className="inner_CuStom_package">
                                <p className='CuStom_package_header text-capitalize text22 font700 py-2'>
                                </p>
                                <div className='booking-list-grid h-100 w-full px-3 py-3'>
                                  <img
                                    src='/C-packega.png'
                                    alt='img'
                                    className='booking-list-header-image'
                                  />
                                  <div className='h-auto w-100'>
                                    <div className='CuStom_package_content my_package_content'>
                                      <div className="d-flex justify-content-between">
                                        <p className='mb-xl-2 mb-lg-0 text14'>
                                          <span className="text18 font600">{ele?.title}</span>
                                        </p>
                                        {/* <div className='text-end mb-2'>
                                        <span
                                          className='text-end'
                                          style={{
                                            color: "white",
                                            padding: "5px 6px",
                                            fontSize: "12px",
                                            borderRadius: "8px",
                                            background: cancelledBookingId.includes(ele?._id)
                                              ? "red"
                                              : "green",
                                          }}
                                        >
                                          {cancelledBookingId.includes(ele?._id)
                                            ? "Booking Cancelled"
                                            : "Booking Confirmed"}
                                        </span>
                                      </div> */}


                                      </div>

                                      <p className='mb-xl-2 mb-lg-0 text14'>
                                        <span className="text14 font600">Booking Id</span> : {ele?._id}
                                      </p>
                                      <p className='mb-xl-2 mb-lg-0 text14'>
                                        <span className="text14 font600">Package Type</span> : {ele?.packageDetails?.length > 0 ? "Ready Package" : "Bid Package"}
                                      </p>
                                      <p className='mb-xl-2 mb-lg-0 text14'>
                                        <span className="text14 font600">Booking Date</span> : {rightDate(ele?.bookdate?.slice(0, 10))}
                                      </p>
                                      <p className='mb-xl-2 mb-lg-0 text14'>
                                        <span className="text14 font600">Trip Duration</span> :{" "}
                                        {ele?.approx_start_date ? rightDate(ele.approx_start_date) : "N/A"}{" "}
                                        to {ele?.approx_end_date ? rightDate(ele.approx_end_date) : "N/A"}
                                      </p>
                                      <div className='d-flex justify-content-between mb-2 mt-3'>
                                        <button
                                          className='button-9090'
                                          onClick={() => handleNavigate(ele?._id)}
                                          style={{ boxShadow: "none" }}
                                        >
                                          View Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </section>

                    </div>
                  </>
                )
              })}
            </div>

          </section>
        </div>
      </div>
    </>
  )
}

export default Booked_packega
