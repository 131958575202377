import React, { useEffect, useState } from "react"
import Header from "./Header"
import Section1 from "./Section1"
import Footer from "./Footer"
import { useNavigate } from "react-router-dom"

function Index(props) {
  const [show, setShow] = useState(false)

  return (
    <>
      <Header setShow={setShow} show={show} />
      <Section1 setShow={setShow} />
      <Footer />
      {/* <Coming_soon/> */}
    </>
  )
}

export default Index
