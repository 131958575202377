import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../BASE_URL";
import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

const ViewCarBooking = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const { id } = useParams();

    const [bookingData, setBookingData] = useState({});

    const getBookedCar = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}car_booking_syt/vendorBookedCarDetails?booked_car_id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookingData(data.data[0]);
        console.log(data.data[0]);
        setBookingStatus(data.data[0].status);

    };

    useEffect(() => {
        getBookedCar();
    }, []);

    const [bookingStatus, setBookingStatus] = useState();

    const handleStatusChange = (e) => {
        setBookingStatus(e.target.value);
    };


    const handleUpdateStatus = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}car_booking_syt/updateStatus?_id=${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: bookingStatus
            }),
        });

        if (!res.ok) {
            throw new Error(`Failed to update status `);
        }
        else {
            toast.success("Status Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            getBookedCar();
            return res.json();
        }

    };

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                    <ProfileSection title="Car Bookings" />

                    <section className="booked_packega_margin p-4">
                        {Object.keys(bookingData).length !== 0 && <div>
                            <div className="booked-details">
                                <div className="booked-hotel-details-container">
                                    <div className='booked-details-title'>Car Details</div>
                                    {
                                        bookingData.vendor_car_details ? (
                                            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                                <div className="booked-details-name"><span className="cust-details-headings">Car Name:</span> {bookingData.vendor_car_details[0].car_details[0].car_name}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Fuel Type: </span> {bookingData.vendor_car_details[0].car_details[0].fuel_type}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Car Type: </span> {bookingData.vendor_car_details[0].car_details[0].car_type}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Car Color: </span> {bookingData.vendor_car_details[0].color}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Model Year: </span> {bookingData.vendor_car_details[0].model_year}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Price per KM: </span> {bookingData.vendor_car_details[0].price_per_km}</div>
                                                <div className="booked-details-address"><span className="cust-details-headings">Price per Day: </span> {bookingData.vendor_car_details[0].price_per_day}</div>
                                            </div>
                                        ) : (
                                            <div>N/A</div>
                                        )
                                    }
                                </div>
                                <div className="booked-user-details-container">
                                    <div className='booked-details-title'>Customer Details</div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                        <div><span className="cust-details-headings">Customer Name:</span> {bookingData.customer_details ? bookingData.customer_details[0].name : "N/A"}</div>
                                        <div><span className="cust-details-headings">Email:</span> {bookingData.customer_details ? bookingData.customer_details[0].email_address : "N/A"}</div>
                                        <div><span className="cust-details-headings">Mobile No.:</span> {bookingData.mobile_number}</div>
                                        <div><span className="cust-details-headings">Gender:</span> {bookingData.gender}</div>
                                        <div><span className="cust-details-headings">State:</span> {bookingData.customer_details ? bookingData.customer_details[0].state : "N/A"}</div>
                                    </div>
                                </div>
                                <div className="booked-status-container">
                                    <div className='booked-details-title'>Booked Status</div>
                                    <div className='booked-status'>{bookingData.status === 'pending' ? "Pending" : (bookingData.status === "approve" ? "Approved" : "Rejected")}</div>
                                    <div style={{ paddingTop: "10px", borderTop: "1px solid #09646D" }}>
                                        <div className="update-status-title">Update Status:</div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <select value={bookingStatus} onChange={handleStatusChange} className="update-status-select">
                                                <option value="pending">Pending</option>
                                                <option value="approve">Approve</option>
                                                <option value="reject">Reject</option>
                                            </select>
                                            <button onClick={handleUpdateStatus} className="update-status-button">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='booked-room-details'>Trip Details</div>
                                <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                                    <div><span className="cust-details-headings">Pickup Address:</span> {bookingData.pickup_address}</div>
                                    <div><span className="cust-details-headings">Drop Address:</span> {bookingData.drop_address}</div>
                                    <div><span className="cust-details-headings">Pickup Date:</span> {new Date(bookingData.pickup_date).toLocaleDateString()}</div>
                                    <div><span className="cust-details-headings">Pickup Time:</span> {bookingData.pickup_time}</div>
                                    <div><span className="cust-details-headings">Return Date:</span> {new Date(bookingData.return_date).toLocaleDateString()}</div>
                                    <div><span className="cust-details-headings">Price Type:</span> {bookingData.price_type}</div>
                                    <div><span className="cust-details-headings">Price per KM:</span> {bookingData.price_per_km}</div>
                                    <div><span className="cust-details-headings">Price per Day:</span> {bookingData.price_per_day}</div>
                                </div>
                            </div>
                            <div className="booked-rooms-container">

                            </div>
                        </div>}
                    </section>
                </div>
            </div>
        </>
    );
};

export default ViewCarBooking;
