import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpen,
  faUser,
  faPlus,
  faTrash,
  faClose,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import { Button, Modal, Form } from 'react-bootstrap';
import MapComponent from "./MapComponent";
import RoomCard from "./RoomCard";
import addressdata from ".././CountryStateCity.json"
import { Policy } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar";
import loader from "../images/loading.png";
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

function getTomorrowDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // Set date to tomorrow

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(tomorrow.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};

function Hotels() {
  const navigate = useNavigate();

  const Call = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setEditedData(data?.data?.[0]);
  };

  useEffect(() => {
    Call();
  }, []);

  const handleNav = () => {
    navigate("/vendor/myprofile");
  };


  const [editedata, setEditedData] = useState("");

  const [hotelId, setHotelId] = useState("");
  const [hotelData, setHotelData] = useState({
    hotel_name: "",
    hotel_address: "",
    hotel_description: "",
    hotel_type: "",
  });


  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState("")
  const [otherDetails, setOtherDetails] = useState(['']);


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      setSelectedState(value)
      setSelectedCity("")
    } else if (name === "city") {
      setSelectedCity(value)
    } else {
      setHotelData({
        ...hotelData,
        [name]: value,
      });
    }
  };


  const handleOtherDetailChange = (index, event) => {
    const newOtherDetails = [...otherDetails];
    newOtherDetails[index] = event.target.value;
    setOtherDetails(newOtherDetails);
    handleChange({
      target: {
        name: 'hotel_others',
        value: newOtherDetails
      }
    });
  };

  const inputRef = useRef(null);
  const addOtherDetailField = () => {
    if (otherDetails[otherDetails.length - 1] !== '') {
      setOtherDetails([...otherDetails, '']);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };


  const [highlightlist, setHighlightlist] = useState([]);
  const [highlightArray, setHighlightArray] = useState([]);
  const [highlightArray2, setHighlightArray2] = useState([]);
  const [showSection2, setShowSection2] = useState(false);
  const [showSection3, setShowSection3] = useState(false);
  const [showSection4, setShowSection4] = useState(false);
  const [showSection5, setShowSection5] = useState(false);
  const [showHighlightsModal, setShowHighlightsModal] = useState(false);



  const countries = addressdata.find((obj) => obj.name === "India")

  const getHotelHighlights = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}highlight/`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setHighlightlist(data?.data);
  };

  useEffect(() => {
    getHotelHighlights();
  }, []);

  const handleHighlightChange = (event) => {
    const { value, checked } = event.target;
    // const selectedOptions = Array.from(event.target.selectedOptions).map(option => option.value);
    setHighlightArray(prevState => {
      if (checked) {
        return [...prevState, value];
      } else {
        return prevState.filter(option => option !== value);
      }
    });
  };

  const handleSaveChanges = async () => {
    const filteredHighlights = highlightlist.filter(highlight =>
      highlightArray.includes(highlight._id)
    );
    setHighlightArray2(filteredHighlights);
    setShowHighlightsModal(false);
  };


  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
    setImages(prevImages => [...prevImages, ...fileObjects]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };


  // submit 1

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!hotelData.hotel_name) {
      toast.error("Hotel name is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!hotelData.hotel_address) {
      toast.error("Hotel address is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!hotelData.hotel_description) {
      toast.error("Hotel description is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!hotelData.hotel_type) {
      toast.error("Hotel type is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!selectedState) {
      toast.error("State is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (!selectedCity) {
      toast.error("City is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (highlightArray.length === 0) {
      toast.error("At least one highlight is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    if (images.length < 5) {
      toast.error("At least 5 hotel image is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    setLoading(true)
    const formData = new FormData();

    // Append hotel data to FormData
    formData.append("hotel_name", hotelData.hotel_name);
    formData.append("hotel_address", hotelData.hotel_address);
    formData.append("hotel_description", hotelData.hotel_description);
    formData.append("hotel_type", hotelData.hotel_type);
    formData.append("state", selectedState);
    formData.append("city", selectedCity);
    highlightArray.forEach((highlight, index) => {
      formData.append("hotel_highlights", highlight)
    });
    images.forEach(({ file }, index) => {
      formData.append("hotel_photo", file);
    });
    otherDetails.forEach((other, index) => {
      if (other !== '')
        formData.append("other", other);
    });

    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}hotel_syt`, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: formData,
    });

    const data = await res.json();
    if (res.ok) {
      setLoading(false)
      setHotelId(data.data._id);
      setShowSection2(true);
      toast.success("Hotel Added", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      // navigate to another page if needed
    } else {
      setLoading(false)
      toast.error(data.message, { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
    }
  };



  // aminities section

  const [showAminitiesModal, setShowAminitiesModal] = useState(false);


  const [amenities, setAmenities] = useState([]);

  const [newAmenity, setNewAmenity] = useState({ title: '', points: [''] });

  const afsubpointRef = useRef(null);
  const handleAddPoint = () => {
    if (newAmenity.points[newAmenity.points.length - 1] !== '') {
      setNewAmenity({ ...newAmenity, points: [...newAmenity.points, ''] });
    }
    else {
      if (afsubpointRef.current) {
        afsubpointRef.current.focus();
      }
    }
  };

  const handleRemovePoints = (indexToRemove) => {
    setNewAmenity((prevState) => ({
      ...prevState,
      points: prevState.points.filter((_, index) => index !== indexToRemove),
    }));
  };


  const handlePointChange = (index, value) => {
    const newPoints = newAmenity.points.map((point, i) => i === index ? value : point);
    setNewAmenity({ ...newAmenity, points: newPoints });
  };

  const handleTitleChange = (e) => {
    setNewAmenity({ ...newAmenity, title: e.target.value });
  };

  const handleSaveChanges2 = async () => {

    if (!newAmenity.title) {
      toast.error("Add Amenity Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const amenitytitle = newAmenity.title;
    const amenitypoints = newAmenity.points;
    const amenitypoints2 = amenitypoints.filter(x => x.trim() !== '');
    if (!amenitypoints2) {
      toast.error("Add Atleast one Amenity Detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }
    const newAmenity2 = { title: amenitytitle, points: amenitypoints2 }
    setAmenities(prevAminities => [...prevAminities, newAmenity2])
    setNewAmenity({ title: '', points: [''] })
    setShowAminitiesModal(false);
  };

  const [loading2, setLoading2] = useState(false)

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    if (amenities.length === 0) {
      toast.error("At least one amenity is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    setLoading2(true)

    const token = localStorage.getItem("vendorToken");
    const promises = amenities.map(async (amenity) => {
      const response = await fetch(`${BASE_URL}amenities_and_facilities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          title: amenity.title,
          points: amenity.points,
          hotel_id: hotelId,
        }),
      });

      if (!response.ok) {
        setLoading2(false)
        throw new Error(`Failed to add amenity: ${amenity.title}`);
      }
      setLoading2(false)
      return response.json();
    });

    try {
      const results = await Promise.all(promises);
      setShowSection3(true);
      setShowSection4(true);
      toast.success("Amenities Added Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });

    } catch (error) {
      console.error('Error adding amenities:', error);
    }
  };


  const handleLocationSubmit = (success) => {
    if (success) {
      setShowSection4(true);
      toast.success("Location Submitted", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
    } else {
      setShowSection4(true);
    }
  };


  const [showRoomsModal, setShowRoomsModal] = useState(false);


  const [rooms, setRooms] = useState([]);
  const [roomImages, setRoomImages] = useState([]);
  const [newRoom, setNewRoom] = useState({
    room_title: '',
    room_highlights: [''],
    price: '',
    photos: [],
    total_rooms: '',
    start_date: '',
    end_date: '',
    status: '',
  });



  const rhsubpointRef = useRef(null);
  const handleAddRoomHighlight = () => {
    if (newRoom.room_highlights[newRoom.room_highlights.length - 1] !== '') {
      setNewRoom({ ...newRoom, room_highlights: [...newRoom.room_highlights, ''] });
    }
    else {
      if (rhsubpointRef.current) {
        rhsubpointRef.current.focus();
      }
    }
  };

  const handleRemoveRoomPoints = (indexToRemove) => {
    setNewRoom((prevState) => ({
      ...prevState,
      room_highlights: prevState.room_highlights.filter((_, index) => index !== indexToRemove),
    }));
  };


  const handleRoomHighlightChange = (index, value) => {
    const newPoints = newRoom.room_highlights.map((point, i) => i === index ? value : point);
    setNewRoom({ ...newRoom, room_highlights: newPoints });
  };

  const handleRoomTitleChange = (e) => {
    setNewRoom({ ...newRoom, room_title: e.target.value });
  };

  const handleRoomStartDateChange = (e) => {
    setNewRoom({ ...newRoom, start_date: e.target.value });
  };

  const handleRoomEndDateChange = (e) => {
    setNewRoom({ ...newRoom, end_date: e.target.value });
  };

  const handleRoomTotalRoomsChange = (e) => {
    setNewRoom({ ...newRoom, total_rooms: e.target.value });
  };
  const handleRoomStatusChange = (e) => {
    setNewRoom({ ...newRoom, status: e.target.value });
  };

  const handleRoomPriceChange = (e) => {
    setNewRoom({ ...newRoom, price: e.target.value });
  };

  const RoomfileInputRef = useRef(null);

  const handleAddRoomImageClick = () => {
    RoomfileInputRef.current.click();
  };


  const handleRoomImageChange = (event) => {
    // const newPhotos = newRoom.photos.map((photo, i) => i === index ? url : photo);
    // setNewRoom({ ...newRoom, photos: newPhotos });
    const files = Array.from(event.target.files);
    const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
    setRoomImages(prevImages => [...prevImages, ...fileObjects]);
  };



  const handleRemoveRoomImage = (indexToRemove) => {
    setRoomImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
  };


  const today = new Date().toISOString().split('T')[0];

  const [roomServices, setRoomServices] = useState("")

  const handleSaveChanges3 = async () => {


    if (!newRoom.room_title) {
      toast.error("Add Room Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!newRoom.total_rooms) {
      toast.error("Add Total Rooms", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (newRoom.status !== "booked" && newRoom.status !== "available" && newRoom.status !== "sold") {
      toast.error("Add Total Rooms", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const roomtitle = newRoom.room_title;
    const roomhighlights = newRoom.room_highlights;
    const roomprice = newRoom.price;
    const roomhighlights2 = roomhighlights.filter(x => x.trim() !== '');
    const roomsd = newRoom.start_date;
    const roomed = newRoom.end_date;
    const roomtr = newRoom.total_rooms;
    const roomstatus = newRoom.status;

    if (!roomhighlights2) {
      toast.error("Add Atleast one Room Highlight", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (roomImages.length < 3) {
      toast.error("Add Atleast one Room Image", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newRoom2 = {
      room_title: roomtitle,
      room_highlights: roomhighlights2,
      price: roomprice,
      photos: roomImages,
      start_date: roomsd,
      end_date: roomed,
      total_rooms: roomtr,
      status: roomstatus,
      othere_future_agency: roomServices,
    }
    setRooms(prevRooms => [...prevRooms, newRoom2])
    setNewRoom({
      room_title: '',
      room_highlights: [''],
      price: '',
      photos: [],
      total_rooms: '',
      start_date: '',
      end_date: '',
      status: '',
    })
    setRoomImages([]);
    setShowRoomsModal(false);
  };

  // submit 3

  const [loading3, setLoading3] = useState(false)

  const handleSubmit3 = async (e) => {
    e.preventDefault();

    if (rooms.length === 0) {
      toast.error("At least one room is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    setLoading3(true)

    const token = localStorage.getItem("vendorToken");
    const promises = rooms.map(async (room) => {

      const formData = new FormData();

      // Append hotel data to FormData
      formData.append("room_title", room.room_title);
      formData.append("hotel_id", hotelId);
      formData.append("status", room.status);
      formData.append("total_rooms", room.total_rooms);
      formData.append("othere_future_agency", room.othere_future_agency);

      priceDetails.forEach((item, index) => {
        formData.append(`price_and_date[${index}][adult_price]`, item.adult_price);
        formData.append(`price_and_date[${index}][child_price]`, item.child_price);
        formData.append(`price_and_date[${index}][price_start_date]`, item.price_start_date);
        formData.append(`price_and_date[${index}][price_end_date]`, item.price_end_date);
      });

      room.room_highlights.forEach((highlight, index) => {
        formData.append("room_highlights", highlight)
      });
      room.photos.forEach(({ file }, index) => {
        formData.append("photos", file);
      });
      const response = await fetch(`${BASE_URL}room_syt`, {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: formData
      });

      if (!response.ok) {
        setLoading3(false)
        throw new Error(`Failed to add room: ${room.room_title}`);
      }
      setLoading3(false)

      return response.json();
    });

    try {
      const results = await Promise.all(promises);
      setShowSection5(true);
      toast.success("Rooms Added Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });

    } catch (error) {
      console.error('Error adding amenities:', error);
    }
  };



  // policy section

  const [showPolicyModal1, setShowPolicyModal1] = useState(false);
  const [showPolicyModal2, setShowPolicyModal2] = useState(false);
  const [showPolicyModal3, setShowPolicyModal3] = useState(false);
  const [showPolicyModal4, setShowPolicyModal4] = useState(false);
  const [showPolicyModal5, setShowPolicyModal5] = useState(false);

  const [newPolicy1, setNewPolicy1] = useState({
    policy_title: '',
    policy_description: '',
  });

  const [newPolicy2, setNewPolicy2] = useState({
    infant: '',
    infant_points: [''],
  });

  const [newPolicy3, setNewPolicy3] = useState({
    children: '',
    children_points: [''],
  });

  const [newPolicy4, setNewPolicy4] = useState({
    adult_and_above: '',
    adult_and_above_points: [''],
  });

  const [newPolicy5, setNewPolicy5] = useState({
    policy_other: ['']
  });

  const [policy1, setPolicy1] = useState();
  const [policy2, setPolicy2] = useState();
  const [policy3, setPolicy3] = useState();
  const [policy4, setPolicy4] = useState();
  const [policy5, setPolicy5] = useState();

  const handlePolicyTitleChange = (e) => {
    setNewPolicy1({ ...newPolicy1, policy_title: e.target.value });
  };

  const handlePolicyDescriptionChange = (e) => {
    setNewPolicy1({ ...newPolicy1, policy_description: e.target.value });
  };

  const handleInfantTitleChange = (e) => {
    setNewPolicy2({ ...newPolicy2, infant: e.target.value });
  };

  const handleChildrenTitleChange = (e) => {
    setNewPolicy3({ ...newPolicy3, children: e.target.value });
  };

  const handleAdultsTitleChange = (e) => {
    setNewPolicy4({ ...newPolicy4, adult_and_above: e.target.value });
  };

  const pisubpointRef = useRef(null);

  const handleAddInfant = () => {
    if (newPolicy2.infant_points[newPolicy2.infant_points.length - 1] !== '') {
      setNewPolicy2({ ...newPolicy2, infant_points: [...newPolicy2.infant_points, ''] });
    }
    else {
      if (pisubpointRef.current) {
        pisubpointRef.current.focus();
      }
    }
  };

  const handleSubInfant = (indexToRemove) => {
    setNewPolicy2((prevState) => ({
      ...prevState,
      infant_points: prevState.infant_points.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handlePolicyInfantDetailChange = (index, value) => {
    const newPoints = newPolicy2.infant_points.map((point, i) => i === index ? value : point);
    setNewPolicy2({ ...newPolicy2, infant_points: newPoints });
  };

  const pcsubpointRef = useRef(null);

  const handleAddChildren = () => {
    if (newPolicy3.children_points[newPolicy3.children_points.length - 1] !== '') {
      setNewPolicy3({ ...newPolicy3, children_points: [...newPolicy3.children_points, ''] });
    }
    else {
      if (pcsubpointRef.current) {
        pcsubpointRef.current.focus();
      }
    }
  };

  const handlePolicyChildrenDetailChange = (index, value) => {
    const newPoints = newPolicy3.children_points.map((point, i) => i === index ? value : point);
    setNewPolicy3({ ...newPolicy3, children_points: newPoints });
  };


  const pasubpointRef = useRef(null);

  const handleAddAdults = () => {
    if (newPolicy4.adult_and_above_points[newPolicy4.adult_and_above_points.length - 1] !== '') {
      setNewPolicy4({ ...newPolicy4, adult_and_above_points: [...newPolicy4.adult_and_above_points, ''] });
    }
    else {
      if (pasubpointRef.current) {
        pasubpointRef.current.focus();
      }
    }
  };

  const handlePolicyAdultsDetailChange = (index, value) => {
    const newPoints = newPolicy4.adult_and_above_points.map((point, i) => i === index ? value : point);
    setNewPolicy4({ ...newPolicy4, adult_and_above_points: newPoints });
  };

  const posubpointRef = useRef(null);

  const handleAddOthers = () => {
    if (newPolicy5.policy_other[newPolicy5.policy_other.length - 1] !== '') {
      setNewPolicy5({ ...newPolicy5, policy_other: [...newPolicy5.policy_other, ''] });
    }
    else {
      if (posubpointRef.current) {
        posubpointRef.current.focus();
      }
    }
  };

  const handlePolicyOthersChange = (index, value) => {
    const newPoints = newPolicy5.policy_other.map((point, i) => i === index ? value : point);
    setNewPolicy5({ ...newPolicy5, policy_other: newPoints });
  };

  const handleSaveChanges41 = async () => {

    if (!newPolicy1.policy_title) {
      toast.error("Add Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!newPolicy1.policy_description) {
      toast.error("Add Policy Descripiton", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    setPolicy1(newPolicy1);
    setShowPolicyModal1(false);
  };

  const handleSaveChanges42 = async () => {

    const infant = newPolicy2.infant;
    const infantpoints = newPolicy2.infant_points;
    const infantpoints2 = infantpoints.filter(x => x.trim() !== '');

    if (!newPolicy2.infant) {
      toast.error("Add Infant Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!infantpoints2) {
      toast.error("Add Atleast one Infant Policy Details", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newPolicyx = {
      infant: infant,
      infant_points: infantpoints2,
    }

    setPolicy2(newPolicyx);
    setShowPolicyModal2(false);
  };

  const handleSaveChanges43 = async () => {

    const children = newPolicy3.children;
    const childrenpoints = newPolicy3.children_points;
    const childrenpoints2 = childrenpoints.filter(x => x.trim() !== '');

    if (!newPolicy3.children) {
      toast.error("Add Children Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!childrenpoints2) {
      toast.error("Add Atleast one children policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newPolicy2 = {
      children: children,
      children_points: childrenpoints2,
    }

    setPolicy3(newPolicy2);
    setShowPolicyModal3(false);
  };

  const handleSaveChanges44 = async () => {

    const adults = newPolicy4?.adult_and_above;
    const adultspoints = newPolicy4?.adult_and_above_points;
    const adultspoints2 = adultspoints.filter(x => x.trim() !== '');

    if (!adults) {
      toast.error("Add adults Policy Title", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (!adultspoints2) {
      toast.error("Add Atleast one adults policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newPolicy2 = {
      adult_and_above: adults,
      adult_and_above_points: adultspoints2,
    }

    setPolicy4(newPolicy2);
    setShowPolicyModal4(false);

  };

  const handleSaveChanges45 = async () => {
    const policyothers = newPolicy5.policy_other;
    const policyothers2 = policyothers.filter(x => x.trim() !== '');

    if (!policyothers2) {
      toast.error("Add Atleast one adults policy detail", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newPolicy2 = {
      policy_other: policyothers2,
    }

    setPolicy5(newPolicy2);
    setShowPolicyModal5(false);
  };

  // submit 4
  const handleSubmit4 = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("vendorToken");

    const response = await fetch(`${BASE_URL}property_policies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        hotel_id: hotelId,
        policy_title: policy1.policy_title,
        policy_description: policy1.policy_description,
        infant: policy2.infant,
        children: policy3.children,
        adult_and_above: policy4.adult_and_above,
        infant_points: policy2.infant_points,
        childern_points: policy3.childern_points,
        adult_and_above_points: policy4.adult_and_above_points,
        policy_other: policy5.policy_other
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to add property policy `);
    }
    else {
      toast.success("All Details Added Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      setTimeout(() => {
        navigate('/vendor/myhotels')
      }, 1000);
      return response.json();
    }
  };

  const [priceDetails, setPriceDetails] = useState([]);
  const [openRangeModal, setOpenRangeModal] = useState(false);
  const [pricePerPerson, setPricePerPerson] = useState('');
  const [pricePerPersonForChild, setPricePerPersonForChild] = useState('');
  const [priceStartDate, setPriceStartDate] = useState('');
  const [priceEndDate, setPriceEndDate] = useState('');

  const handleCloseRangeModal = () => {
    setOpenRangeModal(false)
  }

  const handleSave = () => {

    if (!pricePerPerson || !pricePerPersonForChild || !priceStartDate || !priceEndDate) {
      toast.error("Please Fill All Fields!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    if (Number(pricePerPerson) < Number(pricePerPersonForChild)) {
      toast.error("Childrens Price cannot be bigger than Adult Price!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const startDate = new Date(priceStartDate);
    const endDate = new Date(priceEndDate);

    const overlappingRanges = priceDetails?.filter((item) => {
      const priceStart = new Date(item.price_start_date);
      const priceEnd = new Date(item.price_end_date);

      return (
        (startDate >= priceStart && startDate <= priceEnd) ||
        (endDate >= priceStart && endDate <= priceEnd)
      );
    });

    if (overlappingRanges?.length > 0) {
      toast.error("Dates are mismatched with other dates!", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
      return;
    }

    const newDetail = {
      adult_price: Number(pricePerPerson),
      child_price: Number(pricePerPersonForChild),
      price_start_date: priceStartDate,
      price_end_date: priceEndDate,
    };

    if (Array.isArray(priceDetails)) {
      setPriceDetails([...priceDetails, newDetail]);
    } else {
      setPriceDetails([newDetail]);
    }

    setPricePerPerson('');
    setPricePerPersonForChild('');
    setPriceStartDate('');
    setPriceEndDate('');
    setOpenRangeModal(false);
    setShowRoomsModal(true)
  }

  const handleDelete = (index) => {
    const updatedPriceRanges = priceDetails?.filter((_, i) => i !== index);
    setPriceDetails(updatedPriceRanges);
  }

  return (
    <>
      <Header2 />
      <div className="costum_container">
        <My_pannel />
        <ToastContainer />
        <div >

          <ProfileSection title="My Hotels" />

          <section className="hotel_section_margin p-4">
            <h5 className="font700">Hotel Details</h5>

            <div className="hotel-details-container">
              <div className="form-group-container">
                <div className="form-group">
                  {/* <label htmlFor="hotel_name">Hotel Name</label> */}
                  <p className='mb-1 font600 text14'>Hotel Name</p>
                  <input
                    type="text"
                    id="hotel_name"
                    name="hotel_name"
                    value={hotelData.hotel_name}
                    onChange={handleChange}
                    required
                    placeholder="Hotel Name"
                  />
                </div>
                <div className="form-group">
                  {/* <label htmlFor="hotel_type">Hotel Type</label> */}
                  <p className='mb-1 font600 text14'>Hotel Type</p>
                  <select
                    id="hotel_type"
                    name="hotel_type"
                    value={hotelData.hotel_type}
                    onChange={handleChange}
                    required
                  >
                    <option value="" disabled selected>Select Hotel Type</option>
                    <option value="1">1 Star</option>
                    <option value="2">2 Stars</option>
                    <option value="3">3 Stars</option>
                    <option value="4">4 Stars</option>
                    <option value="5">5 Stars</option>
                  </select>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  {/* <label htmlFor="country">Choose Country</label> */}
                  <p className='mb-1 font600 text14'>Choose State</p>
                  <select
                    id="state"
                    name="state"
                    value={selectedState}
                    onChange={handleChange}
                    required
                    placeholder="Choose State"
                  >
                    <option value="" disabled selected>Select State</option>
                    {countries.states &&
                      countries.states.map((e) => {
                        return (
                          <option value={e.name}>{e.name}</option>
                        )
                      })}
                  </select>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="city">Choose City</label> */}
                  <p className='mb-1 font600 text14'>Choose City</p>
                  <select
                    id="city"
                    name="city"
                    value={selectedCity}
                    onChange={handleChange}
                    required
                    placeholder="Choose City"
                  >
                    <option value="" disabled selected>Select City</option>
                    {countries.states &&
                      countries.states
                        .filter(
                          (state) =>
                            state.name.toLowerCase() ===
                            selectedState.toLowerCase(),
                        )
                        .map((state) =>
                          state.cities.map((city) => (
                            <option key={city.id} value={city.name}>
                              {city.name}
                            </option>
                          )),
                        )}
                  </select>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  {/* <label htmlFor="state">Address</label> */}
                  <p className='mb-1 font600 text14'>Address</p>
                  {/* <input
                    type="text"
                    id="hotel_address"
                    name="hotel_address"
                    value={hotelData.hotel_address}
                    onChange={handleChange}
                    required
                    placeholder="Address"
                  /> */}
                  <textarea
                    id="hotel_address"
                    name="hotel_address"
                    value={hotelData.hotel_address}
                    onChange={handleChange}
                    rows="2"
                    required
                    placeholder="Address"
                  />
                </div>
                <div className="form-group">
                  {/* <label htmlFor="description">Description</label> */}
                  <p className='mb-1 font600 text14'>Description</p>
                  <textarea
                    id="hotel_description"
                    name="hotel_description"
                    value={hotelData.hotel_description}
                    onChange={handleChange}
                    rows="2"
                    required
                    placeholder="Hotel Description"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="other-form-group">
                  <label className="font600 text18" style={{ marginRight: "20px", marginBottom: "20px" }}>Other Details</label>
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={addOtherDetailField} />
                </div>
                {otherDetails.map((detail, index) => (
                  <input
                    key={index}
                    type="text"
                    value={detail}
                    onChange={(e) => handleOtherDetailChange(index, e)}
                    placeholder="Other Detail"
                    required
                    ref={index === otherDetails.length - 1 ? inputRef : null}
                  />
                ))}
              </div>
            </div>
          </section>

          <section className="hotel_section_margin p-4">
            <div className="highlights-container">
              <div className="highlights-title-container">
                <h5 className="font700">Highlights</h5>
                <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowHighlightsModal(true)} />
              </div>
              <div className="highlights-section">
                {highlightArray2.length !== 0 && highlightArray2.map((highlight) => {
                  return (
                    <div key={highlight._id} className="highlight-item">
                      <img src={highlight.icon} alt={highlight.title} className="highlights-icon" />
                      <div className="highlights-title">{highlight.title}</div>
                    </div>)
                })}
              </div>
            </div>
            <Modal show={showHighlightsModal} onHide={() => setShowHighlightsModal(false)}>
              <Modal.Body>
                <div className="hotel-model-title-container">
                  <div>
                    <Modal.Title closeButton>
                      <p className="font700 text20 mb-0">
                        Choose Highlights
                      </p>
                    </Modal.Title>
                  </div>
                  <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowHighlightsModal(false)}></FontAwesomeIcon></div>
                </div>
                <Form>
                  <Form.Group>
                    <div className="highlight-model-section">
                      {highlightlist.map((highlight) => (
                        <div className="highlight-model-form-check">
                          <input
                            type="checkbox"
                            id={`highlight-${highlight._id}`}
                            value={highlight._id}
                            onChange={handleHighlightChange}
                            checked={highlightArray.length !== 0 ? highlightArray.includes(highlight._id) : false}
                          />
                          <label htmlFor={`highlight-${highlight._id}`}>
                            <div className="highlight-model-single">
                              <div>
                                <img src={highlight.icon} alt={highlight.title} className="highlight-model-image" />
                              </div>
                              <div className="highlight-model-highlight-title">
                                {highlight.title}
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <div className="hotel-model-footer">
                <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges}>
                  Add Highlights
                </button>
              </div>
            </Modal>
          </section>

          <section className="hotel_section_margin p-4">
            <div className="hotel-pictures-container">
              <div className="hotel-pictures-title-container">
                <h5 className="font700">Hotel Pictures</h5>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="plus-icon"
                  onClick={handleAddImageClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleImageChange}
                  multiple
                />
              </div>
              <div className="hotel-pictures-section">
                {images.map(({ url }, index) => (
                  <div key={index} className="hotel-pictures-item">
                    <img src={url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="trash-icon"
                      onClick={() => handleRemoveImage(index)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="submit-button-section">
            {loading ? (
              <div className="d-flex justify-content-end me-5">
                <div className="" style={{ width: "170px" }}>
                  <span className='vendor-login-button mb-4 py-1'>
                    <img src={loader} alt="" className="spinner" style={{ height: "35px" }} />
                  </span>
                </div>
              </div>
            ) : (
              <button className="submit-button me-5" onClick={handleSubmit}>
                Submit
              </button>
            )}
          </section>

          {showSection2 &&
            <section className="hotel_section_margin p-4">
              <div className="af-container">
                <div className="af-title-container">
                  <h5 className="font700">Amenities and Facilities</h5>
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowAminitiesModal(true)} />
                </div>
                <div className="grid-container">
                  {amenities.map((section, index) => (
                    <div key={index} className="grid-item">
                      <h6>{section.title}</h6>
                      <ul>
                        {section.points.map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <Modal show={showAminitiesModal} onHide={() => setShowAminitiesModal(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Add Amenities</p>
                      </Modal.Title>
                    </div>
                    <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowAminitiesModal(false)}></FontAwesomeIcon></div>
                  </div>
                  <div className="af-model-body mt-3 mx-3">
                    <p className='mb-1 font600 text14'>Title</p>
                    <input
                      type="text"
                      placeholder="Title"
                      value={newAmenity.title}
                      onChange={handleTitleChange}
                      className="af-model-input1"
                    />
                    <p className='mb-1 mt-2 font600 text14'>Points</p>
                    <div className="af-model-subpoint">
                      <div className="af-model-subpoint-container">
                        {newAmenity.points.map((point, index) => (
                          <div key={index} className="input-and-minus">
                            <input
                              type="text"
                              placeholder="Subpoint"
                              value={point}
                              className="af-model-input2"
                              onChange={(e) => handlePointChange(index, e.target.value)}
                              ref={index === newAmenity.points.length - 1 ? afsubpointRef : null}
                            />
                            <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleRemovePoints(index)} />
                          </div>
                        ))}
                      </div>
                      <div className="plus-icon-container">
                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddPoint} />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges2}>
                    Add Amenity
                  </button>
                </div>
              </Modal>
            </section>
          }

          {showSection2 && <section className="submit-button-section">
            {loading2 ? (
              <div className="d-flex justify-content-end me-5">
                <div className="" style={{ width: "170px" }}>
                  <span className='vendor-login-button mb-4 py-1'>
                    <img src={loader} alt="" className="spinner" style={{ height: "35px" }} />
                  </span>
                </div>
              </div>
            ) : (
              <button className="submit-button me-5" onClick={handleSubmit2}>
                Submit
              </button>
            )}
          </section>}

          {showSection3 &&
            <section className="hotel_section_margin p-4">
              <div className="location-container">
                <div className="map-title-container">
                  <h5 className="font700">Location</h5>
                </div>
                <MapComponent />
              </div>
            </section>
          }

          {showSection4 &&
            <section className="hotel_section_margin p-4">
              <div className="rooms-container">
                <div className="rooms-title-container">
                  <h5 className="font700 mb-0">Rooms</h5>
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowRoomsModal(true)} />
                </div>
                <div className="rooms-card-section">
                  {rooms.map((room, index) => (
                    <RoomCard key={index} room={room} />
                  ))}
                </div>
              </div>
              <Modal show={showRoomsModal} onHide={() => setShowRoomsModal(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Hotel Room</p></Modal.Title>
                    </div>
                    <div><FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowRoomsModal(false)}></FontAwesomeIcon></div>
                  </div>

                  <p className='mb-1 mt-3 font600 text14'>Room Title</p>
                  <input
                    type="text"
                    placeholder="Room Title"
                    value={newRoom.room_title}
                    onChange={handleRoomTitleChange}
                    className="room-model-title"
                  />
                  <p className='mb-1 mt-2 font600 text14'>Room Facilities</p>
                  <div className="room-model-subpoint">
                    <div className="room-model-subpoint-container">
                      {newRoom.room_highlights.map((highlight, index) => (
                        <div key={index} className="input-and-minus">
                          <input
                            type="text"
                            placeholder="Subpoint"
                            value={highlight}
                            className="room-model-input2"
                            onChange={(e) => handleRoomHighlightChange(index, e.target.value)}
                            ref={index === newRoom.room_highlights.length - 1 ? rhsubpointRef : null}
                          />
                          <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleRemoveRoomPoints(index)} />
                        </div>
                      ))}
                    </div>
                    <div className="plus-icon-container">
                      <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddRoomHighlight} />
                    </div>
                  </div>
                  <div className="room-model-add-images-title-container">
                    <p className='mb-1 mt-2 font600 text14'>Add Images</p>
                    <div>
                      <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddRoomImageClick} />
                      {/* <button className="room-model-add-image-button" onClick={handleAddRoomImageClick}> Add Images</button> */}
                    </div>
                    <input
                      type="file"
                      ref={RoomfileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleRoomImageChange}
                      multiple
                    />
                  </div>
                  <div className="room-model-image-section">
                    <div className="room-model-image-container">
                      {roomImages.map(({ url }, index) => (
                        <div key={index} className="room-model-image-item">
                          <img src={url} alt={`Room pic ${index + 1}`} className="room-model-image" />
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="trash-icon"
                            onClick={() => handleRemoveRoomImage(index)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>


                  {/* <p className='mb-1 mt-2 font600 text14'>Room Price</p>
                  <input
                    type="number"
                    placeholder="Room Price"
                    value={newRoom.price}
                    onChange={handleRoomPriceChange}
                    className="room-model-input3"
                  /> */}
                  <p className='mb-1 mt-2 font600 text14'>Total Rooms</p>
                  <input
                    type="number"
                    placeholder="Total Rooms"
                    value={newRoom.total_rooms}
                    onChange={handleRoomTotalRoomsChange}
                    className="room-model-input3"
                  />
                  {/* <p className='mb-1 mt-2 font600 text14'>Available Between</p>
                  <div className="room-model-input3" style={{ display: "flex", gap: "5px" }}>
                    <input
                      type="date"
                      value={newRoom.start_date}
                      onChange={handleRoomStartDateChange}
                      min={today}
                      className="room-model-input3"

                    />
                    <input
                      type="date"
                      value={newRoom.end_date}
                      onChange={handleRoomEndDateChange}
                      min={newRoom.start_date}
                      disabled={!newRoom.start_date}
                      className="room-model-input3"
                    />
                  </div> */}

                  <p className='mb-1 mt-2 font600 text14'>Select Room Status</p>
                  <select
                    id="status"
                    name="Room Status"
                    value={newRoom.status}
                    onChange={handleRoomStatusChange}
                    required
                    placeholder="Choose Room Status"
                    className="room-model-input3"
                  >
                    <option value="" disabled selected>Select Room Status</option>
                    <option value="available">Available</option>
                    <option value="booked">Booked</option>
                    <option value="sold">Sold</option>
                  </select>
                  <div className="mt-2 d-flex justify-content-between align-items-center">
                    <p className='mb-0 font600 text14'>Add Price</p>
                    <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => (setOpenRangeModal(true), setShowRoomsModal(false))} />
                  </div>
                  <div>
                    <table className="table table-striped table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">
                            <p className="vendoraddpackage-label text12 font600 mb-0">Adult</p>
                          </th>
                          <th scope="col">
                            <p className="vendoraddpackage-label text12 font600 mb-0">Children</p>
                          </th>
                          <th scope="col">
                            <p className="vendoraddpackage-label text12 font600 mb-0">Start Date</p>
                          </th>
                          <th scope="col">
                            <p className="vendoraddpackage-label text12 font600 mb-0">End Date</p>
                          </th>
                          <th scope="col">
                            <p className="vendoraddpackage-label text12 font600 mb-0">Actions</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceDetails?.length > 0 ? (
                          priceDetails
                            ?.sort((a, b) => {
                              const startDateA = new Date(a.price_start_date);
                              const startDateB = new Date(b.price_start_date);

                              // If the start dates are the same, sort by end date
                              if (startDateA.getTime() === startDateB.getTime()) {
                                const endDateA = new Date(a.price_end_date);
                                const endDateB = new Date(b.price_end_date);
                                return endDateA - endDateB;
                              }

                              return startDateA - startDateB;
                            })
                            ?.map((range, index) => (
                              <tr key={index}>
                                <td className="text14">{index + 1}.</td>
                                <td className="text14">{range?.adult_price}</td>
                                <td className="text14">{range?.child_price}</td>
                                <td className="text14">{formatDate(range?.price_start_date)}</td>
                                <td className="text14">{formatDate(range?.price_end_date)}</td>
                                <td>
                                  <Button
                                    variant="danger"
                                    onClick={() => handleDelete(index)}
                                    className="btn btn-sm"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center text-muted">
                              No price ranges added yet.
                            </td>
                          </tr>
                        )}

                      </tbody>
                    </table>
                  </div>
                  <div className="mt-2">
                    <p className='mb-1 font600 text14'>Other Services</p>
                    <div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={roomServices}
                        onChange={(event, editor) => setRoomServices(editor.getData())}
                      />
                    </div>
                  </div>

                </Modal.Body>
                <div className="hotel-model-footer pb-4">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges3}>
                    Add Room
                  </button>
                </div>
              </Modal>
            </section>
          }

          <Modal show={openRangeModal} onHide={handleCloseRangeModal}>
            <Modal.Body>
              <Modal.Title>
                <h4 className="font700 text20">Add Price</h4>
              </Modal.Title>
              <div className="mt-3">
                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Adult</p>
                <input
                  type="text"
                  placeholder="price"
                  name="price"
                  value={pricePerPerson}
                  onChange={(e) => setPricePerPerson(e.target.value)}
                  className="vendoraddpackage-input"
                />
              </div>
              <div className="mt-3">
                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Price Per Person For Children</p>
                <input
                  type="text"
                  placeholder="price"
                  name="price"
                  value={pricePerPersonForChild}
                  onChange={(e) => setPricePerPersonForChild(e.target.value)}
                  className="vendoraddpackage-input"
                />
              </div>
              <div className="mt-3">
                <p htmlFor="" className="mb-1 vendoraddpackage-label text12" style={{ fontWeight: "bold", fontSize: "12px" }}>Select Dates For This Price Aplicable</p>
                <div className="d-flex justify-content-between gap-2">
                  <input
                    type="date"
                    name="start_date"
                    placeholder="Enter Start Date"
                    value={priceStartDate}
                    onChange={(e) => setPriceStartDate(e.target.value)}
                    min={getTomorrowDate()}
                    className="vendoraddpackage-input"
                  />

                  <input
                    type="date"
                    name="end_date"
                    placeholder="Enter End Date"
                    value={priceEndDate}
                    onChange={(e) => setPriceEndDate(e.target.value)}
                    min={priceStartDate}
                    className="vendoraddpackageitinerary-input"
                    disabled={!priceStartDate}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <Button variant="secondary" onClick={() => (handleCloseRangeModal(), setShowRoomsModal(true))}>
                  Close
                </Button>
                <div className="d-flex align-items-center">
                  <Button className="vendoraddpackage-button" onClick={handleSave}>
                    Save
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {showSection4 && <section className="submit-button-section">
            {loading3 ? (
              <div className="d-flex justify-content-end me-5">
                <div className="" style={{ width: "170px" }}>
                  <span className='vendor-login-button mb-4 py-1'>
                    <img src={loader} alt="" className="spinner" style={{ height: "35px" }} />
                  </span>
                </div>
              </div>
            ) : (
              <button className="submit-button me-5" onClick={handleSubmit3}>
                Submit
              </button>
            )}
          </section>
          }


          {showSection5 &&
            <section className="hotel_section_margin p-4">
              <div className="pp-container">
                <div className="pp-title-container">
                  <h5 className="font700">Property Policies</h5>
                  <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal1(true)} />
                </div>
                {policy1?.policy_title && (
                  <div>
                    <div className="policy-title">{policy1?.policy_title}</div>
                    <div className="policy-description">{policy1?.policy_description}</div>
                    <div className="policy-age-container">
                      <div className="policy-card">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="policy-card-title">Infant 0-2 year</div>
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal2(true)} />
                        </div>
                        <hr className="policy-card_hr"></hr>
                        {policy2 &&
                          <>
                            <div className="policy-card-info">{policy2?.infant}</div>
                            {policy2?.infant_points[0] && <ul>
                              {policy2?.infant_points.map(point =>
                              (
                                <li className="policy-card-point">{point}</li>
                              )
                              )}
                            </ul>}
                          </>
                        }
                      </div>
                      <div className="policy-card">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="policy-card-title">Children 3-11 year</div>
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal3(true)} />
                        </div>
                        <hr className="policy-card_hr"></hr>
                        {policy3 && <>
                          <div className="policy-card-info">{policy3?.children}</div>
                          {policy3?.children_points[0] &&
                            <ul>
                              {policy3?.children_points && policy3?.children_points.map(point =>
                              (
                                <li className="policy-card-point">{point}</li>
                              )
                              )}
                            </ul>
                          }
                        </>
                        }
                      </div>
                      <div className="policy-card">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className="policy-card-title">Adults 12 & Above</div>
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal4(true)} />
                        </div>
                        <hr className="policy-card_hr"></hr>
                        {policy3 && <>
                          <div className="policy-card-info">{policy4?.adult_and_above}</div>
                          {policy4?.adult_and_above_points?.[0] && <ul>
                            {policy4?.adult_and_above_points?.map(point =>
                            (
                              <li className="policy-card-point">{point}</li>
                            )
                            )}
                          </ul>
                          }
                        </>}
                      </div>
                    </div>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div className="policy-others">Others</div>
                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={() => setShowPolicyModal5(true)} />
                      </div>
                      {policy5?.policy_other[0] &&
                        <ul>
                          {policy5?.policy_other?.map(point =>
                          (
                            <li className="policy-other-point">{point}</li>
                          )
                          )}
                        </ul>
                      }
                    </div>
                  </div>)}
              </div>
              <Modal show={showPolicyModal1} onHide={() => setShowPolicyModal1(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">
                          Add Policy Title And Descripiton
                        </p>
                      </Modal.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal1(false)} />
                    </div>
                  </div>
                  <p className="mb-1 mt-3 font600 text14">Policy Title</p>
                  <input
                    type="text"
                    placeholder="Policy Title"
                    value={newPolicy1?.policy_title}
                    onChange={handlePolicyTitleChange}
                    className="af-model-input1"
                  />
                  <p className="mb-1 mt-3 font600 text14">Policy Description</p>
                  <input
                    type="textarea"
                    placeholder="Policy Description"
                    value={newPolicy1?.policy_description}
                    onChange={handlePolicyDescriptionChange}
                    className="af-model-input1"
                  />
                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges41}>
                    Save Changes
                  </button>
                </div>
              </Modal>
              <Modal show={showPolicyModal2} onHide={() => setShowPolicyModal2(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Add Infant Policies</p>
                      </Modal.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal2(false)} />
                    </div>
                  </div>
                  <p className="mb-1 mt-3 font600 text14">Infant Policy Title</p>
                  <input
                    type="text"
                    placeholder="Infant Policy Title"
                    value={newPolicy2.infant}
                    onChange={handleInfantTitleChange}
                    className="af-model-input1"
                  />
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0 font600 text14">Infant Points</p>
                    <div className="plus-icon-container">
                      <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddInfant} />
                    </div>
                  </div>
                  <div className="af-model-subpoint">
                    <div className="af-model-subpoint-container">
                      {newPolicy2.infant_points.map((infantpoint, index) => (
                        <div key={index} className="input-and-minus">
                          <input
                            type="text"
                            placeholder="Infant detail"
                            value={infantpoint}
                            onChange={(e) => handlePolicyInfantDetailChange(index, e.target.value)}
                            className="af-model-input2"
                            ref={index === newPolicy2.infant_points.length - 1 ? pisubpointRef : null}
                          />
                          <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubInfant(index)} />
                        </div>
                      ))}
                    </div>
                  </div>
                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges42}>
                    Save Changes
                  </button>
                </div>
              </Modal>
              <Modal show={showPolicyModal3} onHide={() => setShowPolicyModal3(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Add Children Policies</p>
                      </Modal.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal3(false)} />
                    </div>
                  </div>
                  <p className="mb-1 mt-3 font600 text14">Children Policy Title</p>
                  <input
                    type="text"
                    placeholder="Children Policy Title"
                    value={newPolicy3.children}
                    onChange={handleChildrenTitleChange}
                    className="af-model-input1"
                  />
                  <div className="af-model-subpoint">
                    <div className="af-model-subpoint-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 font600 text14">Children Points</p>
                        <div className="plus-icon-container">
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddChildren} />
                        </div>
                      </div>
                      {newPolicy3.children_points && newPolicy3.children_points.map((childrenpoint, index) => (
                        <div key={index} className="input-and-minus">
                          <input
                            type="text"
                            placeholder="Children detail"
                            value={childrenpoint}
                            onChange={(e) => handlePolicyChildrenDetailChange(index, e.target.value)}
                            className="af-model-input2"
                            ref={index === newPolicy3.children_points.length - 1 ? pcsubpointRef : null}
                          />
                          {/* <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubChildren(index)} /> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges43}>
                    Save Changes
                  </button>
                </div>
              </Modal>
              <Modal show={showPolicyModal4} onHide={() => setShowPolicyModal4(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Add Adults and Above Policies</p>
                      </Modal.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal4(false)} />
                    </div>
                  </div>
                  <p className="mb-1 mt-3 font600 text14">Adult Policy Title</p>
                  <input
                    type="text"
                    placeholder="Adults Policy Title"
                    value={newPolicy4.adult_and_above}
                    onChange={handleAdultsTitleChange}
                    className="af-model-input1"
                  />
                  <div className="af-model-subpoint">
                    <div className="af-model-subpoint-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 font600 text14">Adults Points</p>
                        <div className="plus-icon-container">
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddAdults} />
                        </div>
                      </div>
                      {newPolicy4.adult_and_above_points.map((adultpoint, index) => (
                        <div key={index} className="input-and-minus">
                          <input
                            type="text"
                            placeholder="Adults detail"
                            value={adultpoint}
                            onChange={(e) => handlePolicyAdultsDetailChange(index, e.target.value)}
                            className="af-model-input2"
                            ref={index === newPolicy4.adult_and_above_points.length - 1 ? pasubpointRef : null}
                          />
                          {/* <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubAdults(index)} /> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges44}>
                    Save Changes
                  </button>
                </div>
              </Modal>
              <Modal show={showPolicyModal5} onHide={() => setShowPolicyModal5(false)}>
                <Modal.Body>
                  <div className="hotel-model-title-container">
                    <div>
                      <Modal.Title closeButton>
                        <p className="font700 text20 mb-0">Add Other Policies</p>
                      </Modal.Title>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClose} className="cross-icon" onClick={() => setShowPolicyModal5(false)} />
                    </div>
                  </div>
                  <div className="af-model-subpoint">
                    <div className="af-model-subpoint-container">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="mb-0 font600 text14">Other Policies Points</p>
                        <div className="plus-icon-container">
                          <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddOthers} />
                        </div>
                      </div>
                      {newPolicy5.policy_other.map((other, index) => (
                        <div key={index} className="input-and-minus mb-2">
                          <input
                            type="text"
                            placeholder="Other detail"
                            value={other}
                            onChange={(e) => handlePolicyOthersChange(index, e.target.value)}
                            className="af-model-input1"
                            ref={index === newPolicy5.policy_other.length - 1 ? posubpointRef : null}
                          />
                          {/* <FontAwesomeIcon icon={faMinus} className="minus-icon" onClick={() => handleSubOthers(index)} /> */}
                        </div>
                      ))}
                    </div>
                  </div>

                </Modal.Body>
                <div className="hotel-model-footer">
                  <button className="hotel-model-footer-button" variant="primary" onClick={handleSaveChanges45}>
                    Save Changes
                  </button>
                </div>
              </Modal>
            </section>
          }
        </div>
        {showSection5 &&
          <section className="submit-button-section">
            <button className="submit-button" onClick={handleSubmit4}>
              Submit
            </button>
          </section>
        }
      </div>
    </>
  );
}

export default Hotels;
