import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Tripclap = () => {
    const [destination, setDestination] = useState('');
    const [budget1, setBudget1] = useState('');
    const [budget2, setBudget2] = useState('');
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setProgress(0);

        const updateProgress = () => {
            setProgress((prev) => Math.min(prev + 1, 100));
        };

        const interval = setInterval(updateProgress, 100); // Update every 100ms

        try {
            const result = await axios.post(
                'https://tripclapscrapper.onrender.com',
                new URLSearchParams({
                    destination,
                    budget1,
                    budget2,
                })
            );
            clearInterval(interval);
            setLoading(false);
            setResponse(result.data);
            setProgress(100);
        } catch (error) {
            clearInterval(interval);
            setLoading(false);
            setProgress(0);
            console.error('Error:', error);
        }
    };


    const formStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
    };

    const inputStyle = {
        margin: '10px 0',
        padding: '10px',
        width: '200px',
    };

    const buttonStyle = {
        padding: '10px 20px',
        marginTop: '20px',
    };

    const loadingBarContainerStyle = {
        width: '100%',
        height: '10px',
        backgroundColor: '#f3f3f3',
        borderRadius: '5px',
        marginTop: '20px',
    };

    const loadingBarStyle = {
        width: `${progress}%`,
        height: '100%',
        backgroundColor: '#4caf50',
        borderRadius: '5px',
        transition: 'width 0.1s',
    };

    return (
        <div style={formStyle}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="destination"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    placeholder="Destination"
                    style={inputStyle}
                    required
                />
                <input
                    type="text"
                    name="budget1"
                    value={budget1}
                    onChange={(e) => setBudget1(e.target.value)}
                    placeholder="Budget 1"
                    style={inputStyle}
                    required
                />
                <input
                    type="text"
                    name="budget2"
                    value={budget2}
                    onChange={(e) => setBudget2(e.target.value)}
                    placeholder="Budget 2"
                    style={inputStyle}
                    required
                />
                <button type="submit" style={buttonStyle}>Submit</button>
            </form>
            {loading && (
                <div style={loadingBarContainerStyle}>
                    <div style={loadingBarStyle}></div>
                </div>
            )}
            {response && (
                <div style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {response.tripclapscrapper?.TripClap?.map((packageData, index) => (
                            <div key={index} style={{
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                margin: '10px',
                                padding: '10px',
                                width: '300px'
                            }}>
                                <img src={packageData.ImageUrl} alt={packageData.Title} style={{
                                    width: '100%',
                                    height: '200px',
                                    objectFit: 'cover',
                                    borderRadius: '8px 8px 0 0'
                                }} />
                                <div style={{ padding: '10px' }}>
                                    <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>{packageData.Title}</h3>
                                    <p><strong>Destination:</strong> {packageData.Destination}</p>
                                    <p><strong>Duration:</strong> {packageData.Duration}</p>
                                    <p><strong>Price:</strong> {packageData.Price}</p>
                                    <button onClick={() => {
                                        navigate("/ws/specificdetail", { state: { site: packageData.Website , link: packageData.Url} });
                                    }} style={{
                                        display: 'inline-block',
                                        padding: '8px 16px',
                                        backgroundColor: '#007bff',
                                        color: '#fff',
                                        textDecoration: 'none',
                                        borderRadius: '4px',
                                        marginTop: '10px'
                                    }} target="_blank" rel="noopener noreferrer">View Details</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tripclap;
