/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Header from "./Header"
import { Row, Container } from "react-bootstrap"
import { useEffect } from "react"
import { useState } from "react"
import { BASE_URL } from "../BASE_URL"
import { useLocation, useNavigate } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import Footer from "./Footer"
import Box from "@mui/material/Box"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const rightDate = (dateString) => {
  const [year, month, day] = dateString?.split('-');
  return `${day}-${month}-${year}`;
};


function My_package(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [bookedData, setBookedData] = useState("")
  const [bookedHotelData, setBookedHotelData] = useState([])
  const [bookedCarData, setBookedCarData] = useState([])
  const [mergedData, setMergedData] = useState([]);
  console.log(mergedData)

  const [isBookingCancel, setIsBookingCancel] = useState(false)
  const [bookingId, setBookingId] = useState("")

  const [cancelledBookingId, setCancelledBookingId] = useState([])

  const [error, setError] = useState("")

  const [reason, setReason] = useState("")

  const fetchBookedPackage = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}bookpackage/jaydev`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked packages');
      }

      const data = await res.json();
      console.log(data)
      setBookedData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked packages');
    }
  };

  // useEffect(() => {
  //   if (location?.state === "fetch") {
  //     setTimeout(() => {
  //       console.log("Fetching booked package...");
  //       fetchBookedPackage();
  //     }, 5000);
  //   }
  // }, [location?.state]);

  const fetchBookedHotels = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}hotel_booking_syt`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked hotels');
      }

      const data = await res.json();
      setBookedHotelData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked hotels');
    }
  };

  const fetchBookedCars = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}car_booking_syt/displayUserBookedCar`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked cars');
      }

      const data = await res.json();
      setBookedCarData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked cars');
    }
  };

  // const mergeAndSortData = () => {
  //   // Check if all three arrays are valid arrays
  //   const validBookedData = Array.isArray(bookedData) ? bookedData : [];
  //   const validBookedHotelData = Array.isArray(bookedHotelData) ? bookedHotelData : [];
  //   const validBookedCarData = Array.isArray(bookedCarData) ? bookedCarData : [];

  //   const mappedBookedData = validBookedData.map(item => ({ ...item, commonDate: new Date(item.approx_start_date) }));
  //   const mappedBookedHotelData = validBookedHotelData.map(item => ({ ...item, commonDate: new Date(item.check_in_date) }));
  //   const mappedBookedCarData = validBookedCarData.map(item => ({ ...item, commonDate: new Date(item.pickup_date) }));

  //   const combinedData = [...mappedBookedData, ...mappedBookedHotelData, ...mappedBookedCarData];

  //   const sortedData = combinedData.sort((a, b) => b.commonDate - a.commonDate);

  //   const finalData = sortedData.map(item => {
  //     const { commonDate, ...rest } = item;
  //     return rest;
  //   });

  //   setMergedData(finalData);
  // };

  const mergeAndSortData = () => {
    // Check if all three arrays are valid arrays
    const validBookedData = Array.isArray(bookedData) ? bookedData : [];
    const validBookedHotelData = Array.isArray(bookedHotelData) ? bookedHotelData : [];
    const validBookedCarData = Array.isArray(bookedCarData) ? bookedCarData : [];

    // Merge all valid arrays
    const mergedArray = [...validBookedData, ...validBookedHotelData, ...validBookedCarData];

    // Sort the merged array by `createdAt` date in descending order
    const sortedArray = mergedArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    // Store in state variable or any variable you need
    setMergedData(sortedArray); // Assuming setMergedData is a state setter
  };


  useEffect(() => {
    const fetchData = async () => {
      await fetchBookedPackage();
      await fetchBookedHotels();
      await fetchBookedCars();
    };

    fetchData();
  }, []);

  useEffect(() => {
    mergeAndSortData();
  }, [bookedData, bookedHotelData, bookedCarData]);

  const userToken = localStorage.getItem("userToken")

  useEffect(() => {
    if (!userToken) {
      navigate("/")
    } else {
    }
  }, [])

  const handleNavigate = (packageId) => {
    navigate(`/booking-full-detail/${packageId}`)
  }

  const handleHotelNavigate = (packageId) => {
    navigate(`/hotel_booking/${packageId?._id} `, { state: packageId })
  }

  const handleCarNavigate = (e) => {
    navigate(`/booked-car-details/${e?._id} `)
  }

  const handleCancelBooking = (bookingId) => {
    setIsBookingCancel(true)
    setBookingId(bookingId)
  }

  const handleCloseBookingModal = () => {
    setIsBookingCancel(false)
    setBookingId("")
    setReason("")
  }

  const handleChangeReason = (e) => {
    setReason(e.target.value)
    setError("")
  }

  const handleCancelBookingConfirm = () => {
    if (reason === "") {
      setError("Please enter your reason")

      return
    }

    setIsBookingCancel(false)
    setCancelledBookingId([...cancelledBookingId, bookingId])
    setReason("")
    setBookingId("")
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [value, setValue] = useState(0)

  return (
    <>
      <div>
        <Header />
        <section>
          <div className='container-customes px-sm-5 px-2'>
            <div className='pt-3'>
              <h1 className='text-center booked-package-list-media text32 font800'>
                My Bookings
              </h1>
            </div>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  overflowX: "auto",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Package Booked' {...a11yProps(0)} />
                  <Tab
                    label='Hotel Booked'
                    {...a11yProps(1)}
                  />
                  <Tab
                    label='Car Booked'
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                {bookedData &&
                  bookedData
                    .slice()
                    .reverse()
                    .map((e) => {
                      return (
                        <React.Fragment key={e._id}>
                          <section className='pt-2 pb-3'>
                            <div>
                              <Row className='mx-auto'>
                                <div className='col-xl-12 col-lg-6 col-sm-12 col-12 m-0 p-0'>
                                  <div className="inner_CuStom_package">
                                    <p className='booking_list_header text-capitalize text22 font700 py-2'>
                                      {/* {e?.package_details?.[0]?.name} */}
                                    </p>
                                    <div className='booking-list-grid h-100 w-full px-3 py-3'>
                                      <img
                                        src='/C-packega.png'
                                        alt='img'
                                        className='booking-list-header-image'
                                      />
                                      <div className='h-auto w-100'>
                                        <div className='CuStom_package_content my_package_content h-100'>
                                          <div className="d-flex justify-content-between h-100">
                                            <div>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text18 font600">{e?.title}</span>
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Booking Id</span> : {e?._id}
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Booking Date</span> : {e?.bookdate && rightDate(e?.bookdate?.slice(0, 10))}
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Trip Duration</span> :{" "}
                                                {e?.approx_start_date && rightDate(e?.approx_start_date)}{" "}
                                                to {e?.approx_end_date && rightDate(e?.approx_end_date)}
                                              </p>
                                            </div>
                                            <div>
                                              <div className="d-flex flex-column justify-content-between h-100">
                                                <div>
                                                  <div className='text-end mb-2'>
                                                    <span
                                                      className='text-end'
                                                      style={{
                                                        color: "white",
                                                        padding: "5px 6px",
                                                        fontSize: "12px",
                                                        borderRadius: "8px",
                                                        background: cancelledBookingId.includes(e?._id)
                                                          ? "red"
                                                          : "green",
                                                      }}
                                                    >
                                                      {cancelledBookingId.includes(e?._id)
                                                        ? "Booking Cancelled"
                                                        : "Booking Confirmed"}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-end h-100 gap-3'>
                                                  <button
                                                    className='check-availibility-button text14 px-3 py-1'
                                                    onClick={() => handleNavigate(e?._id)}
                                                    style={{ boxShadow: "none" }}
                                                  >
                                                    View Details
                                                  </button>

                                                  {!cancelledBookingId.includes(e?._id) ? (
                                                    <button
                                                      className='cancel_btn text14 py-1 px-3'
                                                      onClick={() => handleCancelBooking(e?._id)}
                                                    >
                                                      Cancel
                                                    </button>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </section>
                        </React.Fragment>
                      )
                    })}
              </TabPanel>
              <TabPanel value={value} index={1}>
                {bookedHotelData &&
                  bookedHotelData
                    .slice()
                    .reverse()
                    .map((e) => {
                      const hasUserRegistrationId = e.hasOwnProperty('user_registration_id');
                      return (
                        <React.Fragment key={e._id}>
                          <section className='pt-2 pb-3'>
                            <div>
                              <Row className='mx-auto'>
                                <div className='col-xl-12 col-lg-6 col-sm-12 col-12 m-0 p-0'>
                                  <div className="inner_CuStom_package">
                                    <p className='booking_list_header text-capitalize text22 font700 py-2'>
                                      {/* {e?.package_details?.[0]?.name} */}
                                    </p>
                                    <div className='booking-list-grid h-100 w-full px-3 py-3'>
                                      <img
                                        src='/C-packega.png'
                                        alt='img'
                                        className='booking-list-header-image'
                                      />
                                      <div className='h-auto w-100'>
                                        <div className='CuStom_package_content my_package_content h-100'>
                                          <div className="d-flex justify-content-between h-100">
                                            <div>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text18 font600">{e?.hotel_details?.[0]?.hotel_name}</span>
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Booking Id</span> : {e?._id}
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Booking Date</span> : {e?.createdAt && rightDate(e?.createdAt?.slice(0, 10))}
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Check In</span> :{" "}
                                                {e?.check_in_date && rightDate(e?.check_in_date?.slice(0, 10))}
                                              </p>
                                              <p className='mb-xl-2 mb-lg-0 text14'>
                                                <span className="text14 font600">Check Out</span> :
                                                {e?.check_out_date && rightDate(e?.check_out_date?.slice(0, 10))}
                                              </p>
                                            </div>
                                            <div>
                                              <div className="d-flex flex-column justify-content-between h-100">
                                                <div className='text-end mb-2'>
                                                  <span
                                                    className='text-end'
                                                    style={{
                                                      color: "white",
                                                      padding: "5px 6px",
                                                      fontSize: "12px",
                                                      borderRadius: "8px",
                                                      background: cancelledBookingId.includes(e?._id)
                                                        ? "red"
                                                        : "green",
                                                    }}
                                                  >
                                                    {cancelledBookingId.includes(e?._id)
                                                      ? "Booking Cancelled"
                                                      : "Booking Confirmed"}
                                                  </span>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-end h-100 gap-3'>
                                                  <button
                                                    className='check-availibility-button text14 px-3 py-1'
                                                    onClick={() => handleHotelNavigate(e)}
                                                    style={{ boxShadow: "none" }}
                                                  >
                                                    View Details
                                                  </button>

                                                  {!cancelledBookingId.includes(e?._id) ? (
                                                    <button
                                                      className='cancel_btn text14 py-1 px-3'
                                                      onClick={() => handleCancelBooking(e?._id)}
                                                    >
                                                      Cancel
                                                    </button>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </section>
                        </React.Fragment>
                      )
                    })}
              </TabPanel>
              <TabPanel value={value} index={2}>

              </TabPanel>
            </Box>

            {mergedData.length === 0 && (
              <div className="no-bookings-container">
                <p className="text-center">Not a Any Bookings</p>
              </div>
            )}

          </div>
        </section>
      </div>

      <Modal
        show={isBookingCancel}
        onHide={handleCloseBookingModal}
        backdrop='static'
        keyboard={false}
        style={{ zIndex: "10101010" }}
        centered
      >
        <Modal.Body>
          <div className='pt-3 pb-2 px-3'>
            <h5 className='text-center text-capitalize'>
              Why Wan't to cancel your booking?
            </h5>

            <div>
              <p className='mt-3'>Write Your Reason</p>
              <textarea
                name=''
                id=''
                rows='5'
                className='p-2'
                value={reason}
                style={{ width: "100%" }}
                onChange={handleChangeReason}
              />
              {error && <p className='text-danger'>{error}</p>}
            </div>

            <div className='logout-btn d-flex justify-content-center mt-3'>
              <button
                className='logout-btn-1'
                variant='secondary'
                onClick={handleCloseBookingModal}
              >
                No
              </button>
              <button
                className='logout-btn-2 ms-2'
                variant='primary'
                onClick={handleCancelBookingConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <hr />

      <Footer />
    </>
  )
}

export default My_package
