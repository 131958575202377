import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faPlus,
    faTrash,
    faClose,
    faEdit,
    faMinus
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { BASE_URL } from "../BASE_URL";
import "./css/Hotels.css";
import { Button, Modal, Form } from 'react-bootstrap';
import MapComponent from "./MapComponent";
import RoomCard from "./RoomCard";
import addressdata from ".././CountryStateCity.json"
import { Policy } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "./Loading";
import { Rating } from "@mui/material";
import ProfileSection from "./SecondNavbar";


function EditCar() {
    const navigate = useNavigate();
    const { carId } = useParams();

    const [loading, setLoading] = useState(true);
    const [editedData, setEditedData] = useState("");
    const [editing, setEditing] = useState(false);

    const [carData, setCarData] = useState({
        car_id: "",
        car_condition: "",
        model_year: "",
        insurance: "",
        registration_number: "",
        color: "",
        pincode: "",
        city: "",
        state: "",
        outStateAllowed: "",
        AC: "",
        price_per_km: "",
        price_per_day: "",
    });

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [otherDetails, setOtherDetails] = useState(['']);
    const [images, setImages] = useState([]);
    const [images2, setImages2] = useState([]);
    const fileInputRef = useRef(null);

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const getCarData = async () => {
        const token = localStorage.getItem("vendorToken");
        setLoading(true);
        try {
            const response = await fetch(`${BASE_URL}vendor_car_syt/getById/${carId}`, {
                method: 'GET',
                headers: {
                    Authorization: token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const car = data.data[0];
            console.log(car);
            setCarData({
                car_id: car.car_id,
                car_condition: car.car_condition,
                model_year: car.model_year,
                insurance: car.insurance,
                registration_number: car.registration_number,
                color: car.color,
                pincode: car.pincode,
                city: car.city,
                state: car.state,
                outStateAllowed: car.outStateAllowed,
                AC: car.AC,
                price_per_day: car.price_per_day,
                price_per_km: car.price_per_km,
            });
            setSelectedState(car.state);
            setSelectedCity(car.city);
            setOtherDetails(car.other || []);
            setImages(car.photos.map(url => ({ url })));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching car details:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarData();
    }, []);

    useEffect(() => {
        if (addressdata) {
            const country = addressdata && addressdata.find((e) => e.name === "India");
            setStates(country.states);
        }
    }, [addressdata]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "state") {
            const selected = e.target.value;
            setSelectedState(selected);
            const cities = states.find((e) => e.name === selected)?.cities || [];
            setCities(cities);
            setSelectedCity("");
        } else if (name === "city") {
            setSelectedCity(value);
        } else {
            setCarData({
                ...carData,
                [name]: value,
            });
        }
    };

    const handleAddImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
        setImages2(prevImages => [...prevImages, ...fileObjects]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleRemoveImage2 = (indexToRemove) => {
        setImages2(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!carData.car_id) {
            toast.error("Car Name is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        if (!carData.car_condition) {
            toast.error("Car condition is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        const d = new Date();
        let year = d.getFullYear();
        if (!carData.model_year || carData.model_year > year || carData.model_year < 1900) {
            toast.error("Model year is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (carData.selectedInsurance === "") {
            toast.error("Insurance status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!carData.registration_number) {
            toast.error("Registration number is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!carData.color) {
            toast.error("Car color is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!carData.pincode || carData.pincode.length !== 6) {
            toast.error("Enter A Valid Pincode", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectedState) {
            toast.error("State is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (!selectedCity) {
            toast.error("City is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (carData.selectedOutStateAllowed === "") {
            toast.error("Out state allowed status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (carData.selectedAC === "") {
            toast.error("AC status is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (images.length + images2.length < 3) {
            toast.error("At least 3 car images are required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (carData.price_per_km === "" || !carData.price_per_km) {
            toast.error("price per kilometer is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }
        if (carData.price_per_day === "" || !carData.price_per_day) {
            toast.error("price per day is required", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            return;
        }

        const formData = new FormData();
        formData.append("car_id", carData.car_id);
        formData.append("car_condition", carData.car_condition);
        formData.append("model_year", carData.model_year);
        formData.append("insurance", carData.insurance);
        formData.append("registration_number", carData.registration_number);
        formData.append("color", carData.color);
        formData.append("pincode", carData.pincode);
        formData.append("state", selectedState);
        formData.append("city", selectedCity);
        formData.append("outStateAllowed", carData.outStateAllowed);
        formData.append("AC", carData.AC);
        formData.append("price_per_day", carData.price_per_day);
        formData.append("price_per_km", carData.price_per_km);

        images.forEach(({ url }) => formData.append("previmages", url));
        images2.forEach(({ file }) => formData.append("photos", file));

        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}vendor_car_syt/update/${carId}`, {
            method: "PUT",
            headers: {
                Authorization: token,
            },
            body: formData,
        });

        const data = await res.json();
        if (res.ok) {
            setImages2([]);
            setImages([]);
            getCarData();
            setEditing(false);
            toast.success("Car Details Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        } else {
            alert(`Failed to update car: ${data.message}`);
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}vendor_car_syt/${carId}`, {
            method: "DELETE",
            headers: {
                Authorization: token,
            },
        });

        const data = await res.json();
        if (res.ok) {
            toast.success("Car Deleted", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            navigate("/vendor/mycars");
        } else {
            alert(`Failed to update car: ${data.message}`);
        }
    };



    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div >

                    <ProfileSection title="My Hotels" />
                    {loading ? <Loading /> :
                        <div style={{ paddingBottom: "30px" }}>
                            {!editing && <>
                                <section className="hotel_section_margin p-4">
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <h5>Car Details</h5>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <FontAwesomeIcon icon={faEdit} className="edit-icon" onClick={() => setEditing(true)} />
                                            <button style={{ marginLeft: "10px", padding: "5px 10px", border: "none", borderRadius: "10px", backgroundColor: "red", color: "white" }} onClick={handleDelete}>Delete Car</button>
                                        </div>
                                    </div>
                                    <div className="hotel-details-container">
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="car_id">Car ID</label>
                                                <input
                                                    type="text"
                                                    id="car_id"
                                                    name="car_id"
                                                    value={carData.car_id}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Car ID"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="car_condition">Car Condition</label>
                                                <input
                                                    type="text"
                                                    id="car_condition"
                                                    name="car_condition"
                                                    value={carData.car_condition}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Car Condition"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="color">Color</label>
                                                <input
                                                    type="text"
                                                    id="color"
                                                    name="color"
                                                    value={carData.color}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Color"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="model_year">Model Year</label>
                                                <input
                                                    type="text"
                                                    id="model_year"
                                                    name="model_year"
                                                    value={carData.model_year}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Model Year"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="insurance">Insurance</label>
                                                <input
                                                    id="insurance"
                                                    name="insurance"
                                                    value={carData.insurance ? "Yes" : "No"}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Insurance"
                                                >
                                                </input>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="registration_number">Registration Number</label>
                                                <input
                                                    type="text"
                                                    id="registration_number"
                                                    name="registration_number"
                                                    value={carData.registration_number}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Registration Number"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="pincode">Pincode</label>
                                                <input
                                                    type="text"
                                                    id="pincode"
                                                    name="pincode"
                                                    value={carData.pincode}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Pincode"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="state">State</label>
                                                <input
                                                    id="state"
                                                    name="state"
                                                    value={selectedState}
                                                    // onChange={handleChange}
                                                    required
                                                >
                                                </input>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">City</label>
                                                <input
                                                    id="city"
                                                    name="city"
                                                    value={selectedCity}
                                                    // onChange={handleChange}
                                                    required
                                                >
                                                </input>
                                            </div>
                                        </div>
                                        <div className="form-group-container">

                                            <div className="form-group">
                                                <label htmlFor="outStateAllowed">Out State Allowed</label>
                                                <input
                                                    id="outStateAllowed"
                                                    name="outStateAllowed"
                                                    value={carData.outStateAllowed ? "Yes" : "No"}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Out State Allowed"
                                                >
                                                </input>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="AC">AC</label>
                                                <input
                                                    id="AC"
                                                    name="AC"
                                                    value={carData.AC ? "Yes" : "No"}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="AC"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    id="price_per_km"
                                                    name="price_per_km"
                                                    value={carData.price_per_km}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Price Per Kilometer"
                                                >
                                                </input>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    id="price_per_day"
                                                    name="price_per_day"
                                                    value={carData.price_per_day}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Price Per Day"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="images-section">
                                        <h5>Car Images</h5>
                                        <div className="hotel-pictures-section">
                                            {images.map((image, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={image.url} alt="Car" className="hotel-image" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                            </>
                            }

                            {editing && <>
                                <section className="hotel_section_margin p-4">
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <h5>Car Details</h5>
                                        <button className="cancel-edit-button" onClick={() => setEditing(false)}>Cancel</button>
                                    </div>
                                    <div className="hotel-details-container">
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="car_id">Car ID</label>
                                                <input
                                                    type="text"
                                                    id="car_id"
                                                    name="car_id"
                                                    value={carData.car_id}
                                                    // onChange={handleChange}
                                                    required
                                                    placeholder="Car ID"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="car_condition">Car Condition</label>
                                                <input
                                                    type="text"
                                                    id="car_condition"
                                                    name="car_condition"
                                                    value={carData.car_condition}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Car Condition"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="color">Color</label>
                                                <input
                                                    type="text"
                                                    id="color"
                                                    name="color"
                                                    value={carData.color}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Color"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="model_year">Model Year</label>
                                                <input
                                                    type="text"
                                                    id="model_year"
                                                    name="model_year"
                                                    value={carData.model_year}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Model Year"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="insurance">Insurance</label>
                                                <select
                                                    id="insurance"
                                                    name="insurance"
                                                    value={carData.insurance}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Insurance"
                                                >
                                                    <option value="" disabled selected>Select Insurance</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="registration_number">Registration Number</label>
                                                <input
                                                    type="text"
                                                    id="registration_number"
                                                    name="registration_number"
                                                    value={carData.registration_number}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Registration Number"
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <label htmlFor="pincode">Pincode</label>
                                                <input
                                                    type="text"
                                                    id="pincode"
                                                    name="pincode"
                                                    value={carData.pincode}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Pincode"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="state">State</label>
                                                <select
                                                    id="state"
                                                    name="state"
                                                    value={selectedState}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select State</option>
                                                    {states.map((state, index) => (
                                                        <option key={index} value={state.name}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">City</label>
                                                <select
                                                    id="city"
                                                    name="city"
                                                    value={selectedCity}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="">Select City</option>
                                                    {cities.map((city, index) => (
                                                        <option key={index} value={city.name}>
                                                            {city.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group-container">

                                            <div className="form-group">
                                                <label htmlFor="outStateAllowed">Out State Allowed</label>
                                                <select
                                                    id="outStateAllowed"
                                                    name="outStateAllowed"
                                                    value={carData.outStateAllowed}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Out State Allowed"
                                                >
                                                    <option value="" disabled selected>Select Out State Allowed</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="AC">AC</label>
                                                <select
                                                    id="AC"
                                                    name="AC"
                                                    value={carData.AC}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="AC"
                                                >
                                                    <option value="" disabled selected>Select AC</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group-container">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    id="price_per_km"
                                                    name="price_per_km"
                                                    value={carData.price_per_km}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Price Per Kilometer"
                                                >
                                                </input>
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    id="price_per_day"
                                                    name="price_per_day"
                                                    value={carData.price_per_day}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Price Per Day"
                                                >
                                                </input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="images-section">
                                        <h5>Car Images</h5>
                                        <FontAwesomeIcon icon={faPlus} className="plus-icon" onClick={handleAddImageClick} />
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                            multiple
                                        />
                                        <div className="hotel-pictures-section">
                                            {images.map((image, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={image.url} alt="Car" className="hotel-image" />
                                                    <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveImage(index)} className="trash-icon" />
                                                </div>
                                            ))}
                                            {images2.map((image, index) => (
                                                <div key={index} className="hotel-pictures-item">
                                                    <img src={image.url} alt="Car" className="hotel-image" />
                                                    <FontAwesomeIcon icon={faTrash} onClick={() => handleRemoveImage2(index)} className="trash-icon" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="submit-section">
                                        <button type="submit" className="submit-button" onClick={handleSubmit}>
                                            Save Changes
                                        </button>
                                    </div>
                                </section>
                            </>}

                        </div>}
                </div >
            </div >
        </>
    );
}

export default EditCar;
