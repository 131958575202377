import React, { useEffect, useRef, useState } from "react";
import Hearer from "./Header";
import "../Project/css/index1.css";
import "../Project/css/custom_package.css";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faChevronDown,
    faHotel,
    faHandHoldingDroplet,
    faHandsHoldingCircle,
    faCalendar,
    faUserDoctor,
    faTaxi,
    faPlane,
    faTrainSubway,
    faPaperclip,
    faBrush,
    faCheck,
    faXmark,
    faXmarkCircle,
    faHandshakeAlt,
    faStar,
    faUsers,
    faArrowRightLong,
    faRupee,
    faIndianRupee,
    faUser,
    faDoorClosed,
    faBowlFood,
    faCookie
} from "@fortawesome/free-solid-svg-icons"
import {
    faCircleCheck,
    faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import loader from "../images/loading.png"
import { Modal, Row } from "react-bootstrap";
import { BASE_URL } from "../BASE_URL";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import countries from "../CountryStateCity.json";
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const rightDate = (dateString) => {
    const [year, month, day] = dateString?.split('-');
    return `${day}-${month}-${year}`;
};

const rightDateToSend = (dateString) => {
    const [day, month, year] = dateString?.split('/');
    return `${year}-${month}-${day}`;
};

const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

function BookingPayment() {
    const { merchentId } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const baseUrl = `${window.location.protocol}//${window.location.host}/`;

    const [cities, setCities] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')

    const selectedStates = countries?.find((e) => e.name === "India")

    const handleStateChange = (e) => {
        const selected = e.target.value
        setSelectedState(selected)
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === selected)
        setCities(selectedCity?.cities)
    }

    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false)
    const handleOpen = () => setShow(true)

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const handleStopLoading = () => {
        setLoading(false)
    }

    const overRef = useRef(null)
    const itenarary = useRef(null)
    const services = useRef(null)
    const hotels = useRef(null)
    const privacy = useRef(null)

    const HEADER_OFFSET = 80; // Adjust this value according to your header's height

    const scrollTo = (section) => {
        let targetRef;

        switch (section) {
            case "overview":
                targetRef = overRef;
                break;
            case "services":
                targetRef = services;
                break;
            case "itenarary":
                targetRef = itenarary;
                break;
            case "hotels":
                targetRef = hotels;
                break;
            case "privacy":
                targetRef = privacy;
                break;
            default:
                targetRef = null;
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop - HEADER_OFFSET, // Subtract header height
                behavior: "smooth",
            });

            // Optionally, you can add a margin after scrolling
            setTimeout(() => {
                if (targetRef.current) {
                    targetRef.current.style.marginTop = "20px"; // Adjust the margin as needed
                }
            }, 500);
        }
    };


    const [bidDetails, setBidDetails] = useState("")
    const [packageDetails, setPackageDetails] = useState("")
    const [adminMarginPercentage, setAdminMarginPercentage] = useState("")

    const [policies, setPolicies] = useState([])
    const firstWord = packageDetails?.travel_by?.split(',')[0];

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [address, setAddress] = useState("")
    const [paymentType, setPaymentType] = useState("Full Payment")

    const handlePaymentTypeSelect = (e) => {
        const selectedValue = e.target.value
        setPaymentType(e.target.value)
        localStorage.setItem("paymentType", selectedValue)
    }

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("bidDetails"))
        setBidDetails(data)
        setName(data?.user_name)
        setEmail(data?.email_id)
        setNumber(data?.contact_number)
        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === data?.state)
        setCities(selectedCity?.cities)
        setSelectedState(data?.state)
        setSelectedCity(data?.city)
    }, []);

    useEffect(() => {
        if (merchentId !== undefined && merchentId !== "" && bidDetails) {
            InsertBooking();
            navigate("/my_package", { state: "fetch" });
        }
    }, [merchentId, bidDetails]);

    const DetailsBid = async () => {
        const res = await fetch(`${BASE_URL}bidpackage/biddetails?_id=${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })

        const data = await res.json()

        if (data.code === 200) {
            setPackageDetails(data?.data?.[0])
            setAdminMarginPercentage(data?.data?.[0]?.admin_margin?.margin_percentage)
        }
    }

    const privacypolicies = async () => {
        const res = await fetch(`${BASE_URL}policy`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        setPolicies(data?.data)
    }

    const Profile = async () => {
        const token = localStorage.getItem("userToken")
        const res = await fetch(`${BASE_URL}user/userprofile`, {
            method: "GET",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        })
        const data = await res.json()
        const userData = data?.data?.[0]?.user_details?.[0]

        const selectedCity = selectedStates?.states && selectedStates?.states?.find((e) => e?.name === userData?.state)
        setCities(selectedCity?.cities)

        setName(userData?.name)
        setEmail(userData?.email_address)
        setNumber(data?.data?.[0]?.phone)
        setSelectedState(userData?.state)
        setSelectedCity(userData?.city)
    }

    useEffect(() => {
        Profile();
        privacypolicies();
        DetailsBid();
    }, []);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [gender, setGender] = useState('');
    const [travellers, setTravellers] = useState([]);

    const handleMakeAPayment = async () => {

        const totalPerson = Number(bidDetails?.total_adult) + Number(bidDetails?.total_child) + Number(bidDetails?.Infant)

        if (travellers?.length < bidDetails?.total_person) {
            toast.error("Please Enter All Travellers Detail!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!name) {
            toast.error("Please fill in the Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            toast.error("Please fill in the Email field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid Email address!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        const numberRegex = /^\d{10}$/;
        if (!number) {
            toast.error("Please fill in the Number field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        } else if (!numberRegex.test(number)) {
            toast.error("Number must be exactly 10 digits!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!selectedState) {
            toast.error("Please select a State!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!selectedCity) {
            toast.error("Please select a City!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        setLoading(true);

        const token = localStorage.getItem("userToken")
        const myId = localStorage.getItem("mySytUserId")
        const mobileno = number;
        const url = location.pathname;
        const amountToPaid = localStorage.getItem("finalPrice");

        let amount;
        if (paymentType === "Partial Payment") {
            amount = Number(amountToPaid);
        } else {
            amount = bidDetails?.total_amount;
        }

        try {
            const res = await fetch(`${BASE_URL}payment/pay`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
                body: JSON.stringify({
                    MERCHANT_USER_ID: myId,
                    mobileNumber: mobileno,
                    // amount: 1,
                    amount: Number(amount),
                    paid_amount: Number(amount),
                    link: url,

                    bid_package_id: bidDetails?.bid_package_id,
                    custom_requirement_id: bidDetails?.custom_package_id,
                    total_adult: bidDetails?.total_adult,
                    total_child: bidDetails?.total_child,
                    total_infant: bidDetails?.total_infant,
                    total_person: bidDetails?.total_person,
                    price_per_person_adult: bidDetails?.priceFromVendorAdult,
                    price_per_person_child: bidDetails?.priceFromVendorChild,
                    price_per_person_infant: bidDetails?.priceFromVendorInfant,
                    total_amount: bidDetails?.total_amount,
                    meal: bidDetails?.meal,
                    meal_type: bidDetails?.meal_type,
                    totaldays: bidDetails?.totaldays,
                    totalnight: bidDetails?.totalnight,
                    hoteltype: bidDetails?.hoteltype,
                    room_sharing: bidDetails?.room_sharing,
                    siteseeing: bidDetails?.siteseeing,
                    departure: bidDetails?.departure,
                    category: bidDetails?.category,
                    transport_mode: bidDetails?.transport_mode,
                    approx_start_date: rightDateToSend(bidDetails?.approx_start_date),
                    approx_end_date: rightDateToSend(bidDetails?.approx_end_date),
                    othere_requirement: bidDetails?.othere_requirement,
                    personal_care: bidDetails?.personal_care,
                    booked_include: bidDetails?.booked_include,
                    booked_exclude: bidDetails?.booked_exclude,
                    booked_itinerary: bidDetails?.booked_itinerary,
                    destination: bidDetails?.destination,
                    other_services_by_agency: bidDetails?.other_services_by_agency,

                    destination_arrival_date: bidDetails?.destination_arrival_date,
                    title: bidDetails?.title,
                    package_type: bidDetails?.packageType,

                    payment_type_on_booking: paymentType,
                    travel_details: travellers,
                    contact_number: number,
                    email_id: email,
                    user_name: name,
                    city: selectedCity,
                    state: selectedState,
                    gst_address: address,
                    mobileNumber: mobileno,

                }),
            });

            const data = await res.json();
            console.log(data)
            if (!data?.success) {
                setLoading(false);
                toast.error(data?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000,
                });
            } else {
                localStorage.setItem('bookingId', data.data.booking_id)
                localStorage.setItem('paymentId', data.data.payment_id)
                if (data?.data?.url) {
                    window.location.href = data?.data?.url
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error)
            toast.error("Number must be exactly 10 digits!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }
    };

    console.log(bidDetails?.approx_start_date)


    const InsertBooking = async () => {
        const token = localStorage.getItem("userToken");
        const bookingId = localStorage.getItem("bookingId");
        const paymentId = localStorage.getItem("paymentId");

        const type = localStorage.getItem("paymentType");
        const amountToPaid = localStorage.getItem("finalPrice");

        let amount;
        if (type === "Partial Payment") {
            amount = Number(amountToPaid);
        } else {
            amount = bidDetails?.total_amount;
        }

        localStorage.removeItem("paymentType");

        setLoading(true);

        const res = await fetch(`${BASE_URL}payment/updateBooking/${bookingId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: "booked",
                payment_id: paymentId,
                transaction_id: merchentId
            }),
        });

        const data = await res.json();
        console.log(data)

        if (data?.success) {
            setLoading(false);
            toast.success("Your Booking Successfully Done!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            setTimeout(() => {
                navigate("/my_package")
            }, 2000);
        } else {
            setLoading(false);
            toast.error(data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
        }

    };


    const getMaxDOB = () => {
        const today = new Date();
        const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
        return maxDate.toISOString().split("T")[0];
    };


    const [editIndex, setEditIndex] = useState(null);

    const handleEdit = (index) => {
        const selectedTraveller = travellers[index];
        setFirstName(selectedTraveller.first_name);
        setLastName(selectedTraveller.last_name);
        setDateOfBirth(selectedTraveller.dob);
        setGender(selectedTraveller.gender);
        setEditIndex(index);
        setShow(true);
    };

    const handleSubmit = () => {
        // Calculate the current age of a traveller
        const calculateAge = (dob) => {
            const birthDate = new Date(dob);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };

        // If updating an existing traveller, exclude them from the count
        const travellersExcludingCurrent = editIndex !== null
            ? travellers.filter((_, index) => index !== editIndex)
            : travellers;

        // Count the number of adults, children, and infants already added (excluding the current traveller being updated)
        const adultCount = travellersExcludingCurrent.filter(traveller => calculateAge(traveller.dob) >= 18).length;
        const childCount = travellersExcludingCurrent.filter(traveller => calculateAge(traveller.dob) >= 2 && calculateAge(traveller.dob) < 18).length;
        const infantCount = travellersExcludingCurrent.filter(traveller => calculateAge(traveller.dob) < 2).length;

        // Determine the age of the traveller to be added or updated
        const travellerAge = calculateAge(dateOfBirth);

        // Check if the limits for each group have been reached
        if (travellerAge >= 18 && adultCount >= bidDetails?.total_adult) {
            toast.error(`You can only add a maximum of ${bidDetails?.total_adult} adults!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (travellerAge >= 2 && travellerAge < 18 && childCount >= bidDetails?.total_child) {
            toast.error(`You can only add a maximum of ${bidDetails?.total_child} children!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (travellerAge < 2 && infantCount >= bidDetails?.total_infant) {
            toast.error(`You can only add a maximum of ${bidDetails?.total_infant} infants!`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        // Proceed with the usual validations
        if (!firstName) {
            toast.error("Please fill in the First Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!lastName) {
            toast.error("Please fill in the Last Name field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!dateOfBirth) {
            toast.error("Please fill in the Date of Birth field!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        if (!gender) {
            toast.error("Please select a Gender!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000,
            });
            return;
        }

        // Create a new traveller object
        const newTraveller = {
            first_name: firstName,
            last_name: lastName,
            dob: dateOfBirth,
            gender: gender,
        };

        // Add or update the traveller
        if (editIndex !== null) {
            const updatedTravellers = [...travellers];
            updatedTravellers[editIndex] = newTraveller;
            setTravellers(updatedTravellers);
            setEditIndex(null);
        } else {
            setTravellers([...travellers, newTraveller]);
        }

        // Clear input fields
        setFirstName('');
        setLastName('');
        setDateOfBirth('');
        setGender('');

        handleClose();
    };

    const [persons, setPersons] = useState(1);

    useEffect(() => {
        if (bidDetails?.total_adult) {
            if (bidDetails?.room_sharing === "single sharing") {
                // Each person gets their own room, so roomsRequired is total_adult
                setPersons(bidDetails.total_adult)
            } else if (bidDetails?.room_sharing === "double sharing") {
                // Two people per room, round up to ensure all have space
                setPersons(Math.ceil(bidDetails.total_adult / 2))
            } else if (bidDetails?.room_sharing === "triple sharing") {
                // Three people per room, round up to ensure all have space
                setPersons(Math.ceil(bidDetails.total_adult / 3))
            }
        }
    }, [bidDetails]);


    const gotPercentageValue = (baseValue, percentage) => {
        return Number(baseValue) * (Number(percentage) / 100);
    }

    // const DetailsBid = async () => {
    //     const res = await fetch(`${BASE_URL}bidpackage/biddetails?_id=${id}`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //         },
    //     })

    //     const data = await res.json()

    //     if (data.code === 200) {
    //         setBidDetails(data?.data?.[0])
    //     }
    // }

    // const total_traveller = packageDetails?.total_adult + packageDetails?.total_child + packageDetails?.Infant
    // const adminAdult = packageDetails?.price_per_person_adult + (packageDetails?.price_per_person_adult * (Number(adminMarginPercentage) / 100))
    // const adminChild = packageDetails?.price_per_person_child + (packageDetails?.price_per_person_child * (Number(adminMarginPercentage) / 100))
    // const adminInfant = packageDetails?.price_per_person_infant + (packageDetails?.price_per_person_infant * (Number(adminMarginPercentage) / 100))
    // const grandTotal = (packageDetails?.total_adult * adminAdult) + (packageDetails?.total_child * adminChild) + (packageDetails?.Infant * adminInfant)

    // Ensure fallback values if any are undefined or null
    const totalAdult = packageDetails?.total_adult || 0;
    const totalChild = packageDetails?.total_child || 0;
    const totalInfant = packageDetails?.Infant || 0;
    const pricePerPersonAdult = packageDetails?.price_per_person_adult || 0;
    const pricePerPersonChild = packageDetails?.price_per_person_child || 0;
    const pricePerPersonInfant = packageDetails?.price_per_person_infant || 0;
    const adminMargin = Number(adminMarginPercentage) || 0;

    // Calculating total number of travellers
    const total_traveller = totalAdult + totalChild + totalInfant;

    // Calculating admin prices per traveller type
    const adminAdult = pricePerPersonAdult + (pricePerPersonAdult * (adminMargin / 100));
    const adminChild = pricePerPersonChild + (pricePerPersonChild * (adminMargin / 100));
    const adminInfant = pricePerPersonInfant + (pricePerPersonInfant * (adminMargin / 100));

    // Calculating grand total
    const grandTotal = (totalAdult * adminAdult) + (totalChild * adminChild) + (totalInfant * adminInfant);


    const [finalPrice, setFinalPrice] = useState(null)
    const [divide, setDivide] = useState(null)
    const [checkBoxValue, setCheckBoxValue] = useState("50")

    const handleChange = (event) => {
        const item = event.target.value;
        setCheckBoxValue(event.target.value)
        setDivide(gotPercentageValue(grandTotal, item))
        const amount = gotPercentageValue(grandTotal, item)
        localStorage.setItem("finalPrice", amount)
    };

    // const handleChange = (event) => {
    //     const item = event.target.value;
    //     setCheckBoxValue(event.target.value)
    //     setFinalPrice(gotPercentageValue(bidDetails?.total_amount, item))
    //     const amount = gotPercentageValue(bidDetails?.total_amount, item)
    //     localStorage.setItem("finalPrice", amount)
    // };


    useEffect(() => {
        if (paymentType === "Partial Payment")
            setDivide(gotPercentageValue(grandTotal, "50"))
        const amount = gotPercentageValue(bidDetails?.total_amount, "50")
        localStorage.setItem("finalPrice", amount)
    }, [paymentType]);

    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const tomorrow = new Date();
    tomorrow.setDate(currentDate.getDate() + 1);
    const formattedTomorrow = tomorrow.toISOString().split("T")[0];
    const yesterday = new Date();
    yesterday.setDate(currentDate.getDate() - 1);
    const formattedYesterday = yesterday.toISOString().split("T")[0];

    const handleDelete = (index) => {
        const updatedTravellers = travellers.filter((_, i) => i !== index);
        setTravellers(updatedTravellers); // assuming you have a state setter for travellers
    };

    console.log(packageDetails)

    return (
        <>
            <Hearer />
            <ToastContainer />

            <div className="">
                <div className="CuStom_package_image">
                    <div className="packagebooking-box">
                        <ToastContainer />
                        <div className="booking-header">
                            <div className="booking-header-content container">
                                <div>
                                    <h4 className="mb-1 text22">
                                        {packageDetails?.departure} to {packageDetails?.destination}
                                        {bidDetails?.room_sharing !== "" && (
                                            <span className="mb-0">{persons} Room : {bidDetails?.total_person}  Person</span>
                                        )}
                                    </h4>
                                    <p className="booking-header-travelling-dates mb-0 text14">{packageDetails?.start_date1} <span className="mx-2"><FontAwesomeIcon icon={faArrowRightLong} /></span> <span>{packageDetails?.total_days}D/{packageDetails?.total_nights}N</span> <span className="mx-2"><FontAwesomeIcon icon={faArrowRightLong} /></span> {packageDetails?.end_date1}</p>
                                </div>
                                <div className="booking-header-pricing">
                                    <p className="mb-0 mt-1 sm-mt-0 text20 font800">
                                        <FontAwesomeIcon icon={faIndianRupee} className="me-1" />
                                        {
                                            (packageDetails?.price_per_person_adult || 0) +
                                            ((packageDetails?.price_per_person_adult || 0) * (Number(adminMarginPercentage) / 100 || 0))
                                        }/person
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="packagebooking-grid container mt-4">
                            <div className="packagebooking-tabs">
                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(1)}>
                                        <h5 className="mb-0 text18">1.Traveller Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 1 ? 'active' : ''}`}>
                                        {activeAccordion === 1 &&
                                            <>
                                                <div className="booking-traveller-box">
                                                    <div className="booking-traveller-box-header">
                                                        <div>
                                                            <h5 className="mb-0 text15">1.TOTAL TRAVELLER</h5>
                                                        </div>
                                                        <div className="d-flex gap-2">
                                                            <span className="text12">
                                                                {packageDetails?.total_adult} Adults
                                                                {packageDetails?.total_child > 0 && `, ${packageDetails?.total_child} Children`}
                                                                {packageDetails?.Infant > 0 && `, ${packageDetails?.Infant} Infants`}
                                                            </span>

                                                        </div>
                                                        {travellers?.length !== total_traveller && (
                                                            <div className="ms-auto">
                                                                <h6 onClick={handleOpen}>+ Add Travellers Details</h6>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="booking-traveller-box-body mx-2 mt-4">
                                                        {travellers && travellers?.map((e, index) => (
                                                            <div className="booking-traveller-box-traveller-list" key={index}>
                                                                <div className="text13">
                                                                    <b>{index + 1}.{e?.first_name} {e?.last_name}</b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>{e?.gender}</b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>
                                                                        {rightDate(e?.dob)}({calculateAge(e?.dob)})
                                                                    </b>
                                                                </div>
                                                                <div className="text13">
                                                                    <b>
                                                                        {Number(calculateAge(e?.dob)) > 12
                                                                            ? 'Adult'
                                                                            : Number(calculateAge(e?.dob)) >= 2 && Number(calculateAge(e?.dob)) <= 12
                                                                                ? 'Children'
                                                                                : Number(calculateAge(e?.dob)) < 2
                                                                                    ? 'Infant'
                                                                                    : ""}
                                                                    </b>
                                                                </div>
                                                                <div className="text13 d-flex">
                                                                    <p className="mb-0 me-3" onClick={() => handleEdit(index)}>UPDATE</p>
                                                                    <p className="mb-0 text-danger" onClick={() => handleDelete(index)}>DELETE</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form my-4">
                                                        <div>
                                                            <h5 className="text15">2.PLEASE ENETER CONTACT DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="booking-traveller-form-details">
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Name <span className="text-red">*</span></label>
                                                                <input type="text" value={name} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Email <span className="text-red">*</span></label>
                                                                <input type="email" value={email} />
                                                            </div>
                                                            <div className="d-flex flex-column gap-2">
                                                                <label htmlFor="" className="font600">Mobile Number <span className="text-red">*</span></label>
                                                                <input type="tel" value={number} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="booking-traveller-form mb-3">
                                                        <div>
                                                            <h5 className="text15">3.PLEASE ENTER GST DETAILS <span className="text-red">*</span></h5>
                                                        </div>
                                                        <div className="booking-traveller-form-details">
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">Address</p>
                                                                    <textarea name="" id="" className="w-100" value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">Gst State <span className="text-red">*</span></p>
                                                                    <select name="" id="" className="w-100" value={selectedState} onChange={handleStateChange}>
                                                                        <option value="">select state</option>
                                                                        {selectedStates && selectedStates?.states.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <div className="d-flex flex-column gap-2 w-100">
                                                                    <p htmlFor="" className="text14 font600">City</p>
                                                                    <select name="" id="" className="w-100" value={selectedCity} onChange={(e) => setSelectedCity(e?.target?.value)}>
                                                                        <option value="">select city</option>
                                                                        {cities && cities?.map((e) => (
                                                                            <option value={e?.name}>{e?.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-1">
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(2)}>
                                        <h5 className="mb-0 text18">2.Package Details</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 2 ? 'active' : ''}`}>
                                        {activeAccordion === 2 &&
                                            <>
                                                <div className=' col-12 text-center m-0 p-0 posi-first'>
                                                    <div className='d-flex mt-3 overview-packages'>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("overview")}
                                                            >
                                                                Overview
                                                            </button>
                                                        </div>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("services")}
                                                            >
                                                                Services
                                                            </button>
                                                        </div>
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("itenarary")}
                                                            >
                                                                Itinerary
                                                            </button>
                                                        </div>
                                                        {packageDetails?.hotel_itienrary?.length !== 0 && (
                                                            <div className='me-3 mb-3'>
                                                                <button
                                                                    class='button-47'
                                                                    role='button'
                                                                    onClick={() => scrollTo("hotels")}
                                                                >
                                                                    Hotels
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className='me-3 mb-3'>
                                                            <button
                                                                class='button-47'
                                                                role='button'
                                                                onClick={() => scrollTo("privacy")}
                                                            >
                                                                Policy
                                                            </button>
                                                        </div>
                                                        <ToastContainer />
                                                    </div>

                                                    <section className='mb-3 mt-3' ref={overRef}>
                                                        <div className=''>
                                                            <div>
                                                                <button className='w-100 over-btn text16 lh19'>
                                                                    <img src='/c-icon.png' className=' me-3' />
                                                                    Overview
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='text-start mb-4'>
                                                        <div className=''>
                                                            <Row>
                                                                <div className='col-xl-6 col-lg-12 mb-3'>
                                                                    <div className='amh_to_hima'>
                                                                        <div>
                                                                            <h6 className='pb-2 mb-0 cmntext text-capitalize font700 text18'>
                                                                                {packageDetails?.departure} to {packageDetails?.destination}
                                                                            </h6>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className=''>
                                                                                <div className=''>
                                                                                    <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUser}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Adult (12+yrs)</b> : {packageDetails?.total_adult}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUser}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Kids(2 to 12 years)</b> : {packageDetails?.total_child}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUser}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Infant (0 to 2 years)</b> : {packageDetails?.Infant}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faUsers}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Total Travellers</b> :{" "}
                                                                                            {total_traveller}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHandsHoldingCircle}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Any Traveller Needs Personal Care?</b>{" "}
                                                                                            : {bidDetails?.personal_care}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faCalendar}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Total Days & Night</b> : {packageDetails?.total_days} & {packageDetails?.total_nights}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='mt-3 mb-2'>
                                                                                <h6 className='cmntext font700 text18'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faCalendar}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Approx Departure Between{" "}
                                                                                </h6>
                                                                            </div>
                                                                            <div className='cmn'>
                                                                                <p className='cmnp ms-4 py-2 text14'>
                                                                                    {packageDetails?.start_date1} to {packageDetails?.end_date1}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='col-xl-6 col-lg-12 mb-3'>
                                                                    <div className='amh_to_hima'>
                                                                        <div>
                                                                            <h6 className='pb-2 mb-0 cmntext font700 text18'>
                                                                                <FontAwesomeIcon
                                                                                    icon={faHotel}
                                                                                    className='cmnclr'
                                                                                />{" "}
                                                                                Hotel & Food
                                                                            </h6>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className=''>
                                                                                <div className=''>
                                                                                    <div className='cmn px-2 px-sm-4 px-md-4 px-lg-4 px-xl-4 py-2'>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faHotel}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Hotel Type</b> : {packageDetails?.hotel_types === "" ? "N/A" : packageDetails?.hotel_types}
                                                                                        </p>
                                                                                        {bidDetails?.room_sharing !== "" && (
                                                                                            <p className='cmnp py-1 text14'>
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faDoorClosed}
                                                                                                    className='cmnclr'
                                                                                                />{" "}
                                                                                                <b>Room Sharing</b> : {packageDetails?.room_sharing}
                                                                                            </p>
                                                                                        )}
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faBowlFood}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal</b> : {packageDetails?.meal_required !== "" ? packageDetails?.meal_required : "N/A"}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faCookie}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal Type</b> : {packageDetails?.meal_types !== "" ? packageDetails?.meal_types : "N/A"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-3 mb-2'>
                                                                            {packageDetails?.travel_by?.length > 10 ? (
                                                                                <h6 className='cmntext text18 font600'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={firstWord?.toLowerCase() === "train" ? faTrainSubway : firstWord?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Travel By
                                                                                </h6>
                                                                            ) : (
                                                                                <h6 className='cmntext text18 font600'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={packageDetails?.travel_by?.toLowerCase() === "train" ? faTrainSubway : packageDetails?.travel_by?.toLowerCase() === "flight" ? faPlane : faTaxi}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Travel By
                                                                                </h6>
                                                                            )}
                                                                        </div>
                                                                        <div className='cmn'>
                                                                            <p className='cmnp ms-4 py-2 text14'>{packageDetails?.travel_by}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>

                                                            <div className='cmn  text-start'>
                                                                <div className='mt-3 mb-2 text-start cmnp ms-4'>
                                                                    <h6 className='cmntext font700 text18'>
                                                                        <FontAwesomeIcon
                                                                            icon={faPaperclip}
                                                                            className='cmnclr'
                                                                        />{" "}
                                                                        Additional Requirements By Traveller
                                                                    </h6>
                                                                </div>
                                                                <p className='cmnp ms-4 py-2 mb-3 text14'>{packageDetails?.additional_requirement}</p>
                                                            </div>

                                                            <div className='cmn  text-start mt-3'>
                                                                <div className='mt-3 mb-2 text-start cmnp ms-4'>
                                                                    <h6 className='cmntext font700 text18'>
                                                                        <FontAwesomeIcon
                                                                            icon={faPaperclip}
                                                                            className='cmnclr'
                                                                        />{" "}
                                                                        Other Services By Agency
                                                                    </h6>
                                                                </div>
                                                                <p className='cmnp ms-4 py-2 mb-3 text14'
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: packageDetails?.other_services_by_agency,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='details-service' ref={services}>
                                                        <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon className='me-3' icon={faBrush} /> Services</button>
                                                        <div className='media-services IE-border'>
                                                            <div className='row gx-0 padding-inside-row mx-2 mx-sm-4 mt-3 mb-4'>
                                                                <div className='col-12 col-md-6'>
                                                                    <div className="hotel-Included me-0 me-sm-3">
                                                                        <h4 className="font700 text18 text-start">
                                                                            <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                                                            Included
                                                                        </h4>
                                                                        <div class='content-green'>
                                                                            {bidDetails?.booked_include?.map((e) => {
                                                                                return (
                                                                                    <>
                                                                                        <p className="text14 margin-bottom-p d-flex align-items-start mb-0">
                                                                                            <FontAwesomeIcon icon={faCheck} className="text16 me-2" />
                                                                                            <span className="mb-0" dangerouslySetInnerHTML={{ __html: e.include_services_value }}>
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-6 col-12'>
                                                                    <div className='hotel-excluded ms-0 ms-sm-3'>
                                                                        <h4 className="font700 text18 text-start">
                                                                            <FontAwesomeIcon icon={faCircleXmark} />{" "}
                                                                            Excluded
                                                                        </h4>
                                                                        <div className='content-red'>
                                                                            {bidDetails?.booked_exclude?.map((e1) => {
                                                                                return (
                                                                                    <>
                                                                                        <p className="text14 margin-bottom-p d-flex align-items-start mb-0">
                                                                                            <FontAwesomeIcon icon={faXmark} className="me-2 text16" />
                                                                                            <span dangerouslySetInnerHTML={{ __html: e1.exclude_services_value }}>
                                                                                            </span>
                                                                                        </p>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section className='text-start' ref={itenarary}>
                                                        <div className=''>

                                                            <div
                                                                className='details-service mt-4'
                                                                ref={itenarary}
                                                            >
                                                                <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                                                                    icon={faHandshakeAlt}
                                                                    className='me-2'
                                                                />Itinerary</button>
                                                            </div>

                                                            <div className='amh_to_hima'>
                                                                {packageDetails?.itineries?.map((e3, i) => {
                                                                    return (
                                                                        <>
                                                                            <div className="py-2">
                                                                                <h6 className="cmntext mb-2 font700 text18">Day {i + 1}</h6>
                                                                                <div className='cmn p-2'>

                                                                                    <Row className='align-items-start'>
                                                                                        <div className='col-lg-9'>
                                                                                            <div className='py-1'>
                                                                                                <h6 className='cmnp cmnclr1 text15 font600'>Title</h6>
                                                                                                <p className='cmnp cmnclr font-weight-bolder text14'>
                                                                                                    {e3?.title}{" "}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className='py-1'>
                                                                                                <h6 className='cmnp cmnclr1 text15 font600'>Activity</h6>
                                                                                                <p className='cmnp cmnclr font-weight-bolder text14'>
                                                                                                    <div
                                                                                                        style={{ textAlign: "justify" }}
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: e3?.activity,
                                                                                                        }}
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        {e3?.photo && <div className='col-lg-3'>
                                                                                            <div className='text-start text-sm-end py-2'>
                                                                                                <img src={e3?.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                                                                            </div>
                                                                                        </div>}
                                                                                    </Row>


                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </section>

                                                    {packageDetails?.hotel_itienrary?.length !== 0 && (
                                                        <div className='details-service mt-4' ref={hotels} >
                                                            <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                                                                icon={faHotel}
                                                                className='me-2'
                                                            />Hotels</button>
                                                        </div>
                                                    )}

                                                    {packageDetails?.hotel_itienrary?.length !== 0 && (
                                                        <section className=''>
                                                            <div className='hotels-section'>
                                                                <div className='last-hotels'>

                                                                    {/* <!--------------------------- part-1 ---------------------------------------------> */}

                                                                    <div className=''>
                                                                        {packageDetails?.hotel_itienrary && packageDetails?.hotel_itienrary
                                                                            ?.sort((a, b) => {
                                                                                // Convert the first value in the days string to a number and sort by that value
                                                                                const firstDayA = Math.min(...a.days.split(',').map(Number));
                                                                                const firstDayB = Math.min(...b.days.split(',').map(Number));
                                                                                return firstDayA - firstDayB;
                                                                            })
                                                                            ?.map((e, index) => (
                                                                                <div className={`cmn ${index !== packageDetails?.hotel_itienrary?.length - 1 ? 'mb-4' : ''}`}>
                                                                                    <div className='row gx-5 p-2 second-changes'>
                                                                                        <div className='col-xl-6 col-12'>
                                                                                            <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                                                                                <div className="carousel-indicators">
                                                                                                    {e?.hotel_photo?.map((_, idx) => (
                                                                                                        <button
                                                                                                            key={idx}
                                                                                                            type="button"
                                                                                                            data-bs-target={`#carouselExampleCaptions-${index}`}
                                                                                                            data-bs-slide-to={idx}
                                                                                                            className={idx === 0 ? "active" : ""}
                                                                                                            aria-current={idx === 0 ? "true" : "false"}
                                                                                                            aria-label={`Slide ${idx + 1}`}
                                                                                                        ></button>
                                                                                                    ))}
                                                                                                </div>
                                                                                                <div className="carousel-inner">
                                                                                                    {e?.hotel_photo?.map((ele, idx) => (
                                                                                                        <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                                                                            <img src={ele} className="d-block w-100" alt="..." />
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                                    <span className="visually-hidden">Previous</span>
                                                                                                </button>
                                                                                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                                    <span className="visually-hidden">Next</span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-xl-6 col-12 first-change'>
                                                                                            <div className='mb-md-2'>
                                                                                                <span className='d-flex day1-2  text-left align-items-center'>
                                                                                                    <div className='day-1-2 py-1 font-changes'>
                                                                                                        <h6 className="text18 font600">Day {e?.days}</h6>
                                                                                                    </div>
                                                                                                    <div className=' text-start font-changes'>
                                                                                                        <h6 className='mb-1 text18 font700'>
                                                                                                            {e?.hotel_name}
                                                                                                        </h6>
                                                                                                        <p className='mb-0 text-start text14'>
                                                                                                            {e?.hotel_address}, {e?.hotel_city},{e?.hotel_state}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='text-start hotels-star'>
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faStar}
                                                                                                    className='p-1'
                                                                                                    style={{ color: "yellow" }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faStar}
                                                                                                    className='p-1'
                                                                                                    style={{ color: "yellow" }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faStar}
                                                                                                    className='p-1'
                                                                                                    style={{ color: "yellow" }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faStar}
                                                                                                    className='p-1'
                                                                                                    style={{ color: "yellow" }}
                                                                                                />
                                                                                                <FontAwesomeIcon
                                                                                                    icon={faStar}
                                                                                                    className='p-1'
                                                                                                    style={{ color: "yellow" }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='text-start hotels-footer'>
                                                                                                <p>
                                                                                                    {e?.hotel_description}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    )}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="booking-accordion-item mb-2" ref={privacy}>
                                    <div className="booking-accordion-header" onClick={() => toggleAccordion(3)}>
                                        <h5 className="mb-0 text18">3.Terms & Conditions</h5>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </div>
                                    <div className={`accordion-content ${activeAccordion === 3 ? 'active' : ''}`}>
                                        {activeAccordion === 3 &&
                                            <>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[2]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[0]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                                <div className='mt-5'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: policies?.[1]?.term_and_condition_content,
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="packagebooking-payment-card">
                                <div className="packagebooking-payment-type-box">
                                    <p className="mb-0">Payment Type</p>
                                    <select value={paymentType} onChange={handlePaymentTypeSelect} name="" id="">
                                        <option value="">select</option>
                                        <option value="Full Payment">Full Payment</option>
                                        <option value="Partial Payment">Partial Payment</option>
                                    </select>
                                </div>

                                {paymentType === "Partial Payment" && (
                                    <div className="partial-payment-box mt-3 d-flex justify-content-evenly">
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 30}
                                                value="30"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">30%</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 50}
                                                value="50"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">50%</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <input
                                                type="radio"
                                                name="partial_payment"
                                                checked={Number(checkBoxValue) === 100}
                                                value="100"
                                                onChange={handleChange}
                                            />
                                            <p className="mb-0 ms-2">100%</p>
                                        </div>
                                    </div>
                                )}

                                <div className="packagebooking-payment-card-body my-3">
                                    <div>
                                        <p className="mb-0">Adults</p>
                                        <p className="mb-0">{packageDetails?.total_adult} * {adminAdult} = {packageDetails?.total_adult * adminAdult} <span></span></p>
                                    </div>
                                    {packageDetails?.total_child !== 0 && (
                                        <div>
                                            <p className="mb-0">Children</p>
                                            <p className="mb-0">{packageDetails?.total_child} * {adminChild} = {packageDetails?.total_child * adminChild} <span></span></p>
                                        </div>
                                    )}
                                    {packageDetails?.Infant !== 0 && (
                                        <div>
                                            <p className="mb-0">Infants</p>
                                            <p className="mb-0">{packageDetails?.Infant} * {adminInfant} = {packageDetails?.Infant * adminInfant} <span></span></p>
                                        </div>
                                    )}

                                    <hr className="my-1" />

                                    <div>
                                        <p className="mb-0">Total Amount</p>
                                        <p className="mb-0">₹{paymentType === "Full Payment" ? grandTotal : divide} <span></span></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">GST</p>
                                        <p className="mb-0">
                                            ₹0
                                        </p>
                                    </div>
                                    <div>
                                        <p className="mb-0">Other Tax</p>
                                        <p className="mb-0">
                                            ₹0
                                        </p>
                                    </div>

                                </div>
                                <div className="packagebooking-payment-card-header">
                                    <span className="mb-0">Grand Total</span>
                                    <h5 className="mb-0">₹{paymentType === "Full Payment" ? grandTotal : divide}</h5>
                                </div>
                                <div className="packagebooking-payment-card-footer mt-3">
                                    {loading ? (
                                        <img onClick={handleStopLoading} src={loader} alt="" className="spinner" />
                                    ) : (
                                        <button onClick={handleMakeAPayment}>Make Payment</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >
                <Modal.Body className="pt-3 px-5">
                    <div className="traveller-information-add-modal">
                        <div className="traveller-information-add-modal-header">
                            <h5>Add Traveller Details</h5>
                        </div>
                        <div>
                            <div className="mt-4">
                                <h5 className="mb-0">Basic Informations <span style={{ fontSize: "12px" }}>({travellers?.length + 1} Traveller)</span></h5>
                                <hr />
                            </div>
                            <div className="traveller-detail-form">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">First Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value?.trim())}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Last Name</label>
                                    <input
                                        type="text"
                                        placeholder="Enter a Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value?.trim())}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Date Of Birth</label>
                                    <input
                                        type="date"
                                        value={dateOfBirth}
                                        onChange={(e) => setDateOfBirth(e.target.value)}
                                        max={formattedYesterday}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Gender</label>
                                    <select
                                        name=""
                                        id=""
                                        value={gender}
                                        onChange={(e) => setGender(e.target.value)}
                                    >
                                        <option value="">select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center my-4">
                        <button
                            className="reject-modal reject-modal-no"
                            onClick={handleClose}
                        >
                            No
                        </button>
                        <button
                            className="reject-modal reject-modal-yes ms-2"
                            onClick={handleSubmit}
                        >
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <hr />
            <Footer />
        </>
    );
}

export default BookingPayment;
