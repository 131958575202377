import React from 'react';
import { format } from 'date-fns';

const RoomCard = ({ room }) => {

    const startdate = room.start_date?.slice(0,10) ;
    const enddate = room.end_date?.slice(0,10) ;
    return (
        <div className="room-card ms-0" style={{marginTop: "10px"}}>
            {/* {console.log(room)} */}
            <img src={room.photos.length !== 0 ? room.photos[0].url : room.new_photos[0].url} alt="Room" className="room-image" />
            <div className="room-details">
                <div className="room-title text15 font600">{room.room_title}</div>
                <div className="room-total-rooms text14" style={{fontSize:"11px", marginBottom:"5px"}}>Total Rooms:{" "+room.total_rooms}</div>
                <div className='room-highlight-container'>
                    {room.room_highlights.map((highlight, index) => (
                        <>
                            <div className="room-highlight text14" key={index}><span style={{ margin: "0px 3px" }}>•</span>{highlight}</div>
                        </>
                    ))}
                </div>
                {/* <div style={{display:"flex", fontSize:"10px", gap:"5px"}}>
                    <div>{startdate}</div>- 
                    <div>{enddate}</div>
                </div> */}
                
                <div style={{fontSize:"14px"}}><span>Status: </span>{" "+room.status}</div>
                <div className="room-price text16">₹ {room.price} <span className="room-per-night ">per night</span></div>
            </div>

        </div>
    );
};

export default RoomCard;
