import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from "../BASE_URL";
import './css/HotelBooking.css'
import ProfileSection from "./SecondNavbar";

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
};

function calculateAge(birthDateString) {
    const birthDate = new Date(birthDateString);
    const today = new Date();
  
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();
  
    // Adjust age if the birth month or day hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
  
    return age;
  }

const ViewHotelBooking = () => {
    const navigate = useNavigate();

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);

    const { id } = useParams();

    const [bookingData, setBookingData] = useState([]);

    const getBookedHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_booking_syt/hotel_book_details/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setBookingData(data.data[0]);
        console.log(data.data[0]);
        setbookingstatus(data.data[0].status);

    };

    useEffect(() => {
        getBookedHotel();
    }, []);

    const [bookingstatus, setbookingstatus] = useState("");

    const handleStatusChange = (e) => {
        setbookingstatus(e.target.value);
    };

    const handleUpdateStatus = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}hotel_booking_syt/updateStatus/${id}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                status: bookingstatus
            }),
        });

        if (!res.ok) {
            throw new Error(`Failed to update status `);
        }
        else {
            toast.success("Status Updated", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            getBookedHotel();
            return res.json();
        }

    };

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <ToastContainer />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                    <ProfileSection title="Hotel Booking" />

                    <section className="booked_packega_margin p-4">
                        {bookingData.length !== 0 && <div>
                            <div className="booked-details">
                                <div className="booked-hotel-details-container">
                                    <div className='booked-details-title'>Hotel Details</div>
                                    {bookingData.hotel_details ? (
                                        <div>
                                            <div className="" style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                                <span className="font700 text15">Hotel Name:</span>
                                                <p className="font500 text15">{bookingData.hotel_details[0].hotel_name}</p>
                                            </div>
                                            <div className="" style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                                <span className="font700 text15">Address: </span>
                                                <p className="font500 text15">{bookingData.hotel_details[0].hotel_address}</p>
                                            </div>
                                            <div className="" style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                                <span className="font700 text15">Booked Room: </span>
                                                <p className="font500 text15">{bookingData.room_details[0].room_title}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>N/A</div>
                                    )
                                    }
                                </div>
                                <div className="booked-user-details-container">
                                    <div className='booked-details-title'>Costumer Details</div>
                                    <div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                            <span className="font700 text15">FullName:</span>
                                            <p className="font500 text15">{bookingData.users ? bookingData.users[0].customer_details.name : "N/A"}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                            <span className="font700 text15">Email:</span>
                                            <p className="font500 text15">{bookingData.users ? bookingData.users[0].customer_details.email_address : "N/A"}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                            <span className="font700 text15">Mobile No.:</span>
                                            <p className="font500 text15">{bookingData.users ? bookingData.users[0].phone : "N/A"}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 3fr" }}>
                                            <span className="font700 text15">Address:</span>
                                            <p className="font500 text15">{bookingData.users ? bookingData.users[0].customer_details.city : "N/A"} {bookingData.users ? bookingData.users[0].customer_details.state : "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="booked-status-container">
                                    <div className='booked-details-title'>Booked Status</div>
                                    <div className='booked-status'>{bookingData.status === 'pending' ? "Pending" : bookingData.status === "approve" ? "Approved" : bookingData.status === "booked" ? "Booked" : "Rejected"}</div>
                                    <div style={{ paddingTop: "10px", borderTop: "1px solid #09646D" }}>
                                        <div className="update-status-title">Update Status:</div>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <select value={bookingstatus} onChange={handleStatusChange} className="update-status-select">
                                                <option value="" selected>Select</option>
                                                <option value="pending">Pending</option>
                                                <option value="approve">Approve</option>
                                                <option value="reject">Reject</option>
                                            </select>
                                            <button onClick={handleUpdateStatus} className="update-status-button">Update</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div className='booked-room-details'>Booking Details</div>
                                <div className="row">
                                    <div className="col-3">
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">Booked Date:</span>
                                            <p className="font500 text15 mb-1">{formatDate(bookingData?.createdAt?.slice(0, 10))}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">Total Rooms:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.total_booked_rooms}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">CheckIn:</span>
                                            <p className="font500 text15 mb-1">{formatDate(bookingData?.check_in_date?.slice(0, 10))}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">CheckOut:</span>
                                            <p className="font500 text15 mb-1">{formatDate(bookingData?.check_out_date?.slice(0, 10))}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">Adults:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.total_adult}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr" }}>
                                            <span className="font700 text15">Childrens:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.total_child}</p>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1.5fr" }}>
                                            <span className="font700 text15">Total Amount:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.price}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1.5fr" }}>
                                            <span className="font700 text15">Admin Margin:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.admin_margin_price_adult + bookingData?.admin_margin_price_child}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1.5fr" }}>
                                            <span className="font700 text15">Transaction Id:</span>
                                            <p className="font500 text15 mb-1">{bookingData?.transaction_id}</p>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1.5fr" }}>
                                            <span className="font700 text15">Payment Type:</span>
                                            <p className="font500 text15 mb-1">UPI</p>
                                            {/* <p className="font500 text15 mb-1">{bookingData?.payment_type}</p> */}
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="mb-2">
                                            <span className="font700 text15">Travellers:</span>
                                        </div>
                                        {bookingData?.travel_details?.map((e, index) => (
                                            <div className="me-5" style={{ display: "grid", gridTemplateColumns: "2fr 0.7fr 0.7fr 1fr" }}>
                                                <p className="font500 text15 mb-1">{index + 1}. {e?.first_name} {e?.last_name}</p>
                                                <p className="font500 text15 mb-1">{e?.gender}</p>
                                                <p className="font500 text15 mb-1">{calculateAge(e?.dob)}</p>
                                                <p className="font500 text15 mb-1">{formatDate(e?.dob?.slice(0, 10))}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="booked-rooms-container">

                            </div>
                        </div>}
                    </section>
                </div>
            </div>
        </>
    );
};

export default ViewHotelBooking;
