import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import { Rating } from "@mui/material";
import Button from "react-bootstrap/Button";
import './css/Hotels.css'
import ProfileSection from "./SecondNavbar";
import Carousel from 'react-bootstrap/Carousel';

const MyHotels = () => {
    const navigate = useNavigate();

    const handleViewHotel = (hotel) => {
        navigate(`/vendor/hotel-details/${hotel._id}`, {
            state: { source: "edit" }
        });
    };

    const [editedata, setEditedData] = useState("");

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    useEffect(() => {
        Call();
    }, []);


    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const handleAddHotels = () => {
        navigate(`/vendor/myhotels/Addhotels`)
    }

    const [hotels, setHotels] = useState([]);
    const [error, setError] = useState(null);

    const fetchHotels = async () => {
        try {
            const token = localStorage.getItem("vendorToken");
            const response = await fetch(`${BASE_URL}hotel_syt/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            }); // Replace with your actual API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            setHotels(data.data);

        } catch (error) {
            setError(error.message);
        }
    };


    useEffect(() => {
        fetchHotels();
    }, []);




    return (
        <>
            <Header2 />
            <div className="costum_container">
                <My_pannel />
                <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
                    <ProfileSection title="My Hotels" />

                    <section className="booked_packega_margin p-4">
                        <div className="d-flex justify-content-end mb-2">
                            <Button
                                variant="primary"
                                className="ms-2"
                                style={{
                                    backgroundColor: "#155E75",
                                    padding: "8px 40px",
                                }}
                                onClick={handleAddHotels}
                            >
                                Add Hotels
                            </Button>
                        </div>
                        
                        <div className="">
                            <div className="row gy-4">
                                {hotels.length !== 0 ? hotels.map((hotel) => (
                                    <div className="col-6">
                                        <div className="hotel-list-border">
                                            <div className="row">
                                                <div className="col-6">
                                                    {/* <img src={hotel.hotel_photo[0] ? hotel.hotel_photo[0] : '/hotel1.3.png'} className="hotel-card-image" alt="photos" /> */}
                                                    <Carousel style={{ height: "200px", width: "300px" }}>
                                                        {hotel?.hotel_photo?.map((e, index) => (
                                                            <Carousel.Item key={index} interval={2000} >
                                                                <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" style={{ borderRadius: "10px" }} />
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                <div className="col-6">
                                                    <div className="d-flex flex-column justify-content-between h-100">
                                                        <div>
                                                            <div className="d-flex justify-content-between">
                                                                <div className="hotel-card-name">{hotel.hotel_name}</div>
                                                                <div>
                                                                    {hotel.hotel_type}<FontAwesomeIcon
                                                                        icon={faStar}
                                                                        className='hotel-card-rating-star'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="text14"><span className="font600">Address</span>: {hotel.hotel_address}, {hotel.city}, {hotel.state}</div>
                                                            <div className="text14 overflow-hidden">{hotel.hotel_description?.slice(0, 100)}</div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <button className="check-availibility-button mb-2 px-4 texxt14 py-1" variant="primary" onClick={() => handleViewHotel(hotel)}>View</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : <div>No Hotels Added</div>
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

export default MyHotels;
