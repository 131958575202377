import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faEnvelopeOpen,
    faUser,
    faTrash,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import { Button, Form, Row } from "react-bootstrap";
import loader from "../images/loading.png";
import countries from "../CountryStateCity.json";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar";

const ItineraryHotel = () => {
    const { _id } = useParams();
    const navigate = useNavigate();

    const states = countries.find((e) => e?.name === "India");
    const [cities, setCities] = useState([]);

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [formData, setFormData] = useState({
        hotel_name: "",
        hotel_address: "",
        hotel_city: "",
        hotel_state: "",
        hotel_type: "",
        hotel_description: "",
        other: "",
        hotel_photos: [],
    });

    const [images, setImages] = useState([]);
    console.log(images)
    const fileInputRef = useRef(null);

    const handleAddImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        const fileObjects = files.map(file => ({ file, url: URL.createObjectURL(file) }));
        setImages(prevImages => [...prevImages, ...fileObjects]);
    };

    const handleRemoveImage = (indexToRemove) => {
        setImages(prevImages => prevImages.filter((_, index) => index !== indexToRemove));
    };

    const [editedata, setEditedData] = useState("");

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "hotel_state") {
            const selectedCity = states?.states?.find((e) => e?.name === value);
            setCities(selectedCity?.cities);
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        setLoading(true);

        const token = localStorage.getItem("vendorToken");
        const submitData = new FormData();

        submitData.append("hotel_name", formData.hotel_name);
        submitData.append("hotel_address", formData.hotel_address);
        submitData.append("hotel_city", formData.hotel_city);
        submitData.append("hotel_state", formData.hotel_state);
        submitData.append("hotel_type", formData.hotel_type);
        submitData.append("hotel_description", formData.hotel_description);
        submitData.append("other", formData.other);

        // Append each image file individually
        if (_id) {
            // If _id exists, handle the update logic
            images.forEach(({ file, url }, index) => {
                if (file) {
                    submitData.append("hotel_photo", file); // Send file if available
                } else if (url) {
                    submitData.append("hotel_photo", url); // Send URL if file is not available
                }
            });
        } else {
            // If _id does not exist, handle the create logic
            images.forEach(({ file }, index) => {
                submitData.append("hotel_photo", file); // Send file key only
            });
        }

        if (_id) {
            try {
                const res = await fetch(`${BASE_URL}api/hotel_itienrary/update/${_id}`, {
                    method: "PUT",
                    headers: {
                        Authorization: token,
                    },
                    body: submitData,
                });

                const result = await res.json();

                setLoading(false);
                if (result?.success) {
                    navigate("/vendor/hotels");
                    toast.success("Hotel Itinerary Added Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                const res = await fetch(`${BASE_URL}api/hotel_itienrary/create`, {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: submitData,
                });

                const result = await res.json();

                setLoading(false);
                if (result?.success) {
                    navigate("/vendor/hotels");
                    toast.success("Hotel Itinerary Added Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

    };

    const Call = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setEditedData(data?.data?.[0]);
    };

    const fetchHotelData = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayById/${_id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();

        const selectedCity = states?.states?.find((e) => e?.name === data?.data?.hotel_state);
        setCities(selectedCity?.cities);

        if (data?.success) {
            const dt = data?.data;
            setImages(dt?.hotel_photo.map(url => ({ url })));
            setFormData({
                hotel_name: dt.hotel_name,
                hotel_address: dt.hotel_address,
                hotel_city: dt.hotel_city,
                hotel_state: dt.hotel_state,
                hotel_type: dt.hotel_type,
                hotel_description: dt.hotel_description,
                other: dt.other,
                hotel_photos: dt.hotel_photo,
            });
        }
    };

    useEffect(() => {
        if (_id) {
            fetchHotelData();
        }
    }, [_id]);

    useEffect(() => {
        Call();
    }, []);

    const handleNav = () => {
        navigate("/vendor/myprofile");
    };

    const handleDelete = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/delete/${_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });

        if (res.ok) {
            navigate("/vendor/hotels");
            toast.success("Hotel Itinerary Deleted Successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            });
        } else {
            console.error("Failed to delete the item");
        }
    };

    return (
        <>
            <Header2 />
            <div className="costum_container bg-white">
                <My_pannel />
                <div style={{ backgroundColor: "white", height: "100vh" }}>
                <ProfileSection title="Itinerary Hotels" />
                    <Row className="bg-white justify-content-center">
                        <div className="col-11">
                            <div className="inner_green_border py-3 mt-5 mb-4 px-4">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel name
                                        </p>
                                        <input
                                            type="text"
                                            name="hotel_name"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel address
                                        </p>
                                        <input
                                            type="text"
                                            name="hotel_address"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_address}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel Type
                                        </p>
                                        <select
                                            name="hotel_type"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">select</option>
                                            <option value="5 star">5 star</option>
                                            <option value="4 star">4 star</option>
                                            <option value="3 star">3 star</option>
                                            <option value="2 star">2 star</option>
                                            <option value="1 star">1 star</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel state
                                        </p>
                                        <select
                                            name="hotel_state"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_state}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">select</option>
                                            {states?.states && states?.states.map((e) => (
                                                <option value={e.name}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel city
                                        </p>
                                        <select
                                            name="hotel_city"
                                            id=""
                                            className="mb-2"
                                            style={{ width: "100%" }}
                                            value={formData.hotel_city}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">select</option>
                                            {cities && cities?.map((e) => (
                                                <option value={e.name}>{e.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Hotel description
                                        </p>
                                        <textarea
                                            name="hotel_description"
                                            id=""
                                            style={{
                                                width: "100%",
                                                height: "150px",
                                                padding: "10px",
                                            }}
                                            value={formData.hotel_description}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                            Other
                                        </p>
                                        <textarea
                                            name="other"
                                            id=""
                                            style={{
                                                width: "100%",
                                                height: "150px",
                                                padding: "10px",
                                            }}
                                            value={formData.other}
                                            onChange={handleInputChange}
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-12 margin_left_right">
                                        <section className="">
                                            <div className="hotel-pictures-container">
                                                <div className="hotel-pictures-title-container">
                                                    <p className="mb-1 vendoraddpackage-label text14 mt-2">
                                                        Hotel Pictures
                                                    </p>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="plus-icon"
                                                        onClick={handleAddImageClick}
                                                    />
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleImageChange}
                                                        multiple
                                                    />
                                                </div>
                                                <div className="hotel-pictures-section">
                                                    {images.map(({ url }, index) => (
                                                        <div key={index} className="hotel-pictures-item">
                                                            <img src={url} alt={`Hotel pic ${index + 1}`} className="hotel-image" />
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="trash-icon"
                                                                onClick={() => handleRemoveImage(index)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-11 d-flex justify-content-end">
                            {_id && (
                                <Button
                                    className="back_next_btn"
                                    style={{
                                        background: "red",
                                        color: "white",
                                        padding: "8px 40px",
                                        border: "1px solid #BBBB",
                                    }}
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            )}
                            {loading ? (
                                <Button
                                    variant="primary"
                                    className="ms-2 back_next_btn"
                                    type="submit"
                                    style={{
                                        backgroundColor: "#155E75",
                                    }}
                                >
                                    <img
                                        src={loader}
                                        alt=""
                                        className="spinner"
                                        style={{ height: "25px" }}
                                    />
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    className="ms-2 back_next_btn"
                                    style={{
                                        backgroundColor: "#155E75",
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default ItineraryHotel;
