import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelopeOpen,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import { Rating } from "@mui/material";

import './css/Review.css'

const Reviews = () => {
  const navigate = useNavigate();

  const [editedata, setEditedData] = useState("");
  const [reviews, setReviews] = useState([]);
  const [averagestar, setAverageStar] = useState("");
  const [totalreview, setTotalReviews] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setEditedData(data?.data?.[0]);
  };

  useEffect(() => {
    Call();
  }, []);

  const AgencyReviews = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}review/allreview`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setReviews(data.data);
    setAverageStar(data.metadata.average_star_rating);
    setTotalReviews(data.metadata.total_review);
  };

  useEffect(() => {
    AgencyReviews();
  }, []);

  const handleNav = () => {
    navigate("/vendor/myprofile");
  };

  return (
    <>
      <Header2 />
      <div className="costum_container">
        <My_pannel />
        <div style={{ backgroundColor: "whitesmoke", minHeight: "100vh" }}>
          <section style={{ backgroundColor: "#fff" }}>
            <div className="costum_container">
              <div>
                <div className="pt-2 align-items-center second_navbar">
                  <div className='px-lg-3 px-md-4 px-sm-2 pe-2 align-items-center d-flex justify-content-between'>
                    <div className='d-flex align-items-center'>
                      <span className=' ps-2'>Agency Reviews</span>
                    </div>
                     <div className="d-flex align-items-center">
                      <a href="" className="d-flex">
                        <FontAwesomeIcon
                          icon={faBell}
                          className="p-2 cmnclr cmnicon"
                        />
                      </a>
                      <a href="" className="d-flex">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className="p-2 cmnclr cmnicon ms-2"
                        />
                      </a>
                      <div
                        className="d-flex align-items-center cmn py-1 px-2 ms-2"
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          className="cmnclr cmnicon p-2"
                        />
                        <p className="cmnp ps-1" onClick={handleNav}>{editedata?.full_name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="booked_packega_margin p-4">
            <div className="review_summary d-flex justify-content-between align-items-center mb-3">
              <p className="mb-0 font600">
                Total Reviews: {totalreview || 0}
              </p>
              <div className="d-flex align-items-center">
                <span className="font600">Average Star Rating : </span>
                <Rating
                  name="average-rating"
                  value={averagestar || 0}
                  readOnly
                />
              </div>
            </div>
            <div className="reviews_list">
              {reviews.map((review, index) => (
                <div key={index} className="review-card p-3 mb-3 bg-white">
                  <div>
                    <h5 className="font600 text16">{review?.book_package?.[0]?.review?.customer?.[0]?.name}</h5>
                    <p className="text14 mb-1"><span className="font600">Book Package ID :</span> {review?.book_package?.[0]?._id}</p>
                    <p className="text14 font600 mb-0">Comment :</p>
                    <p className="text14 font500 mb-1">{review?.book_package?.[0]?.review?.comment_box}</p>
                    <div className="d-flex align-items-center">
                      <span className="text14 font600">Rating :</span>
                      <Rating
                        name={`review-rating-${index}`}
                        value={parseInt(review?.book_package?.[0]?.review?.star) || 0}
                        readOnly
                      />
                    </div>
                  </div>
                  <div>
                    <button className="view-review-button" onClick={() => navigate(`/vendor/Book-packega/details/${review?.book_package?.[0]?._id}`)}>View</button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Reviews;
