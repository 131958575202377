import { Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import "../Project/css/index1.css"
import {
  faCheck,
  faCheckCircle,
  faStar,
  faXmark,
  faXmarkCircle,
  faHandshakeAlt
} from "@fortawesome/free-solid-svg-icons"
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import DOMPurify from "dompurify"
import { Rating } from "@mui/material";

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


function Book_packega1({ details }) {

  console.log(details)

  const { id } = useParams();
  const [reviews, setReviews] = useState("");

  const Reviews = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}review/allreview`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();

    const bookPackage = data?.data?.[0]?.book_package?.find(bp => bp?._id === id);
    setReviews(bookPackage?.review)
  };

  useEffect(() => {
    Reviews();
  }, []);

  return (
    <>
      <section className=''>
        {details.map((ele, index) => {
          {/* const categoryDetails = ele.category.split(",") */ }

          return (
            <>
              <div className='costum_container' key={index}>
                <div
                  className='green_border book_packega_1 p-3'
                  style={{ backgroundColor: "#ffffff" }}
                >
                  <div className='pt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                    <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                      <span>User Details</span>
                    </h6>
                  </div>

                  <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div>
                        <Row>
                          <div className='col-lg-6 col-md-6 '>
                            <div className='ps-sm-4 py-2'>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 text14 font400'>Name</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder text-capitalize text15 font600'>
                                  {ele.user_name}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 font400'>Mobile no.</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder text15 font600'>
                                  {ele.contact_number}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 font400'>Email ID</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder text15 font600'>
                                  {ele.email_id}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 '>
                            <div className='ps-0 ps-md-4 py-2'>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 font400'>Gender</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder  text15 font600'>
                                  {ele?.customer?.map((e) => {
                                    return (
                                      <>
                                        {e?.customer_detail.map((e1) => {
                                          return <>{e1.gender}</>
                                        })}
                                      </>
                                    )
                                  })}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 font400'>Location</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder text-capitalize  text15 font600'>
                                  {ele.city}, {ele.state}
                                </p>
                              </div>
                              <div className='py-1'>
                                <h6 className='cmnp cmnclr1 font400'>Booking Date</h6>
                                <p className='cmnp cmnclr me-0 font-weight-bolder text-capitalize text15 font600'>
                                  {formatDate(ele.bookdate)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </div>
                      {reviews && <div className='col-lg-6 col-md-6 cmnp cmnclr me-0 font-weight-bolder' style={{ paddingLeft: "20px", border: "1px solid black", minHeight: "100px", marginTop: "10px", paddingTop: "10px" }}>
                        <p className='cmnp cmnclr me-0 font-weight-bolder font400' style={{ display: "flex" }}>
                          <div>
                            Rating:
                          </div>
                          <div>
                            <Rating
                              name="average-rating"
                              value={reviews.star || 0}
                              readOnly
                            />
                          </div>
                        </p>
                        <div>{reviews.comment_box}</div>
                      </div>}
                    </div>
                  </div>

                  <div className='pt-2 mt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                    <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                      <span>Travellers Details</span>
                    </h6>
                  </div>

                  <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                    <div className='cmn p-2 px-3'>
                      <div>
                        <table className="table table-bordered mt-2">
                          <thead className="thead-dark">
                            <tr>
                              <th scope="col" className="text14 font600">Full Name</th>
                              <th scope="col" className="text14 font600">DOB(Age)</th>
                              <th scope="col" className="text14 font600">Gender</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ele?.travel_details && ele?.travel_details?.map((e) => (
                              <tr>
                                <td className="text13">{e?.first_name} {e?.last_name}</td>
                                <td className="text13">{formatDate(e?.dob)} <b>({calculateAge(e?.dob)} Years)</b> </td>
                                <td className="text13">{e?.gender}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  <div className='pt-2 mt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                    <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                      <span>Package Details</span>
                    </h6>
                  </div>

                  <div>
                    <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                      <div className='cmn p-2 px-3'>
                        <div>
                          <Row>
                            <div className='col-lg-6 col-md-6 '>
                              <div className='ps-sm-4 ps-1 py-2'>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>
                                    Package Name
                                  </h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele?.title}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>
                                    Destination
                                  </h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.destination}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>
                                    Trip Duration
                                  </h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {formatDate(ele?.approx_start_date)} to {formatDate(ele?.approx_end_date)}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>
                                    Total Travellers
                                  </h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.total_person}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Total Childs</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.total_child}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Total Days & Nights</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.totaldays}D & {ele.totalnight}N
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Hotel Type</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.hoteltype !== "" ? "N/A" : ele.hoteltype}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Sightseeing</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.siteseeing}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Meal</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {" "}
                                    {ele?.meal?.[0] !== "" ? ele?.meal?.map((e) => e)?.join(", ") : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='col-lg-6 col-md-6 '>
                              <div className='ps-sm-4 ps-1 py-2'>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Departure</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.departure}
                                    {/* {ele.destination || ele.package_details[0].destination[0].destination_name} */}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>
                                    Package category
                                  </h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele?.category.join(", ")}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Total Amount</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele?.total_amount}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Total Adults</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.total_adult}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Total Infants</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.total_infant}
                                  </p>
                                </div>
                                {/* <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Personal Care</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.personal_care}
                                  </p>
                                </div> */}
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Room Sharing</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.room_sharing === "" ? "N/A" : ele.room_sharing}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Travel By</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {ele.transport_mode}
                                  </p>
                                </div>
                                <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Meal Type</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    {" "}
                                    {ele.meal_type?.[0] !== "" ? ele?.meal_type?.map((e) => e)?.join(", ") : "N/A"}
                                  </p>
                                </div>
                                {/* <div className='py-1'>
                                  <h6 className='cmnp cmnclr1 font400'>Review</h6>
                                  <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                    Statick
                                  </p>
                                </div> */}
                                {ele.othere_requirement !== "" && (
                                  <div className='py-1'>
                                    <h6 className='cmnp cmnclr1 font400'>Other Requirment</h6>
                                    <p className='cmnp cmnclr me-0 font-weight-bolder font600 text15'>
                                      {ele.othere_requirement}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='pt-2 mt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                    <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                      <span>Services</span>
                    </h6>
                  </div>

                  <div className=''>
                    <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                      <div className='cmn py-4 px-4 '>
                        <Row>
                          <div className='col-lg-6 col-md-6 col-dm-6'>
                            <h6 className="font600 text16">
                              <FontAwesomeIcon icon={faCircleCheck} /> Included
                            </h6>
                            <div className='cmn p-3'>
                              <p className='cmnp py-1 ruppe_text'>
                                {ele.booked_include.map((e) => (
                                  <div className="d-flex gap-2">
                                    <FontAwesomeIcon icon={faCheck} className="mb-0 pt-1" />
                                    <span
                                      className="mb-0"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e.include_services_value,
                                        ),
                                      }}
                                    />
                                  </div>
                                ))}
                              </p>
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-6 col-dm-6'>
                            <h6 className="font600 text16">
                              <FontAwesomeIcon icon={faCircleXmark} /> Excluded
                            </h6>
                            <div className='cmn p-3'>
                              <p className='cmnp py-1 ruppe_text1'>
                                {ele.booked_exclude.map((e) => (
                                  <div className="d-flex gap-2">
                                    <FontAwesomeIcon icon={faXmark} className="mb-0 pt-1" />
                                    <span
                                      className="mb-0"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          e.exclude_services_value,
                                        ),
                                      }}
                                    />
                                  </div>
                                ))}
                              </p>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className='pt-2 mt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                    <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                      <span>Itinerary</span>
                    </h6>
                  </div>

                  <section className='text-start'>
                    <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                      <div className='cmn p-4'>
                        {ele?.booked_itinerary.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2 text18 font700">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-9'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text14 font600'>Title</h6>
                                        <p className='cmnp cmnclr me-0 font-weight-bolder text14'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text14 font600'>Activity</h6>
                                        <p className='cmnp cmnclr me-0 font-weight-bolder text14'>
                                          <div
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e3.activity,
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-3'>
                                      <div className='text-end py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                      </div>
                                    </div>}
                                  </Row>


                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  {ele?.hotel_itienrary?.length > 0 && (
                    <div className='pt-2 mt-2 px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1'>
                      <h6 className='cmnclr me-0 font-weight-bold cmnhr font700 text18 d-flex align-items-center'>
                        <span>Hotels</span>
                      </h6>
                    </div>
                  )}


                  {ele?.hotel_itienrary?.length > 0 && (
                    <section className='text-start'>
                      <div className='px-xl-5 px-lg-4 px-md-3 px-sm-2 px-1 py-2'>
                        <div className='cmn p-4'>

                          {/* <!--------------------------- part-1 ---------------------------------------------> */}

                          {ele?.hotel_itienrary && ele?.hotel_itienrary?.map((e, index) => (
                            <div className='row gx-5 p-2 second-changes'>
                              <div className='col-xl-4 col-12'>
                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                  <div className="carousel-indicators">
                                    {e?.hotel_photo?.map((_, idx) => (
                                      <button
                                        key={idx}
                                        type="button"
                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                        data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current={idx === 0 ? "true" : "false"}
                                        aria-label={`Slide ${idx + 1}`}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {e?.hotel_photo?.map((ele, idx) => (
                                      <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                        <img src={ele} className="d-block w-100" alt="..." />
                                      </div>
                                    ))}
                                  </div>
                                  <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              </div>
                              <div className='col-xl-8 col-12 first-change'>
                                <div className='mb-md-2'>
                                  <span className='d-flex day1-2  text-left align-items-center'>
                                    <div className='day-1-2 py-1 font-changes'>
                                      <h6 className="text18 font700">Day {e?.days
                                        ?.split(',')
                                        .map(Number) // Convert each item to a number for proper sorting
                                        .sort((a, b) => a - b)
                                        .join(',')}</h6>
                                    </div>
                                    <div className=' font-changes'>
                                      <h6 className='mb-1 pe-5 text18 font700'>
                                        {e?.hotel_name}
                                      </h6>
                                      <p className='mb-0 text-start text14'>
                                        {e?.hotel_address}, {e?.hotel_city},{e?.hotel_state}
                                      </p>
                                    </div>
                                  </span>
                                </div>
                                <div className='hotels-star'>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                </div>
                                <div className='text-start hotels-footer'>
                                  <p>
                                    {e?.hotel_description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </section>
                  )}


                </div>
              </div>
            </>
          )
        })}
      </section>
    </>
  )
}

export default Book_packega1
