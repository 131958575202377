import React, { useState, useEffect, useContext } from "react"
import Header from "./Header"
import { Button, Container, Row } from "react-bootstrap"
import "./css/index1.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import {
  faArrowAltCircleLeft,
  faArrowRight,
  faCalendar,
  faIdBadge,
  faIndianRupeeSign,
  faLocationPin,
  faPlane,
  faStar,
  faUser,
  faStarHalfAlt
} from "@fortawesome/free-solid-svg-icons"
import { NavLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { bidContext } from "../App"
import { ids } from "../App"
import { hotelName } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Rating } from "@mui/material";


function C_packegga(props) {
  const navigate = useNavigate()

  const [packages, setPackages] = useState([])
  const [bid, setBid] = useState([])
  const { bidData, setBidData } = useContext(bidContext)
  const { id, setId } = useContext(ids)
  const { hotel, setHotel } = useContext(hotelName)

  const [selectedShowPackage, setSelectedShowPackage] = useState()
  const [averageStar, setAverageStar] = useState(0);
  const [totalreviews, setTotalReviews] = useState(0);
  const [stars, setStars] = useState([]);


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }

  const handleHotelClick = (HotelName) => {
    setBidData(HotelName)
  }

  const token = localStorage.getItem("userToken")
  const ShowPackage = async () => {
    const res = await fetch(`${BASE_URL}customrequirements`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setPackages(data.data)
  }

  const Show_Packages = async (id, pack) => {
    const res = await fetch(
      `${BASE_URL}bidpackage/displaybidpackages_jaydev?custom_requirement_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
    const data = await res.json()
    setBid(data.data)
    setAverageStar(data.metadata.average_star_rating.toFixed(1));
    setTotalReviews(data.metadata.total_reviews);
    setSelectedShowPackage(pack)

  }

  const [compare, setCompare] = useState([])

  const handleCheckboxChange = (event, idValue, hotelName) => {
    if (event.target.checked) {
      // Add the logic to store the id and hotel if they're not already present
      if (!id.includes(idValue)) {
        setId((prevIds) => [...prevIds, idValue])
        setHotel((prevHotels) => [...prevHotels, hotelName])

        // Create an object with id and hotelName and add it to the compare array
        setCompare((prevCompare) => [
          ...prevCompare,
          { id: idValue, hotel: hotelName },
        ])
      }
    } else {
      // Add the logic to remove the id and hotel from the arrays if they're present
      setId((prevIds) => prevIds.filter((prevId) => prevId !== idValue))
      setHotel((prevHotels) =>
        prevHotels.filter((prevHotel) => prevHotel !== hotelName),
      )

      // Remove the object with matching id from the compare array
      setCompare((prevCompare) =>
        prevCompare.filter((item) => item.id !== idValue),
      )
    }
  }
  const userToken = localStorage.getItem("userToken")

  useEffect(() => {
    if (!userToken) {
      navigate("/")
    } else {
      ShowPackage()
    }
  }, [])

  const fetchPackageDetails = async (tripId) => {
    try {
      const response = await fetch(
        `${BASE_URL}bidpackage/displaybidpackages?custom_requirement_id=${tripId}`,
      )
      const data = await response.json()
      return data
    } catch (error) {
      throw error
    }
  }

  const [bidCount, setBidCount] = useState("")

  useEffect(() => {
    // Fetch details for each package using their _id
    const fetchPackageDetailsForAll = async () => {
      const detailsPromises = packages.map((ele) =>
        fetchPackageDetails(ele.Trip_id),
      )

      try {
        const detailsData = await Promise.all(detailsPromises)
        setBidCount(detailsData)
      } catch (error) { }
    }

    if (packages.length > 0) {
      fetchPackageDetailsForAll()
    }
  }, [packages])

  const handleNavigate = () => {
    if (compare.length < 2) {
      toast.error("Please must be 2 packages!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
      return;
    }

    if (compare.length > 2) {
      toast.error("Please select only 2 package!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      })
      return;
    }
    navigate("/compare_packega", { state: { id } });
  }

  useEffect(() => {
    const fullStars = Math.floor(averageStar);
    const hasHalfStar = averageStar % 1 !== 0;
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

    const starElements = [];
    for (let i = 0; i < fullStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`full-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
        />
      );
    }
    if (hasHalfStar) {
      starElements.push(
        <FontAwesomeIcon
          key='half'
          icon={faStarHalfAlt}
          className='CuStom_package_icon'
        />
      );
    }
    for (let i = 0; i < emptyStars; i++) {
      starElements.push(
        <FontAwesomeIcon
          key={`empty-${i}`}
          icon={faStar}
          className='CuStom_package_icon'
          style={{ color: '#B8B8B8' }}
        />
      );
    }

    setStars(starElements);
  }, [averageStar]);


  return (
    <>
      <Header />
      <ToastContainer />
      <section className='py-5'>
        <div className='container-customes'>
          <div className='text-center'>
            <img src='/C-packega1.png' className='w-100' alt='' />
          </div>
          <div className='mt-3 mx-4'>
            <Carousel
              responsive={responsive}
              className='mx-xl-4 mx-md-2 mx-sm-0'
            >
              {packages && packages.map((ele, index) => {
                return (
                  <>
                    <Row key={ele._id} className='d-flex justify-content-center mx-1'>
                      {bidCount[index] && (
                        <div className='d-flex col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                          <div className='py-4 px-3 cmn d-flex bg-white w-100 card-container'>
                            <img
                              src='/C-packega2.png'
                              className='d-none d-sm-block img-fluid'
                              alt=''
                            />
                            <div className='ps-3 w-100'>
                              <div className=''>
                                <p className='textcm text14 font500'>
                                  <FontAwesomeIcon icon={faPlane} className="color-light-green me-1" /> <span className="font600">Departure :</span> {ele.departure}
                                </p>
                                <p className='textcm text14 font500'>
                                  <FontAwesomeIcon icon={faLocationPin} className="color-light-green me-1" /> <span className="font600">Destination :</span> {ele.Arival}
                                </p>
                                <p className='textcm text14 font500'>
                                  <FontAwesomeIcon icon={faUser} className="color-light-green me-1" /> <span className="font600">Total Bid :</span> {bidCount[index]?.data?.length}
                                </p>
                                <p className='textcm text14 font500'>
                                  <FontAwesomeIcon icon={faCalendar} className="color-light-green me-1" /> <span className="font600">On Date :</span> {ele.On_Date}
                                </p>
                                <p className='textcm text14 font500'>
                                  <FontAwesomeIcon icon={faCalendar} className="color-light-green me-1" /> <span className="font600">Status :</span> {ele?.custom_requirement?.status}
                                </p>
                              </div>
                              <div className='w-100 compare_package_btn text-end'>
                                <button onClick={() => Show_Packages(ele.Trip_id, ele)} className='show1 text13 font600'>
                                  Show Packages
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Row>
                  </>
                )
              })}
            </Carousel>
          </div>
        </div>
      </section>

      {bid.length == 0 ? (
        <div
          style={{
            color: "red",
            fontSize: "22px",
            display: "grid",
            placeItems: "center",
            padding: "30px 0px",
          }}
        >
          No Bid Data
        </div>
      ) : (
        <div>
          {bid.map((ele, index) => {
            return (
              <>
                <section key={index} className='py-2'>
                  <div className='container-customes'>
                    <div>
                      <Row className='cmn px-4 py-4  mx-3'>
                        <div className='col-xl-5 col-lg-7 col-12 ps-0 pe-md-3 pe-0'>
                          <div className='d-flex flex-wrap w-100'>
                            <div className='com_100'>
                              {ele?.agency_logo ? (
                                <img
                                  src={ele?.agency_logo}
                                  className='img-fluid w-100'
                                  alt=''
                                />
                              ) : (
                                <img
                                  src='/C-packega3.png'
                                  className='img-fluid w-100'
                                  alt=''
                                />
                              )}
                            </div>
                            <div className='ps-md-3 ps-0 mt-md-0 mt-3 ps-md-5 d-flex flex-column justify-content-center'>
                              <p className='cmnp py-1 text-15 text-capitalize text18 font700'>
                                {ele.Agency}
                              </p>
                              <div style={{ display: "flex" }}>
                                {averageStar}
                                {stars}
                                <div style={{ marginLeft: "10px" }}>
                                </div>
                              </div>
                              <p className='cmnp py-1 text-15'>{ele.Date}</p>
                              <p className='cmnp py-1 text-15'>
                                <b>Total Review:</b> {totalreviews}
                              </p>
                              <div>
                                <h2 className='ruppe_text text20 font700'>
                                  Price :{" "}
                                  <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                                  {ele.total_amount}
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-4 col-lg-3 col-md-6 col-12'>
                          <div className='justify-content-center topp'>
                            <p className='cmp text-15 m-0'>
                              <b>Total Days & Nights:</b> {ele.TotalDays} & {ele.TotalNights}
                              <br />
                              {/* <b>Hotel</b> :{ele.HotelTypes.join(",")} */}
                              {/* {
                                selectedShowPackage.custom_requirement
                                  .hotel_type
                              } */}
                            </p>
                          </div>
                        </div>

                        <div className='col-xl-3 col-lg-2 col-md-6 col-12 text-center pe-0'>
                          <div className='text-center d-flex flex-column align-items-md-end align-items-start justify-content-between h-100'>
                            <div className=''>
                              Add To Compare{" "}
                              <input
                                type='checkbox'
                                className='ms-3'
                                onChange={(event) => {
                                  handleCheckboxChange(
                                    event,
                                    ele._id,
                                    ele.Agency,
                                  )
                                }}
                              />
                              <p className='cmnp text-15'></p>
                            </div>
                            <div
                              className='text-center'
                              onClick={() => {
                                navigate(`/custome_package/${ele._id}`)
                                handleHotelClick(ele.Agency)
                              }}
                              style={{ cursor: "pointer", marginTop: 3 }}
                            >
                              <button className='check-availibility-button text12 border-0 w-100'>VIEW</button>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                </section>
              </>
            )
          })}

          <div className='text-center py-5' >
            <button className='check-availibility-button text16 px-5  border-0' onClick={handleNavigate}
              style={{ textDecoration: "none", cursor: "pointer" }}>Compare Quotes</button>
          </div>
        </div>
      )}
      <hr />
      <Footer />
    </>
  )
}

export default C_packegga
