import React, { useEffect, useState } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndianRupeeSign,
  faStar,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import "./css/index1.css";
import { BASE_URL } from "../BASE_URL";
import { useNavigate } from "react-router-dom";

function CarList() {
  const navigate = useNavigate();

  const [carList, setCarList] = useState([]);
  const [filteredCarList, setFilteredCarList] = useState([]);
  const [filters, setFilters] = useState({
    car_name: "",
    car_type: "",
    car_type2: "",
    state: "",
    city: "",
    insurance: "",
    min_price_per_km: "",
    max_price_per_km: "",
    min_price_per_day: "",
    max_price_per_day: ""
  });

  const getCarList = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}vendor_car_syt/getAll`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setCarList(data.data);
    setFilteredCarList(data.data);
  };

  useEffect(() => {
    getCarList();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    const filtered = carList.filter((car) => {
      const pricePerKm = car.price_per_km || 0;
      const pricePerDay = car.price_per_day || 0;

      return (
        (filters.car_name === "" ||
          car.car_details[0]?.car_name
            ?.toLowerCase()
            .includes(filters.car_name.toLowerCase())) &&
        (filters.car_type === "" ||
          car.car_details[0]?.fuel_type
            ?.toLowerCase()
            .includes(filters.car_type.toLowerCase())) &&
        (filters.car_type2 === "" ||
          car.car_details[0]?.car_type
            ?.toLowerCase()
            .includes(filters.car_type2.toLowerCase())) &&
        (filters.state === "" ||
          car.state?.toLowerCase().includes(filters.state.toLowerCase())) &&
        (filters.city === "" ||
          car.city?.toLowerCase().includes(filters.city.toLowerCase())) &&
        (filters.insurance === "" ||
          (filters.insurance === "yes" && car.insurance) ||
          (filters.insurance === "no" && !car.insurance)) &&
        (filters.min_price_per_km === "" ||
          pricePerKm >= parseFloat(filters.min_price_per_km)) &&
        (filters.max_price_per_km === "" ||
          pricePerKm <= parseFloat(filters.max_price_per_km)) &&
        (filters.min_price_per_day === "" ||
          pricePerDay >= parseFloat(filters.min_price_per_day)) &&
        (filters.max_price_per_day === "" ||
          pricePerDay <= parseFloat(filters.max_price_per_day))
      );
    });
    setFilteredCarList(filtered);
  }, [filters, carList]);

  return (
    <>
      <Header />
      <section>
        <div className="container-customes">
          <div className="pt-4 pb-2 text-center">
            <h2 className="mb-0">Top rated cars</h2>
            <p className="top_text">These are the top-rated cars available for booking</p>
          </div>

          <div className="row">
            <div
              className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6 d-xl-block d-lg-none d-md-none d-sm-none d-none text-start"
              style={{
                position: "sticky",
                top: "0px",
              }}
            >
              <div className="sort-list filter px-3 py-3">
                <div>
                  <ul className="list-none ps-0">
                    <li className="d-flex justify-content-between">
                      <h6 className="filter-headers">Filters</h6>
                      <div className="reset-filter-button">
                        <button onClick={() => setFilters({
                          car_name: "",
                          car_type: "",
                          car_type2: "",
                          state: "",
                          city: "",
                          insurance: "",
                          min_price_per_km: "",
                          max_price_per_km: "",
                          min_price_per_day: "",
                          max_price_per_day: ""
                        })}>RESET</button>
                      </div>
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Enter Car Name
                      </label>
                      <input
                        type="text"
                        name="car_name"
                        value={filters.car_name}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li>
                      <label htmlFor="" className="filter-headers mb-1">
                        Fuel Type
                      </label>
                      <select
                        name="car_type"
                        value={filters.car_type}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        <option value="">All</option>
                        <option value="petrol">Petrol</option>
                        <option value="diesel">Diesel</option>
                        <option value="electric">Electric</option>
                      </select>
                    </li>
                    <li>
                      <label htmlFor="" className="filter-headers mb-1">
                        Car Type
                      </label>
                      <select
                        name="car_type"
                        value={filters.car_type2}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        <option value="">All</option>
                        <option value="petrol">SUV</option>
                        <option value="diesel">SEDAN</option>
                        <option value="electric">Hetchback</option>
                      </select>
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Enter State
                      </label>
                      <input
                        type="text"
                        name="state"
                        value={filters.state}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Enter City
                      </label>
                      <input
                        type="text"
                        name="city"
                        value={filters.city}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li>
                      <label htmlFor="" className="filter-headers mb-1">
                        Insurance
                      </label>
                      <select
                        name="insurance"
                        value={filters.insurance}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      >
                        <option value="">All</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Min Price per Km
                      </label>
                      <input
                        type="number"
                        name="min_price_per_km"
                        value={filters.min_price_per_km}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Max Price per Km
                      </label>
                      <input
                        type="number"
                        name="max_price_per_km"
                        value={filters.max_price_per_km}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Min Price per Day
                      </label>
                      <input
                        type="number"
                        name="min_price_per_day"
                        value={filters.min_price_per_day}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                    <li className="mb-3">
                      <label htmlFor="" className="filter-headers mb-1">
                        Max Price per Day
                      </label>
                      <input
                        type="number"
                        name="max_price_per_day"
                        value={filters.max_price_per_day}
                        onChange={handleFilterChange}
                        className="px-2 py-1 focus-outline"
                        style={{
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-9">
              {filteredCarList &&
                filteredCarList.map((car) => {
                  const carDetails = car.car_details[0] || {};
                  return (
                    <div className="top-border p-4 mb-2" key={car._id} style={{ display: "flex", flexDirection: "column" }}>
                      <div className="row gx-5">
                        <div className="col-lg-4 col-md-5 col-sm-6 text-center d-flex align-items-center">
                          <img
                            src={car.photos[0]}
                            className="img-fluid w-100"
                            alt="car"
                            style={{height:"250px"}}
                          />
                        </div>
                        <div className="col-lg-8 col-md-7 col-sm-6 d-flex flex-column justify-content-center pe-md-4 pe-2">
                          <div className="py-2">
                            <div className="align-items-center top_hotels_name">
                              <h3>{carDetails.car_name}</h3>
                            </div>
                            <div className="top_hotels_star pt-2">
                              <p>Type: {carDetails.car_type}</p>
                            </div>
                          </div>
                          <div className="d-flex top_hotels_map">
                            <p className="top-a">
                              <FontAwesomeIcon icon={faLocationPin} />{" "}
                              {car.city}, {car.state} <span style={{ color: "#B8B8B8" }}>|</span> {car.pincode}
                            </p>
                          </div>
                          <div className="pt-3 ">
                            <p>Insurance: {car.insurance ? "Yes" : "No"}</p>
                            <p>Price per Km: {car.price_per_km}</p>
                            <p>Price per Day: {car.price_per_day}</p>
                          </div>
                        </div>
                      </div>
                      <div style={{ alignSelf: "end" }}>
                        <button style={{ padding: "10px 20px", backgroundColor: "#09646D", border: "none", borderRadius: "10px", color: "white" }} onClick={() => navigate(`/car-details/${car._id}`)}>View More</button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CarList;
