import React from "react"
import Header from "../Project/Header"
import { useState } from "react"
import index1 from "../Project/css/index1.css"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useLocation, useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import loader from "../images/loading.png"

function NewPassword() {
  const navigate = useNavigate()
  const location = useLocation();
  const [loading, setLoading] = useState(false)

  const [newPasswordVisible, setNewPasswordVisible] = useState(false)

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const [formData, setFormData] = useState({
    mobile_number: "",
    newpassword: "",
    confirmpassword: "",
  })

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleSubmit = async (e) => {
    const token = localStorage.getItem("vendorToken")
    e.preventDefault()
    if (!formData.newpassword) {
      toast.error("Enter Password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (formData.newpassword !== formData.confirmpassword) {
      toast.error("Passwords do not match!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    setLoading(true)

    const { mobile_number, newpassword } = formData

    const res = await fetch(`${BASE_URL}agency/forgatepassword`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        newpassword,
        mobile_number: Number(location?.state),
      }),
    })
    const data = await res.json()
    if (data.code === 200) {
      setLoading(false)
      toast.success("Password Change Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })

      setTimeout(() => {
        navigate("/vendor/login")
      }, 2000)
    } else {

      setLoading(false)
    }
  }

  const handleKeyDown = (e) => {
    // Allow: backspace, delete, tab, escape, enter
    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A/Ctrl+C/Ctrl+V
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // Let it happen, don't do anything
      return
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Header />
      <div className='container'>
        <div className='forgot_not'>
          <div className='forgot_pass'>
            <div className='forgot_box'>
              <div className='text-center mt-3'>
                <h2 className="text28 font700">Set New Password</h2>
              </div>
              <div className='mobile_number_box mt-3'>
                <div className='mobile_number_forgot text-start'>
                  <p className='mb-1 text16'>Enter Your Mobile Number</p>
                  <input
                    type='tel'
                    name='mobile_number'
                    id='pasword'
                    value={location?.state}
                    disabled
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 10)
                      handleChange(e)
                    }}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className='mobile_number_box mt-2'>
                <div className='mobile_number_forgot text-start'>
                  <p className='mb-1 text16'>Enter New Password</p>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      type={newPasswordVisible ? "text" : "password"}
                      name='newpassword'
                      id='pasword'
                      onChange={handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                          "",
                        )
                      }}
                    />

                    <img
                      src={newPasswordVisible ? "/eye.png" : "/eye2.png"}
                      className='eye-image'
                      alt=''
                      style={{
                        height: "10px",
                        width: "15px",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "5%",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={toggleNewPasswordVisibility}
                    />
                  </div>
                  <ToastContainer />
                </div>
              </div>
              <div className='mobile_number_box mt-2'>
                <div className='mobile_number_forgot text-start'>
                  <p className='mb-1 text16'>Enter Confirm Password</p>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <input
                      type={confirmPasswordVisible ? "text" : "password"}
                      name='confirmpassword'
                      id='confirmpassword'
                      onChange={handleChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                          "",
                        )
                      }}
                    />

                    <img
                      src={confirmPasswordVisible ? "/eye.png" : "/eye2.png"}
                      className='eye-image'
                      alt=''
                      style={{
                        height: "10px",
                        width: "15px",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "5%",
                        margin: "auto",
                        cursor: "pointer",
                      }}
                      onClick={toggleConfirmPasswordVisibility}
                    />
                  </div>
                  <ToastContainer />
                </div>
              </div>
              {loading ? (
                <div className='mobile_btn'>
                  <button
                    type='submit'
                    className="login-modal-btn"
                  >
                    <img src={loader} alt="" className="spinner" />
                  </button>
                </div>
              ) : (
                <div className='mobile_btn'>
                  <button onClick={handleSubmit}>Next</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewPassword
