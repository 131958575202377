import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const SpecificDetail = () => {
    const location = useLocation();
    const { site, link } = location.state;
    const [details, setDetails] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDetails = async () => {
            try {

                const response = await axios.post(
                    'https://specificdetails.onrender.com',
                    new URLSearchParams({
                        site,
                        link
                    })
                );


                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Network response was not ok: ${errorText}`);
                }

                const result = await response.json();
                setDetails(result);
            } catch (error) {
                setError(error.message);
                console.error('Error:', error);
            }
        };

        fetchDetails();
    }, [site, link]);

    return (
        <div>
            {error ? (
                <div>
                    <h1>Error</h1>
                    <p>{error}</p>
                </div>
            ) : details ? (
                <div>
                    <h1>Details for {site}</h1>
                    <p>{JSON.stringify(details)}</p>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default SpecificDetail;
