import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { useNavigate } from "react-router-dom"
import {
  faArrowLeft,
  faBell,
  faBrush,
  faCheck,
  faCheckCircle,
  faClipboard,
  faEnvelopeOpen,
  faLayerGroup,
  faLocationPin,
  faStar,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBarsProgress,
  faCube,
  faGear,
  faHome,
  faHotel,
  faKey,
  faMapLocationDot,
  faVectorSquare,
} from "@fortawesome/free-solid-svg-icons"
import {
  faGift,
  faMobileScreenButton,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons"
import { faUser } from "@fortawesome/free-regular-svg-icons"
import "../Project/css/index1.css"
import { useState } from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import { FormCheck, FormControl, Row } from "react-bootstrap"
import { Female, Male } from "@mui/icons-material"
import Form from "react-bootstrap"
import { Navigate } from "react-router-dom"
import { NavLink } from "react-router-dom"

function Header2() {
  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleYesClick = () => {
    localStorage.removeItem("vendorToken")

    handleClose()

    navigate("/vendor/login")
  }

  const vendtoken = localStorage.getItem("vendorToken")

  return (
    <>
      <div className='' style={{ borderBottom: "1px solid #000" }} bg='light'>
        <Navbar expand='lg' className='px-lg-0 px-md-4 px-sm-2 px-2 nav_width'>
          <NavLink
            to='/vendor/home-page'
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Navbar.Brand className='logo'>
              <img
                src='/syt_logo.png'
                alt=''
                className='me-2'
                style={{ height: "40px" }}
              />
              START YOUR TOUR
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle aria-controls='navbarScroll' />

          <Navbar.Collapse id='navbarScroll'>
            <Nav
              className='m-right my-2 my-lg-0'
              style={{ marginLeft: "auto" }}
              navbarScroll
            >
              <Nav.Link
                href=''
                onClick={() => navigate("/vendor/home-page")}
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
              >
                <FontAwesomeIcon icon={faHome} className='me-1 ' />
                <span className=''>Home</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>
              <Nav.Link href='tel:+91 96623 99609' className='text-dark'>
                <FontAwesomeIcon icon={faPhoneVolume} className='me-1' />
                <span className=''>+91 96623 99609</span>
                {/* <a
                  href="/Home"
                  className="p-2 d-xl-none d-lg-none d-md-block d-sm-block d-block header_2_anchor"
                >
                  HOME
                </a> */}
              </Nav.Link>
              {vendtoken ? null : (
                <Nav.Link href='' className='text-dark'>
                  <FontAwesomeIcon icon={faUser} className='me-1 ' />
                  <span className=''>Travel Agent? Join Us</span>
                </Nav.Link>
              )}
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon icon={faGift} className='me-1 ' />
                <span className=''>Offers</span>
                {/* <a href='/Location' className=' p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>
              <Nav.Link href='' className='text-dark'>
                <FontAwesomeIcon
                  icon={faMobileScreenButton}
                  className='me-1 '
                />
                <span className=''>Download App</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>

              <Nav.Link
                href=''
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
                onClick={() => navigate("/vendor/my-bid")}
              >
                <FontAwesomeIcon icon={faBarsProgress} className='me-1 ' />
                <span className=''>My Bid</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>
              <Nav.Link
                href=''
                onClick={() => navigate("/display/custom")}
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
              >
                <FontAwesomeIcon icon={faCube} className='me-1 ' />
                <span className=''>Package</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>

              <Nav.Link
                href=''
                onClick={() => navigate("/vendor/Booked-packega")}
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
              >
                <FontAwesomeIcon icon={faMapLocationDot} className='me-1 ' />
                <span className=''>Booked</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>

              <Nav.Link
                href=''
                onClick={() => navigate("/vendor/changepassword")}
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
              >
                <FontAwesomeIcon icon={faKey} className='me-1 ' />
                <span className=''>Change Password</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>

              <Nav.Link
                href=''
                onClick={() => setShow(true)}
                className='d-xl-none d-lg-none d-md-block d-sm-block d-block text-dark'
              >
                <FontAwesomeIcon
                  icon={faMobileScreenButton}
                  className='me-1 '
                />
                <span className=''>Logout</span>
                {/* <a href='/Hotal' className='p-2 header_2_anchor'>
                  HOME
                </a> */}
              </Nav.Link>

              <Modal
                show={show}
                onHide={handleClose}
                backdrop='static'
                keyboard={false}
                centered // Add the centered prop to center the modal
              >
                <Modal.Body className='p-5'>
                  <div>
                    <h4 className='text-center'>
                      Are you Sure Want to Logout?
                    </h4>
                  </div>
                  <div
                    className='d-flex justify-content-center'
                    style={{ gap: "10px", marginTop: "30px" }}
                  >
                    <button
                      style={{
                        width: "80px",
                        padding: "4px 0px",
                        backgroundColor: "lightgray",
                        borderRadius: "10px",
                        boxShadow: "none",
                        border: "none",
                        color: "grey",
                      }}
                      onClick={handleClose}
                    >
                      No
                    </button>
                    <button
                      style={{
                        width: "80px",
                        padding: "4px 0px",
                        backgroundColor: "#09646d",
                        borderRadius: "10px",
                        boxShadow: "none",
                        border: "none",
                        color: "white",
                      }}
                      onClick={handleYesClick}
                    >
                      Yes
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
}

export default Header2
