import React, { useContext, useEffect, useState, useRef } from "react"
import {
  faArrowLeft,
  faBell,
  faEnvelopeOpen,
  faUser,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useParams, NavLink } from "react-router-dom"
import My_pannel from "./My_pannel"
import "../Project/css/index1.css"
import Header2 from "./Header2"
import { Form, Modal, Row } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Select from "react-select"
import { day } from "../App"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
import { Avatar } from "@mui/material"
import loader from "../images/loading.png"
import ProfileSection from "./SecondNavbar"
// import { colourOptions } from "../data";

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  // Format options to get the date in 'dd-mm-yyyy' format
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return date.toLocaleDateString('en-GB', options);
};


function Submit_package_form(props) {

  const [loading, setLoading] = useState(false)

  const [details, setDetails] = useState([])
  const [it, setIt] = useState([])

  const [packageType, setPackageType] = useState("")
  const [title, setTitle] = useState("")


  const [detailsData, setDetailsData] = useState({
    total_adult: "",
    Infant: "",
    total_child: "",
    destination_category: "",
    hotel_types: "",
    meal_types: "",
    meal_required: "",
    other_Services: "",
    price_per_person: "",
    total_days: "",
    personal_care: "",
    travel_by: "",
    total_nights: "",
    total_amount: "",
  })


  const editorRef = useRef(null)
  const [includeList, setIncludeList] = useState([])
  const [excludeList, setExcludeList] = useState([])



  const handleIncludeClick = () => {
    const includeData = editorRef.current.editor.getData()
    if (includeData.length > 0) {
      setIncludeList([...includeList, includeData])
      editorRef.current.editor.setData("")
    } else {
      toast.error("Please Enter Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    }
  }


  const handleExcludeClick = () => {
    const excludeData = editorRef.current.editor.getData()

    if (excludeData.length > 0) {
      setExcludeList([...excludeList, excludeData])
      editorRef.current.editor.setData("")
    } else {
      toast.error("Please Enter Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    }
  }


  const { dayNumber, setDayNumber } = useContext(day)

  const navigate = useNavigate()
  const { id } = useParams()
  const [value, setValue] = useState(0)
  const [sharing, setSharing] = useState("")

  const [budget_per_person, setBudget_per_person] = useState({})

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [daysValue, setDaysValue] = useState("")
  const [nightError, setNightError] = useState("")
  const [nightOptions, setNightsOptions] = useState([0, 1, 2])

  const txtData = (e) => {
    const { name, value } = e.target

    if (name === "total_days") {
      const daysValue = parseInt(value);
      setDetailsData({ ...detailsData, total_days: value })
      setTNights(Number(value) - 1);
      const options = [];
      for (let i = 1; i <= 30; i++) {
        if (i >= daysValue - 1 && i <= daysValue + 1 && i <= 30) {
          options.push(i);
        }
      }
      setNightsOptions(options)
    }
    if (name === "total_nights") {
      const night = e.target.value
      const daysValueInt = parseInt(detailsData.total_days)
      const nightInt = parseInt(night)

      if (
        nightInt !== daysValueInt &&
        nightInt !== daysValueInt - 1 &&
        nightInt !== daysValueInt + 1
      ) {
        setNightError("Enter valid details")
      } else {
        setNightError("")
        setDetailsData({ ...detailsData, total_nights: night })
        setTNights(nightInt);
      }
    }
  }

  const [isSearchable, setIsSearchable] = useState(false)

  const colourOptions = [
    { value: "5 Star", label: "5 Star" },
    { value: "4 Star", label: "4 Star" },
    { value: "3 Star", label: "3 Star" },
    { value: "2 Star", label: "2 Star" },
    { value: "Not Required", label: "Not Required" },
    { value: "Budget Property", label: "Budget Property" },
  ]
  const colourOptions1 = [
    { value: "Veg", label: "Veg" },
    { value: "Non-Veg", label: "Non-Veg" },
  ]
  const colourOptions2 = [
    { value: "Breakfast", label: "Breakfast" },
    { value: "Lunch", label: "Lunch" },
    { value: "Dinner", label: "Dinner" },
  ]
  const colourOptions3 = [
    { value: "Flight", label: "Flight" },
    { value: "Train", label: "Train" },
    { value: "Bus", label: "Bus" },
    { value: "Cab/Car", label: "Cab/Car" },
  ]

  const [selectedHotelTypes, setSelectedHotelTypes] = useState([])
  const [prevselectedHotelTypes, setprevSelectedHotelTypes] = useState([])

  const [selectedMealTypes, setSelectedMealTypes] = useState([])

  const [selectedMeals, setSelectedMeals] = useState([])

  const [selectedTravelBy, setSelectedTravelBy] = useState([])

  const updateHotelTypes = (selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    const previousValues = selectedHotelTypes ? selectedHotelTypes.map((option) => option.value) : [];
    const exclusiveValues = ["No", "Budget Property"];
    const starValues = ["2 Star", "3 Star", "4 Star", "5 Star"];

    if (selectedValues.some((value) => exclusiveValues.includes(value))) {
      // If selected values have both "No" and "Budget Property" and previous values have either
      if (selectedValues.includes("No") && selectedValues.includes("Budget Property")) {
        if (previousValues.includes("No")) {
          setSelectedHotelTypes([{ value: "Budget Property", label: "Budget Property" }]);
        } else if (previousValues.includes("Budget Property")) {
          setSelectedHotelTypes([{ value: "No", label: "No" }]);
        }
        return;
      }

      // If selected values have "No" or "Budget Property" and previous values do not have "No" or "Budget Property"
      if (!previousValues.some((value) => exclusiveValues.includes(value))) {
        const exclusiveOption = selectedOptions.find((option) => exclusiveValues.includes(option.value));
        setSelectedHotelTypes([exclusiveOption]);
        return;
      }

      // If selected values have "No" or "Budget Property" and also any star, and previous values also have "No" or "Budget Property"
      if (selectedValues.some((value) => starValues.includes(value))) {
        const starOption = selectedOptions.find((option) => starValues.includes(option.value));
        setSelectedHotelTypes([starOption]);
        return;
      }
    } else if (!selectedValues.some((value) => exclusiveValues.includes(value))) {
      // If selected values do not have "No" or "Budget Property" and previous values also do not have "No" or "Budget Property"
      setSelectedHotelTypes(selectedOptions);
      return;
    }
  };


  const updateMealTypes = (selectedOptions) => {
    const isAnySelected = selectedOptions.some(
      (option) => option.value === "Any",
    )
    const isVegSelected = selectedOptions.some(
      (option) => option.value === "Veg",
    )
    const isNonVegSelected = selectedOptions.some(
      (option) => option.value === "Non-Veg",
    )

    // Update selected options
    setSelectedMealTypes((prevSelectedMealTypes) => {
      if (isAnySelected && (!isVegSelected || !isNonVegSelected)) {
        // If only "Any" is selected, keep only "Any"
        return [{ value: "Any", label: "Any" }]
      } else {
        // If "Veg" or "Non-Veg" is selected along with "Any", remove "Veg" and "Non-Veg"
        const updatedOptions = isAnySelected
          ? [{ value: "Any", label: "Any" }]
          : selectedOptions.filter((option) => option.value !== "Any")

        return updatedOptions
      }
    })
  }

  const updateMeals = (selectedOptions) => {
    setSelectedMeals(selectedOptions)
  }

  const updateTravelBy = (selectedOptions) => {
    setSelectedTravelBy(selectedOptions)
  }

  // Display customer requirement
  const [displayData, setDisplayData] = useState([])
  const [hotelType, setHotelType] = useState("")

  const Requirement = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}customrequirements/details?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    if (data?.data?.[0]?.hotel_type?.[0] === "Not Required") {
      setHotelType("Not Required")
    } else {
      setHotelType("")
    }
    setDisplayData(data.data)
    setSelectedOption(data?.data?.[0]?.sightseeing)
    setDetailsData({ ...detailsData, personal_care: data.data?.[0]?.personal_care })
    setBudget_per_person(data.data[0].budget_per_person)
  }

  // Get BidId
  const [BidData, setBidData] = useState([])
  const [BidDataId, setBidDataId] = useState()
  const [totalTravellingDays, setTotalTravellingDays] = useState(1)
  const [totalTravellingNights, setTotalTravellingNights] = useState(1)
  const [TNights, setTNights] = useState(null)
  const [BidStatus, setBidStatus] = useState("save");

  useEffect(() => {
    setIncludeList(BidData?.include_services || [])
    setExcludeList(BidData?.exclude_services || [])
  }, [BidData.include_services])

  useEffect(() => {
    setTitle(BidData?.name || "")
    setPackageType(BidData?.package_type || [])
  }, [BidData])

  useEffect(() => {
    setDetailsData({ ...detailsData, total_nights: BidData.total_nights })
    setTNights(BidData.total_nights)
  }, [BidData.total_nights]);

  useEffect(() => {
    if (BidData?.meal_types?.length > 0) {
      let mealTypesArray;

      // Check if the first element is a string and split it if necessary
      if (typeof BidData.meal_types[0] === 'string') {
        // Split the single string into an array of meal types
        mealTypesArray = BidData.meal_types[0].split(', ');
      } else {
        // If it's already an array, use it directly
        mealTypesArray = BidData.meal_types;
      }

      // Map the array to the desired format
      const selectedOptions = mealTypesArray.map((meal) => ({
        value: meal,
        label: meal,
      }));

      // Set the state with the new array of objects
      setSelectedMealTypes(selectedOptions);
    }
  }, [BidData.meal_types]);


  useEffect(() => {
    if (BidData?.meal_required?.length > 0) {
      let mealRequiredArray;

      if (typeof BidData.meal_required[0] === 'string') {
        mealRequiredArray = BidData.meal_required[0].split(', ');
      } else {
        mealRequiredArray = BidData.meal_required;
      }

      const selectedOptions = mealRequiredArray.map((meal) => ({
        value: meal,
        label: meal,
      }));

      setSelectedMeals(selectedOptions);
    }
  }, [BidData.meal_required]);

  useEffect(() => {
    if (BidData?.hotel_types?.length > 0) {

      const hotelTypesArray = BidData.hotel_types[0]
        .split(', ')

      const selectedOptions = hotelTypesArray.map((meal) => ({
        value: meal,
        label: meal,
      }));

      setSelectedHotelTypes(selectedOptions);
      setprevSelectedHotelTypes(selectedOptions);
    }
  }, [BidData?.hotel_types]);

  useEffect(() => {
    if (BidData?.travel_by?.length > 0) {
      let travelByArray;

      if (typeof BidData.travel_by[0] === 'string') {
        travelByArray = BidData.travel_by[0].split(', ');
      } else {
        travelByArray = BidData.travel_by;
      }

      const selectedOptions = travelByArray.map((travel) => ({
        value: travel,
        label: travel,
      }));

      setSelectedTravelBy(selectedOptions);
    }
  }, [BidData.travel_by]);

  const getBidPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bidpackage/agencybid`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    const matchingBidPackage = data.data.find(
      (bidPackage) => bidPackage.custom_requirement_id === id,
    )

    if (matchingBidPackage) {
      setBidData(matchingBidPackage)
      setBidDataId(matchingBidPackage._id)
      setBidStatus(matchingBidPackage.bid_status)
      if (matchingBidPackage.bid_status === "submit") {
        setFourthOpen("yes");
        setThirdOpen("yes");
      }
    } else {
    }
  }

  // Display Itinerary

  const getItinerary = async () => {
    const res = await fetch(`${BASE_URL}itinerary?bid_id=${BidDataId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()

    if (res.status === 200) {
      setIt(data.data)
    } else {
      setIt([])
    }
  }

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [adminMargin, setAdminMargin] = useState('');
  const [adminMarginInPrice, setAdminMarginInPrice] = useState('');

  const fetchAdminMargin = async () => {
    try {
      const token = localStorage.getItem("vendorToken")
      const res = await fetch(`${BASE_URL}api/package_profit_margin/all`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })

      // Check if the response is OK
      if (!res.ok) {
        throw new Error(`Error: ${res.status}`)
      }

      const data = await res.json()
      const date = new Date(details?.[0]?.start_date);
      const monthName = date.toLocaleString('default', { month: 'long' });

      // Ensure both the API data and details array are present
      if (data?.data && details?.[0]?.destination) {
        const selectedStateMargin = data?.data?.find((e) =>
          e?.state_name?.toLowerCase() === details?.[0]?.destination?.toLowerCase()
        )


        const selectedObject = selectedStateMargin?.month_and_margin_user?.find((e) =>
          e?.month_name?.toLowerCase() === monthName?.toLowerCase()
        )

        setAdminMargin(selectedObject)
      }
    } catch (error) {
      console.error("Error fetching admin margin:", error)
    }
  }



  useEffect(() => {
    fetchAdminMargin()
  }, [details])

  useEffect(() => {
    setStartDate(BidData?.start_date?.slice(0, 10));
  }, [BidData.start_date]);

  useEffect(() => {
    setEndDate(BidData?.end_date?.slice(0, 10));
  }, [BidData.end_date]);

  const today = new Date().toISOString().split('T')[0];

  const handleStartDateChange = (event) => {
    const { value } = event.target;
    setStartDate(value);
    if (endDate && value > endDate) {
      setEndDate('');
    }
  };

  const handleEndDateChange = (event) => {
    const { value } = event.target;
    setEndDate(value);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    setSelectedOption(BidData?.sightseeing);
  }, [BidData.sightseeing]);

  const [vendor_budget, setVendorBudget] = useState("")

  const [totalBudget, setTotalBudget] = useState(0)
  const [totalBudgetPerPerson, setTotalBudgetPerPerson] = useState(0)
  const [totalBudgetAdults, setTotalBudgetAdults] = useState(0)
  const [totalBudgetChildren, setTotalBudgetChildren] = useState(0)
  const [totalBudgetInfants, setTotalBudgetInfants] = useState(0)
  const [totalBudgetPerPersonAdults, setTotalBudgetPerPersonAdults] = useState(0)
  const [totalBudgetPerPersonChildren, setTotalBudgetPerPersonChildren] = useState(0)
  const [totalBudgetPerPersonInfants, setTotalBudgetPerPersonInfants] = useState(0)

  const [agencyServices, setAgencyServices] = useState("")

  useEffect(() => {
    if (totalBudget) {
      setAdminMarginInPrice(Number(adminMargin?.margin_percentage) * (totalBudget / 100))
    }
  }, [totalBudget]);

  useEffect(() => {
    if (totalBudget) {
      setAdminMarginInPrice(Number(adminMargin?.margin_percentage) * (totalBudget / 100))
    }
  }, [adminMargin]);

  useEffect(() => {
    if (BidData?.other_services_by_agency) {
      setAgencyServices(BidData?.other_services_by_agency)
    }
  }, [BidData]);

  const budgetChangeAdults = (e) => {
    setTotalBudgetPerPersonAdults(e.target.value)
    setTotalBudgetAdults(Number(displayData?.[0]?.total_adult) * e.target.value)
  }

  const budgetChangeChildren = (e) => {
    setTotalBudgetPerPersonChildren(e.target.value)
    setTotalBudgetChildren(Number(displayData?.[0]?.total_child) * e.target.value)
  }

  const budgetChangeInfants = (e) => {
    setTotalBudgetPerPersonInfants(e.target.value)
    setTotalBudgetInfants(Number(displayData?.[0]?.Infant) * e.target.value)
  }

  useEffect(() => {
    setTotalBudgetPerPerson(Number(totalBudgetPerPersonAdults) + Number(totalBudgetPerPersonChildren) + Number(totalBudgetPerPersonInfants))
    setTotalBudget(Number(totalBudgetAdults) + Number(totalBudgetChildren) + Number(totalBudgetInfants))
  }, [totalBudgetAdults, totalBudgetChildren, totalBudgetInfants]);

  useEffect(() => {
    if (BidData?.bid_status === "save" || BidData?.bid_status === "submit" || BidData?.bid_status === "booked") {
      setTotalTravellingDays(BidData?.total_days)
      setTotalTravellingNights(BidData?.total_nights)
      setSharing(BidData?.room_sharing)
      setTotalBudgetPerPersonAdults(BidData?.price_per_person_adult)
      setTotalBudgetPerPersonChildren(BidData?.price_per_person_child)
      setTotalBudgetPerPersonInfants(BidData?.price_per_person_infant)
      setTotalBudgetAdults(BidData?.price_per_person_adult * BidData?.total_adult)
      setTotalBudgetChildren(BidData?.price_per_person_child * BidData?.total_child)
      setTotalBudgetInfants(Number(BidData?.price_per_person_infant) * Number(BidData?.Infant))
      setTotalBudget(BidData?.total_amount)
      setTotalBudgetPerPerson(BidData?.price_per_person_adult + BidData?.price_per_person_child + BidData?.price_per_person_infant)
    }
  }, [BidData]);

  const getBidDetails = async (e) => {
    e.preventDefault();

    const total_days = totalTravellingDays;
    const total_nights = totalTravellingNights;
    const {
      total_adult,
      Infant,
      total_child,
      destination_category,
      other_Services,
      personal_care,
    } = detailsData;

    if (!title) {
      toast.error("Please Enter Package Name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!packageType) {
      toast.error("Please Select Package Type!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!total_days || !total_nights) {
      toast.error("Please Enter Valid Days And Night!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (total_days !== undefined && total_nights !== undefined) {
      if (total_nights < total_days - 1 && total_nights !== total_days && total_nights > total_days + 1) {
        toast.error("Please enter a valid number of nights based on the days entered!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    } else {
      toast.error("Please enter valid values for days and nights!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!details?.[0]?.hotel_type?.includes("Not Required") && selectedHotelTypes?.length < 1) {
      toast.error("Please Select Hotel Types", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    // if (selectedMealTypes?.length < 1) {
    //   toast.error("Please Select Meal Types", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return;
    // }

    // if (selectedMeals?.length < 1) {
    //   toast.error("Please Select Meal Required", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return;
    // }

    if (!startDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!startDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!endDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (selectedTravelBy?.length < 1) {
      toast.error("Please Select Travel By", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!selectedOption) {
      toast.error("Please Select Sightseeing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!details?.[0]?.hotel_type?.includes("Not Required") && !sharing) {
      toast.error("Please Select Room Sharing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!agencyServices) {
      toast.error("Please Enter Other Services By Agency", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (Number(displayData?.[0]?.total_adult) > 0 && Number(totalBudgetPerPersonAdults) === 0) {
      toast.error("Please Enter Price Per Person For Adults", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }


    setLoading(true);

    const totalAmount = Number(totalBudgetAdults) + Number(totalBudgetChildren) + Number(totalBudgetInfants);
    const token = localStorage.getItem("vendorToken");

    try {
      const res = await fetch(`${BASE_URL}bidpackage`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          custom_requirement_id: id,
          total_adult,
          Infant,
          total_child,
          destination_category,
          hotel_types: selectedHotelTypes.map((option) => option.value).join(", "),
          meal_types: selectedMealTypes.map((option) => option.value).join(", "),
          meal_required: selectedMeals.map((option) => option.value).join(", "),
          other_Services,
          price_per_person_adult: totalBudgetPerPersonAdults,
          price_per_person_child: totalBudgetPerPersonChildren,
          price_per_person_infant: totalBudgetPerPersonInfants,
          total_days: Number(totalTravellingDays),
          total_nights: Number(totalTravellingNights),
          personal_care,
          travel_by: selectedTravelBy.map((option) => option.value).join(", "),
          total_amount: totalAmount,
          end_date: endDate,
          start_date: startDate,
          sightseeing: selectedOption,
          room_sharing: sharing,
          other_services_by_agency: agencyServices,
          name: title,
          package_type: packageType,
          admin_margin_price: Number(adminMarginInPrice),
          admin_margin: adminMargin?.margin_percentage,
        }),
      });

      const data = await res.json();
      if (data.code == 200) {
        setLoading(false);
        toast.success("Bid Saved Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        getBidPackage();
      } else {
        setLoading(false);
        toast.error(data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [thirdOpen, setThirdOpen] = useState("")
  const [FourthOpen, setFourthOpen] = useState("")


  const handleFourth = (e) => {

    e.preventDefault()

    if (includeList.length < 1) {
      toast.error("Please Enter Include Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setValue(2)
      return;
    }
    if (excludeList.length < 1) {
      toast.error("Please Enter Exclude Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setValue(2)
      return;
    }

    if (!isChecked) {
      toast.error("Please Check Our Privacy Policy", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    setLoading(true)
    LastSubmit()
  }

  // Edit Bid
  const LastSubmit = async (e) => {

    if (includeList.length < 1) {
      toast.error("Please Enter Include Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setValue(2)
      return
    }

    if (excludeList.length < 1) {
      toast.error("Please Enter Exclude Services!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setValue(2)
      return
    }

    setLoading(true)

    const token = localStorage.getItem("vendorToken")

    const res = await fetch(`${BASE_URL}bidpackage/${BidDataId}`, {
      method: "PUT",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        include_services: includeList,
        exclude_services: excludeList,
      }),
    })

    const data = await res.json()

    setTimeout(() => {
      setLoading(false)
      navigate(`/display/custom/details/${id}`)
    }, 2000)

    if (data.code == 200) {
      toast.success("Bid Submit Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const [hotalData, setHotalData] = useState({
    hotel_name: "",
    hotel_address: "",
    hotel_photo: "",
    hotel_type: "",
    city: "",
    state: "",
    hotel_description: "",
    other: "",
  })

  const hotelTxt = (e) => {
    const { name, value } = e.target
    setHotalData({ ...hotalData, [name]: value })
  }

  useEffect(() => {
    const fetchData = async () => {
      await Requirement()
      await getBidPackage()
      if (BidDataId) {
        await getItinerary(BidDataId)
      }
    }

    fetchData()
  }, [id, BidDataId])

  useEffect(() => {
    setDetails(displayData)
  }, [BidDataId, displayData])

  const [totalDays, setTotalDays] = useState("")
  const [totalNights, setTotalNights] = useState("")

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  const [totalItinerary, setTotalItinerary] = useState("")

  useEffect(() => {
    if (BidData) {
      const { total_days, total_nights } = BidData;

      // Determine which value is greater
      const greaterValue = Math.max(total_days, total_nights);

      // Set the greater value to the state variable
      setTotalItinerary(greaterValue.toString());
    }
  }, [BidData]);

  const handleSetThree = (e) => {
    e.preventDefault()

    setThirdOpen("yes")
    setValue(2);

  }

  const getSelectedHotelTypesString = () => {
    return selectedHotelTypes ? selectedHotelTypes.map(option => option.label).join(', ') : '';
  };
  const getSelectedMealTypesString = () => {
    return selectedMealTypes ? selectedMealTypes.map(option => option.label).join(', ') : '';
  };
  const getSelectedMealsString = () => {
    return selectedMeals ? selectedMeals.map(option => option.label).join(', ') : '';
  };
  const getSelectedTravelByString = () => {
    return selectedTravelBy ? selectedTravelBy.map(option => option.label).join(', ') : '';
  };


  const [isChecked, setIsChecked] = useState(false)

  const handleUpdateBid = async (e) => {
    e.preventDefault();

    const total_days = totalTravellingDays;
    const total_nights = totalTravellingNights;
    const {
      total_adult,
      Infant,
      total_child,
      destination_category,
      other_Services,
      personal_care,
    } = detailsData;

    if (!title) {
      toast.error("Please Enter Package Name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!packageType) {
      toast.error("Please Select Package Type!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!total_days || !total_nights) {
      toast.error("Please Enter Valid Days And Night!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (total_days !== undefined && total_nights !== undefined) {
      if (total_nights < total_days - 1 && total_nights !== total_days && total_nights > total_days + 1) {
        toast.error("Please enter a valid number of nights based on the days entered!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        return;
      }
    } else {
      toast.error("Please enter valid values for days and nights!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!details?.[0]?.hotel_type?.includes("Not Required") && selectedHotelTypes?.length < 1) {
      toast.error("Please Select Hotel Types", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    // if (selectedMealTypes?.length < 1) {
    //   toast.error("Please Select Meal Types", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return;
    // }

    // if (selectedMeals?.length < 1) {
    //   toast.error("Please Select Meal Required", {
    //     position: toast.POSITION.BOTTOM_RIGHT,
    //     autoClose: 1000,
    //   });
    //   return;
    // }

    if (!startDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!startDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!endDate) {
      toast.error("Please Select Package Validation Duration!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (selectedTravelBy?.length < 1) {
      toast.error("Please Select Travel By", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!selectedOption) {
      toast.error("Please Select Sightseeing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!details?.[0]?.hotel_type?.includes("Not Required") && !sharing) {
      toast.error("Please Select Room Sharing", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!agencyServices) {
      toast.error("Please Enter Other Service By Agency", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (Number(displayData?.[0]?.total_adult) > 0 && Number(totalBudgetPerPersonAdults) === 0) {
      toast.error("Please Enter Price Per Person For Adults", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (Number(displayData?.[0]?.total_adult) > 0 && Number(totalBudgetPerPersonAdults) === 0) {
      toast.error("Please Enter Price Per Person For Adults", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    setLoading(true);

    const totalAmount = Number(totalBudgetAdults) + Number(totalBudgetChildren) + Number(totalBudgetInfants);
    const token = localStorage.getItem("vendorToken");

    try {
      const res = await fetch(`${BASE_URL}bidpackage?bid_id=${BidDataId}`, {
        method: "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          custom_requirement_id: id,
          total_adult,
          Infant,
          total_child,
          destination_category,
          hotel_types: selectedHotelTypes.map((option) => option.value).join(", "),
          meal_types: selectedMealTypes.map((option) => option.value).join(", "),
          meal_required: selectedMeals.map((option) => option.value).join(", "),
          other_Services,
          other_services_by_agency: agencyServices,
          price_per_person_adult: totalBudgetPerPersonAdults,
          price_per_person_child: totalBudgetPerPersonChildren,
          price_per_person_infant: totalBudgetPerPersonInfants,
          total_days: Number(totalTravellingDays),
          personal_care,
          travel_by: selectedTravelBy.map((option) => option.value).join(", "),
          total_nights: Number(totalTravellingNights),
          total_amount: totalAmount,
          end_date: endDate,
          start_date: startDate,
          sightseeing: selectedOption,
          room_sharing: sharing,
          name: title,
          package_type: packageType,
        }),
      });

      const data = await res.json();
      if (data.code == 200) {
        setValue(1)
        setLoading(false);
        toast.success("Bid Updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        getBidPackage();
      } else {
        setLoading(false);
        toast.error(data?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [showConfirmModalInclu, setShowConfirmModalInclu] = useState(false);
  const [itemToDeleteInclu, setItemToDeleteInclu] = useState(null);

  const handleExcludeDelete = () => {
    const updatedList = [...excludeList];
    updatedList.splice(itemToDelete, 1);
    setExcludeList(updatedList);
    setShowConfirmModal(false);
  };

  const handleDeleteClick = (index) => {
    if (BidData?.bid_status !== 'submit') {
      setItemToDelete(index);
      setShowConfirmModal(true);
    }
  };
  const handleIncludeDelete = () => {
    const updatedList = [...includeList];
    updatedList.splice(itemToDeleteInclu, 1);
    setIncludeList(updatedList);
    setShowConfirmModalInclu(false);
  };

  const handleDeleteClickInclu = (index) => {
    if (BidData?.bid_status !== 'submit') {
      setItemToDeleteInclu(index);
      setShowConfirmModalInclu(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }


  const [daysArray, setDaysArray] = useState([]);
  const [nightsArray, setNightsArray] = useState([]);
  const [daysDropdownValue, setDaysDropdownValue] = useState(null);
  const [nightsDropdownValue, setNightsDropdownValue] = useState(null);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the total number of days between the start and end dates
      const timeDiff = end.getTime() - start.getTime();
      const totalDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Total days

      // Generate an array for days, starting from 2 to totalDays + 1
      const generatedDaysArray = Array.from({ length: totalDays }, (_, i) => i + 2);
      setDaysArray(generatedDaysArray);

      // Generate nights array based on the selected total days
      if (daysDropdownValue) {
        const generatedNightsArray = Array.from({ length: daysDropdownValue - 1 }, (_, i) => i + 1);
        setNightsArray(generatedNightsArray);
      }
    }
  }, [startDate, endDate, daysDropdownValue]);

  const handleNavigate = () => {
    navigate(`/vendor/add-itineries/${id}/${BidDataId}`, { state: hotelType })
  }

  const isExpired = details && details?.[0]?.end_date && new Date(details?.[0]?.end_date) < new Date();

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <ToastContainer />
        <div style={{ backgroundColor: "whitesmoke" }}>
          <ProfileSection title="Submit Form" />
          <div className='p-3'>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  overflowX: "auto",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Overview' {...a11yProps(0)} />
                  <Tab
                    label='Itinerary'
                    {...a11yProps(1)}
                    disabled={!BidDataId}
                  />
                  <Tab
                    label='Services'
                    {...a11yProps(2)}
                    disabled={!thirdOpen}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <section className='vendor_submit_packages mt-3'>
                  <div className='costum_container'>
                    <div className='p-sm-3 p-0 pt-sm-0 '>
                      {details.map((ele, index) => {
                        const DestinationCategory =
                          ele.destination_category_name
                            ? ele.destination_category_name
                              .map((e) => e.category_name)
                              .join(", ")
                            : ""

                        return (
                          <>
                            <div key={index}>
                              <Row
                                className='green_border gy-sm-2 gy-0 gx-sm-5 gx-3 margin_left_right'
                                style={{ backgroundColor: "#ffffff" }}
                              >
                                {!BidDataId && (
                                  <p className='text-center font700 text16'>
                                    Please first fill overview details and submit the form. Then
                                    after you can go to next step.
                                  </p>
                                )}
                                {BidDataId ? (
                                  <p className='text-end text14 font700'>Bid Data</p>
                                ) : (
                                  <p className='text-end text14 font700'>
                                    Custom Requirement{" "}
                                  </p>
                                )}
                                <p>
                                  <h3 className='text-capitalize text24 mb-0 font700'>
                                    {ele.departure} To {ele.destination}
                                  </h3>
                                </p>

                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Package Name</p>
                                  <input
                                    type='text'
                                    name='total_adult'
                                    id=''
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    disabled={BidData?.bid_status === 'submit'}
                                  />
                                </div>

                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Package Type</p>
                                  <select name="total_days" id="" onChange={(e) => setPackageType(e.target.value)} value={packageType} disabled={BidData?.bid_status === 'submit'} >
                                    <option value="">Select</option>
                                    <option value="Land Package">Land Package</option>
                                  </select>
                                </div>

                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Adult (12+ years)</p>
                                  <input
                                    type='number'
                                    name='total_adult'
                                    id=''
                                    value={ele.total_adult}
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Infants (0 to 2 years)</p>
                                  <input
                                    type='number'
                                    name='Infant'
                                    id=''
                                    value={ele.Infant}
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Child (2 to 12 years)</p>
                                  <input
                                    type='number'
                                    name='total_child'
                                    id=''
                                    value={ele.total_child}
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Total Person</p>
                                  <input
                                    type='text'
                                    name=''
                                    id=''
                                    value={
                                      ele.total_adult +
                                      ele.total_child +
                                      ele.Infant
                                    }
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Personal Care</p>
                                  <input
                                    type='text'
                                    name='personal_care'
                                    id=''
                                    value={ele.personal_care}
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-6 col-12 py-1 ms-0'>
                                  <div className='col-md-4 col-12 py-2 px-0 px-md-2'>
                                    <p className='mb-2 font600 text14'>Travel Days</p>
                                    <input
                                      type='text'
                                      name='total_travel_days'
                                      id=''
                                      value={ele.total_travel_days}
                                      onChange={txtData}
                                      style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                    />
                                  </div>

                                  <div className=' col-md-4 col-12 py-2 px-0 px-md-2'>
                                    <p className='mb-2 font600 text14'>Total Days</p>
                                    <div>

                                      <select name="total_days" id="" onChange={(e) => setTotalTravellingDays(e.target.value)} value={totalTravellingDays} disabled={BidData?.bid_status === 'submit'} >
                                        {[...Array(30)].map((_, index) => (
                                          <option key={index} value={index + 1}>{index + 1}</option>
                                        ))}
                                      </select>
                                      {totalDays && (
                                        <span className='text-danger'>
                                          {totalDays}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className='col-md-4 col-12 py-2 px-0 px-md-2'>
                                    <p className='mb-2 font600 text14'>Total Night</p>
                                    <div>
                                      <select name="total_nights" onChange={(e) => setTotalTravellingNights(e.target.value)} id="" value={totalTravellingNights} disabled={BidData?.bid_status === 'submit'}>
                                        <option value="">Select</option>
                                        <option value={Number(totalTravellingDays) - 1}>{Number(totalTravellingDays) - 1}</option>
                                        <option value={Number(totalTravellingDays)}>{Number(totalTravellingDays)}</option>
                                        <option value={Number(totalTravellingDays) + 1}>{Number(totalTravellingDays) + 1}</option>
                                      </select>
                                      {totalNights && (
                                        <span className='text-danger'>
                                          {totalNights}
                                        </span>
                                      )}
                                      <span style={{ color: "red" }}>
                                        {nightError}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>
                                    Destination Category
                                    {/* (here i have got id not
                                    a name) */}
                                  </p>

                                  <input
                                    type='text'
                                    name='destination_category'
                                    id=''
                                    value={
                                      ele.destination_category
                                        ? ele.destination_category
                                          .map((e) => e.category_name)
                                          .join(", ")
                                        : ""
                                    }
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                  />
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1 px-0 px-md-2 ms-0 ms-md-1'>
                                  <div className='col-xl-6 col-12 py-2'>
                                    <p className='mb-2 font600 text14'>Hotel Type</p>

                                    <input
                                      type='text'
                                      name='hotel_type'
                                      value={
                                        ele.hotel_type
                                          ? ele.hotel_type
                                            .map((e) => e)
                                            .join(", ")
                                          : ""
                                      }
                                      style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                    />
                                  </div>
                                  <div className='col-xl-6 col-12 mt-xl-4'>
                                    <p className='mb-2 mt-3'></p>
                                    {BidStatus === "submit" && <input
                                      type='text'
                                      name='total_travel_days'
                                      id=''
                                      value={getSelectedHotelTypesString()}
                                      disabled
                                    />}
                                    {BidStatus !== "submit" && !ele?.hotel_type?.includes("Not Required") &&
                                      <Select
                                        isMulti
                                        name='New_hotel_type'
                                        options={colourOptions}
                                        className='basic-multi-select'
                                        classNamePrefix='select'
                                        onChange={updateHotelTypes}
                                        isSearchable={isSearchable}
                                        value={selectedHotelTypes}
                                      />}
                                  </div>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-0 px-md-2 py-1 ms-0 ms-md-1'>
                                  <div className='col-xl-6 py-2'>
                                    <p className='mb-2 font600 text14'>Meal Type</p>
                                    <input
                                      type='text'
                                      name='meal_type'
                                      id=''
                                      value={ele.meal_type}
                                      onChange={txtData}
                                      style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                    />
                                  </div>
                                  <div className='col-xl-6 mt-xl-4'>
                                    <p className='mb-2 mt-3'></p>
                                    {BidStatus === "submit" && <input
                                      type='text'
                                      name='total_travel_days'
                                      id=''
                                      value={getSelectedMealTypesString()}
                                      disabled
                                    />}
                                    {BidStatus !== "submit" && <Select
                                      isMulti
                                      name='colors'
                                      options={colourOptions1}
                                      className='basic-multi-select'
                                      classNamePrefix='select'
                                      onChange={updateMealTypes}
                                      isSearchable={isSearchable}
                                      value={selectedMealTypes}
                                    />}
                                  </div>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-6 col-12 px-0 px-md-2 py-1 ms-0 ms-md-2'>
                                  <div className='col-xl-6 col-12 py-2'>
                                    <p className='mb-2 font600 text14'>Meals Required</p>

                                    <input
                                      type='text'
                                      name='meal_require'
                                      id=''
                                      value={
                                        ele.meal_require
                                          ? ele.meal_require
                                            .map((e) => e)
                                            .join(", ")
                                          : ""
                                      }
                                      onChange={txtData}
                                      style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}

                                    />
                                  </div>
                                  <div className='col-xl-6 mt-xl-4'>
                                    <p className='mb-2 mt-3'></p>
                                    {BidStatus === "submit" && <input
                                      type='text'
                                      name='total_travel_days'
                                      id=''
                                      disabled
                                      value={getSelectedMealsString()}
                                    />}
                                    {BidStatus !== "submit" && <Select
                                      isMulti
                                      name='colors'
                                      options={colourOptions2}
                                      className='basic-multi-select'
                                      classNamePrefix='select'
                                      onChange={updateMeals}
                                      isSearchable={isSearchable}
                                      value={selectedMeals}
                                    />}
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>
                                    Approx Departure Between
                                  </p>
                                  <input
                                    type='text'
                                    name=''
                                    id=''
                                    value={
                                      formatDate(ele.start_date) +
                                      " To " +
                                      formatDate(ele.end_date)
                                    }
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}

                                  />
                                  <div className="mt-2 w-100">
                                    <div className="w-100">
                                      <p className="mb-1 text14 font600">Package Valid Between</p>
                                      <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12 cool-sm-12 col-12">
                                          <p className="mb-1 text14 font600">Start Date</p>
                                          {BidStatus === "submit" &&
                                            <input
                                              type="date"
                                              value={startDate}
                                              disabled={true}
                                              min={new Date().toISOString().slice(0, 10)}
                                            />}
                                          {BidStatus !== "submit" &&
                                            <input
                                              type="date"
                                              value={startDate}
                                              onChange={handleStartDateChange}
                                              min={new Date().toISOString().slice(0, 10)}
                                            />
                                          }
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12 cool-sm-12 col-12">
                                          <p className="mb-1 text14 font600">End Date</p>
                                          {BidStatus === "submit" &&
                                            <input
                                              type="date"
                                              value={endDate}
                                              disabled={true}
                                            />
                                          }
                                          {BidStatus !== "submit" &&
                                            <input
                                              type="date"
                                              value={endDate}
                                              onChange={handleEndDateChange}
                                              min={startDate}
                                              max={ele.end_date?.slice(0, 10)}
                                              disabled={!startDate}
                                            />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='row col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 px-0 px-md-2 py-1 ms-0 ms-md-1'>
                                  <div className='col-xl-6 py-2'>
                                    <p className='mb-2 font600 text14'>Travel By</p>
                                    <input
                                      type='text'
                                      name='travel_by'
                                      id=''
                                      value={
                                        ele.travel_by
                                          ? ele.travel_by
                                            .map((e) => e)
                                            .join(", ")
                                          : ""
                                      }
                                      onChange={txtData}
                                      style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}

                                    />
                                  </div>
                                  <div className='col-xl-6 mt-xl-4'>
                                    <p className='mb-2 mt-3'></p>
                                    {BidStatus === "submit" && <input
                                      type='text'
                                      name='total_travel_days'
                                      id=''
                                      value={getSelectedTravelByString()}
                                      style={{ cursor: "no-drop" }}
                                    />}
                                    {BidStatus !== "submit" && <Select
                                      isMulti
                                      name='colors'
                                      options={colourOptions3}
                                      className='basic-multi-select'
                                      classNamePrefix='select'
                                      onChange={updateTravelBy}
                                      isSearchable={isSearchable}
                                      value={selectedTravelBy}
                                    />}
                                  </div>
                                  <div className="col-12 mt-3">
                                    <div className="row gy-2">
                                      <div className="col-12 col-sm-6">
                                        <p className="mb-0 text14 font600">Sightseeing</p>
                                        <div className="badge-label-sighseeing">
                                          <span className="text14">{details?.[0]?.sightseeing}</span>
                                        </div>
                                        {BidStatus === "submit" && <div style={{ display: "flex", gap: "30px" }}>
                                          <div className="">
                                            <div className="d-flex items-center">
                                              <input
                                                type="radio"
                                                name="xxx"
                                                value="include"
                                                id="include"
                                                className="w-auto"

                                                checked={selectedOption === 'include'}

                                              />
                                              <label htmlFor="include" className="mb-0 ms-2 text-black">Include</label>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="d-flex items-center">
                                              <input
                                                type="radio"
                                                name="xxx"
                                                value="exclude"
                                                id="exclude"
                                                className="w-auto"

                                                checked={selectedOption === 'exclude'}

                                              />
                                              <label htmlFor="exclude" className="mb-0 ms-2 text-black">Exclude</label>
                                            </div>
                                          </div>
                                        </div>}


                                        {BidStatus !== "submit" &&
                                          <>
                                            <div style={{ display: "flex", gap: "30px" }}>
                                              <div className="">
                                                <div className="d-flex items-center">
                                                  <input
                                                    type="radio"
                                                    name="xxx"
                                                    value="include"
                                                    id="include"
                                                    className="w-auto"
                                                    onChange={handleOptionChange}
                                                    checked={selectedOption === 'include'}
                                                  />
                                                  <label htmlFor="include" className="mb-0 ms-2 text-black">Include</label>
                                                </div>
                                              </div>
                                              <div className="">
                                                <div className="d-flex items-center">
                                                  <input
                                                    type="radio"
                                                    name="xxx"
                                                    value="exclude"
                                                    id="exclude"
                                                    className="w-auto"
                                                    onChange={handleOptionChange}
                                                    checked={selectedOption === 'exclude'}
                                                  />
                                                  <label htmlFor="exclude" className="mb-0 ms-2 text-black">Exclude</label>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        }
                                      </div>

                                      {ele?.hotel_type?.includes("Not Required") ? (
                                        <></>
                                      ) : (
                                        <div className="col-12 col-sm-6">
                                          <p className='mb-2 font600 text14'>Room Sharing </p>
                                          <select name="room_sharing" id="" onChange={(e) => setSharing(e.target.value)} value={sharing} disabled={BidData?.bid_status === 'submit'}>
                                            <option value="">Select</option>
                                            <option value="single sharing">Single Sharing</option>
                                            <option value="double sharing">Double Sharing</option>
                                            <option value="triple sharing">Triple Sharing</option>
                                          </select>
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <p className='mb-2 font600 text14'>Other Requirement</p>
                                  <textarea
                                    className="w-100 px-2 py-2 rounded"
                                    cols="15"
                                    rows="8"
                                    name='additional_requirement'
                                    id=''
                                    value={ele.additional_requirement}
                                    onChange={txtData}
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop", maxHeight: "130px", overflowY: "scroll" }}
                                  ></textarea>
                                </div>

                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1">
                                  <p className='mb-2 font600 text14'>Other Services By Agency</p>
                                  <div>
                                    <CKEditor
                                      editor={ClassicEditor}
                                      data={agencyServices}
                                      onChange={(event, editor) => setAgencyServices(editor.getData())}
                                    />
                                  </div>
                                </div>

                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-1'>
                                  <div className="row">
                                    <div className='col-12 py-1 mb-2'>

                                      <div className="row">
                                        <div className="col-3"></div>
                                        <div className="col-4">
                                          <p className='mb-2 font600 text14'>Price Per Person</p>
                                        </div>
                                        <div className="col-3">
                                          <p className='mb-2 font600 text14'>Total</p>
                                        </div>
                                      </div>

                                      <div className="row align-items-center mb-2">
                                        <div className="col-12 col-sm-3">
                                          <h6 className="font-vendor mb-0">Adults ({displayData?.[0]?.total_adult})</h6>
                                        </div>
                                        <div className="col-12 col-sm-4 mb-2 mb-sm-0">
                                          <input
                                            type="number"
                                            name="budget_per_person"
                                            onChange={budgetChangeAdults}
                                            value={totalBudgetPerPersonAdults}
                                            disabled={BidData?.bid_status === 'submit'}
                                            onKeyDown={(e) => {
                                              if (["e", "E", "+", "-", "."].includes(e.key)) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                        </div>

                                        <div className="col-12 col-sm-4">
                                          <input
                                            type='number'
                                            name='budget_per_person'
                                            id=''
                                            disabled
                                            style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                            value={totalBudgetAdults}
                                          />
                                        </div>
                                      </div>

                                      {Number(displayData?.[0]?.total_child) !== 0 && (
                                        <div className="row align-items-center mb-2">
                                          <div className="col-3">
                                            <h6 className="font-vendor mb-0">Children ({displayData?.[0]?.total_child})</h6>
                                          </div>
                                          <div className="col-lg-4">
                                            <input
                                              type='number'
                                              name='budget_per_person'
                                              id=''
                                              onChange={budgetChangeChildren}
                                              value={totalBudgetPerPersonChildren}
                                              disabled={BidData?.bid_status === 'submit'}
                                              onKeyDown={(e) => {
                                                if (["e", "E", "+", "-", "."].includes(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <input
                                              type='number'
                                              name='budget_per_person'
                                              id=''
                                              disabled
                                              style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                              value={totalBudgetChildren}
                                            />
                                          </div>
                                        </div>
                                      )}

                                      {Number(displayData?.[0]?.Infant) !== 0 && (
                                        <div className="row align-items-center mb-2">
                                          <div className="col-3">
                                            <h6 className="font-vendor mb-0">Infants ({displayData?.[0]?.Infant})</h6>
                                          </div>
                                          <div className="col-lg-4">
                                            <input
                                              type='number'
                                              name='budget_per_person'
                                              id=''
                                              onChange={budgetChangeInfants}
                                              value={totalBudgetPerPersonInfants}
                                              disabled={BidData?.bid_status === 'submit'}
                                              onKeyDown={(e) => {
                                                if (["e", "E", "+", "-", "."].includes(e.key)) {
                                                  e.preventDefault();
                                                }
                                              }}
                                            />
                                          </div>
                                          <div className="col-lg-4">
                                            <input
                                              type='number'
                                              name='budget_per_person'
                                              id=''
                                              disabled
                                              style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                              value={totalBudgetInfants}
                                            />
                                          </div>
                                        </div>
                                      )}

                                    </div>

                                    <div className="col-12">
                                      <div className="row align-items-center">
                                        <div className="col-12 col-sm-3">
                                          <h6 className='font-vendor mb-2'>Total Amount</h6>
                                        </div>
                                        <div className="col-12 col-sm-8">
                                          <input
                                            type='text'
                                            name='total_amount'
                                            id=''
                                            disabled
                                            style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                            value={totalBudget}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                      <div className="row align-items-center">
                                        <div className="col-12 col-sm-3">
                                          <h6 className='font-vendor'>Admin Margin</h6>
                                          <h6 className='font-vendor'>({adminMargin?.margin_percentage || 0}%)</h6>
                                        </div>
                                        <div className="col-12 col-sm-8">
                                          <input
                                            type='text'
                                            name='total_amount'
                                            id=''
                                            disabled
                                            style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                            value={adminMarginInPrice || 0}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 mb-3">
                                      <div className="row align-items-center">
                                        <div className="col-12 col-sm-3">
                                          <h6 className='font-vendor mb-2'>Grand Total</h6>
                                        </div>
                                        <div className="col-12 col-sm-8">
                                          <input
                                            type='text'
                                            name='total_amount'
                                            id=''
                                            disabled
                                            style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                            value={totalBudget + (adminMarginInPrice || 0)}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                <div className="col-6">
                                  <p className='mb-2 font600 text14'>Price Per Person (By User)</p>
                                  <input
                                    type='text'
                                    name='meal_require'
                                    id=''
                                    style={{ backgroundColor: "#dddddd", cursor: "no-drop" }}
                                    disabled
                                    value={displayData?.[0]?.budget_per_person}
                                    className="w-100"
                                  />
                                </div>

                                {!BidDataId && (
                                  <div className='col-12 text-end '>
                                    {loading ? (
                                      <>
                                        <Button
                                          variant='primary'
                                          className='ms-2 back_next_btn back_next_btn2'
                                          type='submit'
                                          style={{
                                            backgroundColor: "#155E75",
                                          }}
                                        >
                                          <img src={loader} alt="" className="spinner" style={{ height: "25px" }} />
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        {/* <Button
                                          type='submit'
                                          className=''
                                          onClick={getBidDetails}
                                        >
                                          Save
                                        </Button> */}
                                        <Button
                                          variant='primary'
                                          className='ms-2 back_next_btn back_next_btn2'
                                          type='submit'
                                          style={{
                                            backgroundColor: "#155E75",
                                          }}
                                          onClick={getBidDetails}
                                        >
                                          Save
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                )}

                                {!isExpired && (
                                  <div className='col-12 text-center'>
                                    {BidDataId && BidData?.bid_status !== 'submit' && (
                                      <div className="d-flex justify-content-end">
                                        {loading ? (
                                          <>
                                            <Button
                                              variant='primary'
                                              className='me-2 back_next_btn back_next_btn2'
                                              type='submit'
                                              style={{
                                                backgroundColor: "#155E75",
                                              }}
                                            >
                                              <img src={loader} alt="" className="spinner me-2" style={{ height: "25px" }} />
                                            </Button>
                                          </>
                                        ) : (
                                          <>
                                            {/* <Button
                                              className='me-2'
                                              onClick={handleUpdateBid}
                                            >
                                              Save & Next
                                            </Button> */}
                                            <Button
                                              variant='primary'
                                              className='me-2 back_next_btn back_next_btn2'
                                              type='submit'
                                              style={{
                                                backgroundColor: "#155E75",
                                              }}
                                              onClick={handleUpdateBid}
                                            >
                                              Save
                                            </Button>
                                          </>
                                        )}

                                        {/* <Button
                                          type='button' // Use type "button" to prevent form submission
                                          className=''
                                          onClick={() => {
                                            if (
                                              BidDataId === undefined &&
                                              detailsData.total_days === "" &&
                                              detailsData.total_nights === ""
                                            ) {
                                              // Show an error toast
                                              setTotalDays("please fill total days")
                                              setTotalNights(
                                                "please fill total nights",
                                              )
                                              toast.error(
                                                "Please fill in total days and total nights.",
                                                {
                                                  position:
                                                    toast.POSITION.BOTTOM_RIGHT,
                                                  autoClose: 1000,
                                                },
                                              )
                                            } else {
                                              setValue(1)
                                            }
                                          }}
                                        >
                                          Next
                                        </Button> */}
                                        <Button
                                          variant='primary'
                                          className='ms-2 back_next_btn back_next_btn2'
                                          type='button'
                                          style={{
                                            backgroundColor: "#155E75",
                                          }}
                                          onClick={() => {
                                            if (
                                              BidDataId === undefined &&
                                              detailsData.total_days === "" &&
                                              detailsData.total_nights === ""
                                            ) {
                                              // Show an error toast
                                              setTotalDays("please fill total days")
                                              setTotalNights(
                                                "please fill total nights",
                                              )
                                              toast.error(
                                                "Please fill in total days and total nights.",
                                                {
                                                  position:
                                                    toast.POSITION.BOTTOM_RIGHT,
                                                  autoClose: 1000,
                                                },
                                              )
                                            } else {
                                              setValue(1)
                                            }
                                          }}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    )}
                                    {BidData?.bid_status === 'submit' && (
                                      <div className="text-end">
                                        <Button
                                          type='button' // Use type "button" to prevent form submission
                                          className=''
                                          onClick={() => {
                                            if (
                                              BidDataId === undefined &&
                                              detailsData.total_days === "" &&
                                              detailsData.total_nights === ""
                                            ) {
                                              // Show an error toast
                                              setTotalDays("please fill total days")
                                              setTotalNights(
                                                "please fill total nights",
                                              )
                                              toast.error(
                                                "Please fill in total days and total nights.",
                                                {
                                                  position:
                                                    toast.POSITION.BOTTOM_RIGHT,
                                                  autoClose: 1000,
                                                },
                                              )
                                            } else {
                                              setValue(1)
                                            }
                                          }}
                                        >
                                          Next
                                        </Button>
                                      </div>
                                    )}

                                  </div>
                                )}
                              </Row>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <section>
                  <div className='costum_container'>
                    <div className='p-sm-3 p-0'>
                      <div>
                        <Form>
                          <Row
                            className='itinerary_padding green_border gy-2 gx-5 margin_left_right'
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <span className='text-20 itinerary_header text22 font700'>
                                    Day wise Itinerary plan ({totalTravellingDays}D/{totalTravellingNights}N)
                                  </span>
                                </div>

                                {BidData &&
                                  <>
                                    {it?.length < Number(totalItinerary) && (
                                      <div className='col-12 col-md-6 d-flex align-items-center justify-content-end'>
                                        <p className='cmnclr cmnp'>
                                          Add days
                                        </p>
                                        <span onClick={handleNavigate}>
                                          <a>
                                            <FontAwesomeIcon
                                              icon={faPlus}
                                              className='cmnbkg p-2 ms-2'
                                              style={{ cursor: "pointer" }}
                                            />
                                          </a>
                                        </span>
                                      </div>
                                    )}
                                  </>
                                }
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='row gx-5 gy-3'>
                                {it &&
                                  it.map((ele) => {
                                    return (
                                      <>
                                        <div className='col-lg-6 col-12'>
                                          <div
                                            style={{
                                              border: "1px solid grey",
                                              borderRadius: "15px",
                                            }}
                                          >
                                            <div className='d-flex justify-content-between'>
                                              <p
                                                className="text14 font600 mb-0"
                                                style={{
                                                  backgroundColor: "#09646d",
                                                  color: "white",
                                                  padding: "5px 10px",
                                                  display: "inline-block",
                                                  borderRadius:
                                                    "15px 0px 15px 0px",
                                                }}
                                              >
                                                Day {ele.day}
                                              </p>
                                              {BidData?.bid_status !== 'submit' && (
                                                <p
                                                  className="text14 font600 mb-0"
                                                  style={{
                                                    backgroundColor: "#09646d",
                                                    color: "white",
                                                    padding: "5px 10px",
                                                    display: "inline-block",
                                                    borderRadius:
                                                      "0px 15px 0px 15px",
                                                    cursor: "pointer"
                                                  }}
                                                  onClick={() => {
                                                    navigate(
                                                      `/vendor/add-itineriesEdit/${ele._id}/${BidDataId}`, { state: { customrid: id, hotelType: hotelType } }
                                                    )
                                                    setDayNumber(ele.day)
                                                  }}
                                                >
                                                  EDIT
                                                </p>
                                              )}
                                            </div>
                                            <div className='p-3'>
                                              <div>
                                                <div className='mb-2'>
                                                  <label htmlFor='text' className="text14 font600 mb-1">
                                                    Title
                                                  </label>
                                                  <input
                                                    type='text'
                                                    name=''
                                                    id=''
                                                    value={ele.title}
                                                  />
                                                </div>
                                                {ele.photo &&
                                                  <div className="mb-2">
                                                    <label htmlFor="text" className="text14 font600 mb-1 d-block">
                                                      Photo
                                                    </label>
                                                    <img
                                                      src={ele.photo}
                                                      alt="dayphoto"
                                                      style={{ height: "100px", width: "100px", objectFit: "cover", borderRadius: "8px" }}
                                                    />
                                                  </div>
                                                }

                                                {hotelType === "Not Required" ? (
                                                  <></>
                                                ) : (
                                                  <div className='mb-2'>
                                                    <label htmlFor='text' className="text14 font600 mb-1">
                                                      Hotel Name
                                                    </label>
                                                    <input
                                                      type='text'
                                                      name=''
                                                      id=''
                                                      value={ele?.hotel_itienrary?.[0]?.hotel_name}
                                                    />
                                                  </div>
                                                )}
                                                <div className='mb-2'>
                                                  <a className="text14 font600 mb-1" style={{ color: "#000", textDecoration: "none" }}>Description/Activity</a>
                                                  <div
                                                    className="black-p"
                                                    style={{
                                                      textAlign: "justify",
                                                      color: "black",
                                                      border: "1px solid #09646d",
                                                      borderRadius: "5px",
                                                      padding: "8px 20px",
                                                      wordBreak: "break-word",  // Ensures long words break and wrap
                                                      overflowWrap: "break-word" // Ensures long words wrap correctly
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                      __html: ele.activity,
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                              </div>
                            </div>
                            <div className='col-12'>
                              <div className='py-2 pt-3 text-end'>
                                <Button
                                  className='back_next_btn'
                                  style={{
                                    background: "#0000",
                                    color: "black",
                                    border: "1px solid black",
                                  }}
                                  onClick={() => setValue(0)}
                                >
                                  Back
                                </Button>
                                <Button
                                  variant='primary'
                                  className='ms-2 back_next_btn back_next_btn2'
                                  type='submit'
                                  style={{
                                    backgroundColor: "#155E75",
                                  }}
                                  onClick={handleSetThree}
                                  disabled={it?.length !== Number(totalItinerary)}
                                >
                                  Next
                                </Button>

                              </div>
                            </div>
                          </Row>
                        </Form>
                      </div>
                    </div>
                  </div>
                </section>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <section>
                  <div className='costum_container'>
                    <div
                      className='service_border'
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      <div className='py-2 itinerary_header'>
                        <p className='cmnp text20 font700'>Include / Exclude</p>
                      </div>

                      {/* include */}

                      {includeList.length === 0 && excludeList.length === 0 && (
                        <p className='cmnp'>
                          Not a added include or exclude services.
                        </p>
                      )}

                      {(includeList.length > 0 || excludeList.length > 0) && (
                        <div className='cmn py-3'>
                          <div className='border_bottom_2'>
                            {includeList &&
                              includeList.map((includeItem, index) => (
                                <div
                                  key={index}
                                  className='w-100 px-4 py-1 d-flex align-items-center justify-content-between'
                                  style={{
                                    borderBottom: "1px solid #09646D",
                                  }}
                                >
                                  {/* Bullet point and content */}
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <li style={{ listStyleType: "disc", marginRight: "10px" }}></li>
                                    <div
                                      className="ckeditor-p-tag-mb0"
                                      dangerouslySetInnerHTML={{
                                        __html: includeItem,
                                      }}
                                    ></div>
                                  </div>

                                  {/* Buttons */}
                                  <div>
                                    <ButtonGroup
                                      aria-label='Basic example'
                                      className='text-end'
                                    >
                                      <Button
                                        style={{
                                          background: "#09646D",
                                          color: "#fff",
                                          borderRadius: "10px 0 0 10px",
                                          border: "none",
                                        }}
                                        className='text-end'
                                      >
                                        Include
                                      </Button>
                                      <Button
                                        style={{
                                          background: "#FFF",
                                          color: "#B8B8B8",
                                          border: "none",
                                        }}
                                      >
                                        Exclude
                                      </Button>
                                    </ButtonGroup>
                                    <button
                                      onClick={() => handleDeleteClickInclu(index)}
                                      style={{
                                        border: "none",
                                        background: "none",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        color: "red"
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>


                          {/* exclude */}
                          <div className='border_bottom_2'>
                            {excludeList &&
                              excludeList.map((excludeItem, index) => (
                                <div
                                  key={index}
                                  className='w-100 px-4 py-1 d-flex align-items-center justify-content-between'
                                  style={{
                                    borderBottom: "1px solid #09646D",
                                  }}
                                >
                                  {/* Bullet point and content */}
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <li style={{ listStyleType: "disc", marginRight: "10px" }}></li>
                                    <div
                                      className="ckeditor-p-tag-mb0"
                                      dangerouslySetInnerHTML={{
                                        __html: excludeItem,
                                      }}
                                    ></div>
                                  </div>

                                  {/* Buttons */}
                                  <div>
                                    <ButtonGroup
                                      aria-label='Basic example'
                                      className='text-end'
                                    >
                                      <Button
                                        style={{
                                          background: "#FFF",
                                          color: "#B8B8B8",
                                          border: "none",
                                        }}
                                        className='text-end'
                                      >
                                        Include
                                      </Button>
                                      <Button
                                        style={{
                                          background: "red",
                                          color: "#FFF",
                                          border: "none",
                                          borderRadius: "0 10px 10px 0",
                                        }}
                                      >
                                        Exclude
                                      </Button>
                                    </ButtonGroup>
                                    <button
                                      onClick={() => handleDeleteClick(index)}
                                      style={{
                                        border: "none",
                                        background: "none",
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        color: "red"
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                  </div>
                                </div>
                              ))}
                          </div>

                        </div>
                      )}

                      {BidData?.bid_status !== 'submit' && (
                        <div>
                          <div>
                            <div className='pt-4 mb-1'>
                              <p className='itinerary_header mb-0 font600 text16'>
                                Enter Services
                              </p>
                            </div>
                            <div className='py-1'>
                              <CKEditor editor={ClassicEditor} ref={editorRef} />
                              {/* <textarea ref={editorRef} id="" style={{width: "100%", padding: "10px 15px"}} rows="3"></textarea> */}
                            </div>
                          </div>
                          <div className='py-3 pt-3 text-end'>
                            <button
                              className='include_exclude_btn'
                              style={{
                                backgroundColor: "#155E75",
                                color: "#fff",
                                padding: "8px 40px",
                                border: "none",
                              }}
                              onClick={handleIncludeClick}
                            >
                              Include
                            </button>
                            <button
                              variant='primary'
                              className='ms-2 include_exclude_btn'
                              style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "8px 40px",
                                border: "none",
                              }}
                              onClick={handleExcludeClick}
                            >
                              Exclude
                            </button>
                          </div>
                        </div>
                      )}

                      <div className='py-2 text-center d-flex justify-content-center'>
                        <div>
                          <div className='d-flex'>
                            <input
                              type='checkbox'
                              checked={isChecked || BidData?.bid_status === 'submit'}
                              onChange={handleCheckboxChange}
                            />
                            <p className='cmnp rgba ps-1'>
                              I agree to{" "}
                              <a
                                href='/privacy-policy'
                                target="_blank"
                                style={{ color: "#09646d" }}
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                href='/payment-policy'
                                target="_blank"
                                style={{ color: "#09646d" }}
                              >
                                Terms of services
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>

                      {BidData?.bid_status !== 'submit' && (
                        <div className='py-3 pt-3 text-center'>
                          <Button
                            className='back_next_btn'
                            style={{
                              background: "#0000",
                              color: "#BBBB",
                              border: "1px solid #BBBB",
                            }}
                            onClick={() => setValue(1)}
                          >
                            Back
                          </Button>
                          {loading ? (
                            <Button
                              variant='primary'
                              className='ms-2 back_next_btn back_next_btn2'
                              style={{
                                backgroundColor: "#155E75",
                              }}
                            >
                              <img src={loader} alt="" className="spinner" style={{ height: "25px" }} />
                            </Button>
                          ) : (
                            <Button
                              variant='primary'
                              className='ms-2 back_next_btn back_next_btn2'
                              style={{
                                backgroundColor: "#155E75",
                              }}
                              onClick={handleFourth}
                            >
                              Submit
                            </Button>
                          )}
                        </div>
                      )}


                      {/* Confirmation Modal */}
                      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
                        <Modal.Body>
                          <h5 className="pt-3">Are you sure you want to delete this item?</h5>
                          <div className="d-flex justify-content-end mt-4 pb-2">
                            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                              Cancel
                            </Button>
                            <Button variant="danger" onClick={handleExcludeDelete} className="ms-2">
                              Yes, Delete
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>

                      {/* Confirmation Modal */}
                      <Modal show={showConfirmModalInclu} onHide={() => setShowConfirmModalInclu(false)} centered>
                        <Modal.Body>
                          <h5 className="pt-3">Are you sure you want to delete this item?</h5>
                          <div className="d-flex justify-content-end mt-4 pb-2">
                            <Button variant="secondary" onClick={() => setShowConfirmModalInclu(false)}>
                              Cancel
                            </Button>
                            <Button variant="danger" onClick={handleIncludeDelete} className="ms-2">
                              Yes, Delete
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>

                    </div>
                  </div>
                </section>
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
    </>
  )
}

export default Submit_package_form
