import { createContext, useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import { useAuthContext } from "./AuthContext";


const SocketContext = createContext();

export const useSocketContext = () => {
    return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const { authUser } = useAuthContext();

    // useEffect(() => {
    //     if (authUser) {
    //         const socket = io("https://chemical-api-usa2.onrender.com", {
    //             query: {
    //                 userId: authUser.id,
    //             },
    //         });

    //         setSocket(socket);

    //         return () => socket.close();
    //     } else {
    //         if (socket) {
    //             socket.close();
    //             setSocket(null);
    //         }
    //     }
    // }, [authUser]);

    return <SocketContext.Provider value={{ socket, }}>{children}</SocketContext.Provider>;

	return useContext(SocketContext);
};
