import React, { useEffect, useState } from 'react';
import './BookingModal.css';
import { BASE_URL } from '../../BASE_URL';
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import countries from "../../CountryStateCity.json"
import { useLocation, useParams, useNavigate } from "react-router-dom"


const BookingModal = ({ isOpen, onClose, hotelId, roomId, checkindate, checkoutdate, totalRooms, alreadyAdults, alreadyChildren, pricePerChild, pricePerAdult, priceAmount, pricePerChildWithMargin, pricePerAdultWithMargin }) => {
    const location = useLocation();
    const { id } = useParams();
    const { merchentId } = useParams();
    const navigate = useNavigate();

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [username, setUserName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [dob, setDob] = useState(null);
    const [paymentType, setPaymentType] = useState('');

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry)
        const selectedStates = countries.find((e) => e.name === selectedCountry)
        setStates(selectedStates?.states)
    }

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState)
        const selectedCities = states.find((e) => e.name === selectedState)
        setCities(selectedCities?.cities)
    }


    const userDetails = async () => {
        const token = localStorage.getItem("token");
        const res = await fetch(
            `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();

        const bookingDetails = JSON.parse(localStorage.getItem("bookingDetail"));

        const updatedbookingDetails =
        {
            ...bookingDetails,
            transaction_id: data?.data?.merchantTransactionId,
            payment_type: data?.data?.paymentInstrument?.type,
        }


        if (bookingDetails !== "") {
            localStorage.removeItem("bookingDetail")
            try {
                const token = localStorage.getItem("userToken");
                const response = await fetch(`${BASE_URL}hotel_booking_syt`, {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedbookingDetails),
                });


                const data = await response.json();

                if (data.code === 200) {
                    toast.success("Hotel Booked Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    })
                    // setTimeout(() => {
                    navigate("/my_package")
                    // }, 1000);
                }
            }
            catch (error) {
                toast.error("Failed to book the hotel!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                })
            }
        }

    };

    const [loading, setLoading] = useState(false)

    if (!isOpen) return null;


    const handlePrice = async () => {

        if (!totalRooms) {
            toast.error("Please Select Total Rooms!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            })
            return;
        }

        if (!paymentType) {
            toast.error("Please Select Payment Type!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            })
            return;
        }

        navigate(`/booking-hotel-payment/${id}`, {
            state: {
                finalAmountWithMargin: priceAmount,
                finalAmountAdultWithMargin: pricePerAdultWithMargin,
                finalAmountChildWithMargin: pricePerChildWithMargin,
                finalAmountAdult: pricePerAdult,
                finalAmountChild: pricePerChild,
                totalChild: alreadyChildren,
                totalAdult: alreadyAdults,
                checkIn: checkindate,
                checkOut: checkoutdate,
                checkOut: roomId?._id,
            },
        });

        // setLoading(true)

        const myId = localStorage.getItem("mySytUserId")
        const mobileno = contactNumber;
        const amount = Number(roomId?.price) * (Number(alreadyAdults) + Number(alreadyChildren))
        // const amount = 1;
        const url = location.pathname;
        const token = localStorage.getItem("userToken")

        // const res = await fetch(`${BASE_URL}payment/pay`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //         Authorization: token,
        //     },
        //     body: JSON.stringify({
        //         MERCHANT_USER_ID: myId,
        //         user_id: myId,
        //         link: url,
        //         amount: 1,
        //         paid_amount: priceAmount,
        //         price_per_person_adult: pricePerAdult,
        //         price_per_person_child: pricePerChild,
        //         hotel_id: hotelId,
        //         room_id: roomId?._id,
        //         total_booked_rooms: totalRooms,
        //         check_in_date: checkindate,
        //         check_out_date: checkoutdate,
        //         total_adult: alreadyAdults,
        //         total_child: alreadyChildren,
        //         paymemt_type: paymentType,
        //         room_title: roomId.room_title,
        //         price: priceAmount,
        //         user_name: username,
        //         gender: gender,
        //         country: country,
        //         state: state,
        //         city: city,
        //         dob: dob,
        //         contact_no: contactNumber,
        //     }),
        // });

        // const data = await res.json();

        // if (!data?.success) {
        //     // setLoading(false);
        //     toast.error(data?.message, {
        //         position: toast.POSITION.BOTTOM_RIGHT,
        //         autoClose: 3000,
        //     });
        // } else {
        //     localStorage.setItem('bookingId', data.data.booking_id)
        //     localStorage.setItem('paidAmount', roomId.room_title)
        //     if (data?.data?.url) {
        //         window.location.href = data?.data?.url
        //     }
        //     // setLoading(false);
        // }
    }

    // const InsertBooking = async () => {
    //     const token = localStorage.getItem("userToken");
    //     const bookingId = localStorage.getItem("bookingId");
    //     const paymentId = localStorage.getItem("paymentId");
    //     const type = localStorage.getItem("paymentType");
    //     const paidAmount = localStorage.getItem("paidAmount");

    //     localStorage.removeItem("paymentType");

    //     setLoading(true);

    //     const res = await fetch(`${BASE_URL}payment/updateBooking/${bookingId}`, {
    //         method: "PUT",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: token,
    //         },
    //         body: JSON.stringify({
    //             status: "booked",
    //             payment_id: paymentId,
    //             transaction_id: merchentId
    //         }),
    //     });

    //     const data = await res.json();

    //     if (data?.success) {
    //         setLoading(false);
    //         toast.success("Your Booking Successfully Done!", {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             autoClose: 3000,
    //         });
    //         setTimeout(() => {
    //             navigate("/my_package")
    //         }, 2000);
    //     } else {
    //         setLoading(false);
    //         toast.error(data?.message, {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             autoClose: 3000,
    //         });
    //     }

    // };

    // useEffect(() => {
    //     if (merchentId !== undefined && merchentId !== "") {
    //         InsertBooking();
    //     } else {

    //     }
    // }, [merchentId]);


    return (
        <>
        <div className="book-hotel-modal-overlay">
            <ToastContainer />
            <div className="book-hotel-modal-content">
                <h2 className='text20 font700'>Book Your Hotel</h2>
                <div >
                    <div className="row mb-2">
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Fullname</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Contact Number</label>
                            <input
                                type="text"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Gender</label>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Country</label>
                            <select
                                value={country}
                                onChange={handleCountryChange}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {countries && countries.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>State</label>
                            <select
                                value={state}
                                onChange={handleStateChange}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {states && states.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>City</label>
                            <select
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {cities && cities.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>DOB</label>
                            <input
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Total Adult</label>
                            <input
                                type="number"
                                value={alreadyAdults}
                                // onChange={(e) => setDob(e.target.value)}
                                // required
                                disabled
                            />

                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Total Child</label>
                            <input
                                type="number"
                                value={alreadyChildren}
                                // onChange={(e) => setDob(e.target.value)}
                                // required
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Room Title</label>
                            <input
                                type="text"
                                value={roomId.room_title}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Price</label>
                            <input
                                type="text"
                                value={roomId.price + " per night"}
                                // onChange={(e) => setRoomPrice(e.target.value)}
                                disabled
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Total Rooms</label>
                            <input
                                type="number"
                                value={totalRooms}
                                // onChange={(e) => setRoomPrice(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Check-in Date</label>
                            <input
                                type="date"
                                value={checkindate}
                                diabled
                            />
                        </div>
                        <div className="col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Check-out Date</label>
                            <input
                                type="date"
                                value={checkoutdate}
                                disabled
                            />
                        </div>
                        <div className=" col-12 col-sm-4">
                            <label className='text14 font600 mb-1'>Payment Type</label>
                            <select
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select Payment Type</option>
                                <option value="credit-card">Credit Card</option>
                                <option value="debit-card">Debit Card</option>
                                <option value="paypal">PayPal</option>
                                <option value="cash">Cash</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                        <div className="">
                            <button className="check-availibility-button text-20 border-0 " onClick={onClose}>Close</button>
                        </div>
                        <div className="">
                            <button type="button" onClick={handlePrice} className='check-availibility-button text-20 border-0 '>Book Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default BookingModal;
