import {
    faArrowLeft,
    faBell,
    faCableCar,
    faEnvelopeOpen,
    faLocationPin,
    faPenToSquare,
    faTag,
    faTrash,
    faUser,
    faStar,
    faCheck,
    faIndianRupeeSign,
    faHotel,
    faUtensils,
    faBinoculars,
    faBus,
    faTrainSubway,
    faPlane,
    faTaxi,
    faEdit,
    faUserDoctor,
    faLocationPinLock,
    faPaperclip,
    faDoorClosed,
    faBowlFood,
    faCookie,
    faMountainCity,
    faCalendarDays,
    faHouseFloodWaterCircleArrowRight,
    faBrush,
    faXmark,
    faHandshakeAlt
} from "@fortawesome/free-solid-svg-icons"
import {
    faCircleCheck,
    faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState, useRef } from "react"
import { Modal, Form, Button } from "react-bootstrap"
import "../Project/css/index1.css"
import { faCalendar } from "@fortawesome/free-regular-svg-icons"
import Side_navbar from "./Side_navbar"
import My_pannel from "./My_pannel"
import Header from "../Project/Header"
import Header2 from "./Header2"
import { useNavigate, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import './css/vendorpackages.css'
import Uppage from "../Project/Uppage"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSection from "./SecondNavbar";
import { Row } from "react-bootstrap";
import loader from "../images/loading.png"

const formatDate = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return 'Invalid date format';
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year

    return `${day}-${month}-${year}`;
};

const convertToRight = (dateString) => {
    if (dateString) {
        const [day, month, year] = dateString?.split("-");

        // Return the date in yyyy-mm-dd format
        return `${year}-${month}-${day}`;
    }
};

function getMonthWithMostDaysInRange(startDate, endDate) {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const start = new Date(startDate);
    const end = new Date(endDate);

    // To store the count of days for each month in the range
    const monthDaysCount = Array(12).fill(0);

    // Loop through each day in the range and count how many days fall in each month
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        const currentMonth = d.getMonth();
        monthDaysCount[currentMonth]++;
    }

    // Find the month with the maximum days
    let maxDays = 0;
    let monthWithMostDays = 0;

    for (let i = 0; i < monthDaysCount.length; i++) {
        if (monthDaysCount[i] > maxDays) {
            maxDays = monthDaysCount[i];
            monthWithMostDays = i;
        }
    }

    // Return the name of the month with the most days
    return months[monthWithMostDays];
}

function ViewMyPackage(props) {
    const navigate = useNavigate()

    const [pricePerPerson, setPricePerPerson] = useState([])
    const [editedata, setEditedData] = useState("")

    const Call = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(`${BASE_URL}agency/profile`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        })
        const data = await res.json()
        setEditedData(data?.data?.[0])
    }

    useEffect(() => {
        Call()
    }, [])

    const overRef = useRef(null)
    const itenarary = useRef(null)
    const services = useRef(null)
    const hotels = useRef(null)

    const scrollTo = (section) => {
        let targetRef

        switch (section) {
            case "overview":
                targetRef = overRef
                break
            case "services":
                targetRef = services
                break
            case "itenarary":
                targetRef = itenarary
                break
            case "hotels":
                targetRef = hotels
                break
            default:
                targetRef = null
        }

        if (targetRef) {
            window.scroll({
                top: targetRef.current.offsetTop,
                behavior: "smooth",
            })
        }
    }

    const { id } = useParams()
    const [pDetails, setPDetails] = useState([])
    const [hotelList, setHotelList] = useState([])
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [selectedHotelName, setSelectedHotelName] = useState("");
    const [selectedHotelId, setSelectedHotelId] = useState("");

    const [showModal, setShowModal] = useState(false);

    const PackageDetails = async () => {
        const token = localStorage.getItem("vendorToken")
        const res = await fetch(
            `${BASE_URL}package/getPackageData?package_id=${id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                },
            },
        )
        const data = await res.json()
        setPDetails(data.data)

        const dateToUse = new Date();


        const matchingPriceObject = data?.data?.[0]?.price_and_date.find((item) => {
            const startDate = new Date(item.price_start_date);
            const endDate = new Date(item.price_end_date);
            const date = new Date(dateToUse);
            const month = date.getMonth() + 1;
            const startMonth = startDate.getMonth() + 1;

            if (month === startMonth) {
                if (dateToUse.getDate() >= startDate.getDate() && dateToUse.getDate() <= endDate.getDate()) {
                    return true;
                }
            }

            return false;
        });

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        let currentMonthIndex = dateToUse.getMonth();

        let matchingMonth;
        for (let i = 0; i < 12; i++) {
            const monthToCheck = months[(currentMonthIndex + i) % 12]; // Ensure index stays within 0-11
            matchingMonth = data?.data?.[0]?.profitMargin?.[0]?.month_and_margin_user.find(
                (item) => item.month_name === monthToCheck
            );
            if (matchingMonth) {
                break;
            }
        }


        if (matchingMonth) {
            const marginPercentage = Number(matchingMonth.margin_percentage);
            const finalAmount = Number(matchingPriceObject?.price_per_person) * (marginPercentage / 100);
            setPricePerPerson(matchingPriceObject?.price_per_person + finalAmount);
        } else {
            // Handle the case where no match is found (if desired)
            setPricePerPerson(matchingPriceObject?.price_per_person); // Or any default value or behavior
        }

    }

    useEffect(() => {
        PackageDetails()
        window.scrollTo(0, 0)
    }, [])

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        photoFile: null,
        photoURL: '',
        description: '',
        day: ''
    });

    const handleClose = () => {
        setOpen(false)
        setFormData({
            title: '',
            photoFile: null,
            photoURL: '',
            hotelName: '',
            description: '',
            day: ''
        })
    };

    const handleClose1 = () => {
        setOpen1(false)
        setFormData({
            title: '',
            photoFile: null,
            photoURL: '',
            hotelName: '',
            description: '',
            day: ''
        })
    };
    const handleShow = () => setOpen(true);
    const handleShow1 = () => setOpen1(true);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'photoFile') {
            setFormData({ ...formData, [name]: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const [loading, setLoading] = useState(false)

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("vendorToken");

            // Validation
            if (!formData.title) {
                toast.error("Title is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!formData.description) {
                toast.error("Activity description is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!selectedHotelId) {
                toast.error("Select Hotel.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }

            setLoading(true)

            const formData1 = new FormData();
            formData1.append("title", formData.title);
            formData1.append("day", formData.day);
            formData1.append("activity", formData.description);
            formData1.append("hotel_itienrary_id", selectedHotelId);
            if (formData.photoFile) {
                formData1.append("photo", formData.photoFile);
            }

            const response = await fetch(`${BASE_URL}itinerary?_id=${formData.itineraryid}`, {
                method: "PUT",
                headers: {
                    Authorization: token,
                },
                body: formData1,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setLoading(false)
            const responseData = await response.json();
            toast.success("Itinerary updated successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            handleClose();
            PackageDetails();
        } catch (error) {
            setLoading(false)
            toast.error("Error updating itinerary. Please try again.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        }
    };

    const handleInsert = async () => {
        try {
            const token = localStorage.getItem("vendorToken");

            // Validation
            if (!formData.title) {
                toast.error("Title is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!formData.description) {
                toast.error("Activity description is required.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }
            if (!selectedHotelId) {
                toast.error("Select Hotel.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                return;
            }

            setLoading(true)

            const formData1 = new FormData();
            formData1.append("title", formData.title);
            formData1.append("day", formData.day);
            formData1.append("activity", formData.description);
            formData1.append("hotel_itienrary_id", selectedHotelId);
            formData1.append("package_id", id);
            if (formData.photoFile) {
                formData1.append("photo", formData.photoFile);
            }

            const response = await fetch(`${BASE_URL}itinerary`, {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData1,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            setLoading(false)
            const responseData = await response.json();
            toast.success("Itinerary Added successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            handleClose1();
            PackageDetails();
        } catch (error) {
            setLoading(false)
            toast.error("Error adding itinerary. Please try again.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
        }
    };


    const handleDelete = async () => {

        try {
            const token = localStorage.getItem("vendorToken");


            const response = await fetch(`${BASE_URL}package/${id}`, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                },
            });

            if (!response.ok) {
                toast.error("Error Deleting Package. Please try again.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
            } else {
                toast.success("Package Deleted Successfully", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 1000 });
                handleCloseDeleteDialogue()
                setTimeout(() => {
                    navigate('/vendor/mypackages')
                }, 2000);
            }

        } catch (error) {
            console.error("Error updating itineraries:", error);
        }
    };

    const fetchItineraryHotel = async () => {
        const token = localStorage.getItem("vendorToken");
        const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
        const data = await res.json();
        setHotelList(data?.data);
    };

    useEffect(() => {
        fetchItineraryHotel()
    }, []);

    const transformPriceAndDate = (priceAndDateArray) => {
        return priceAndDateArray?.map((item) => {
            // Convert price_start_date to a month name
            const date = new Date(item?.price_start_date);
            const monthName = date?.toLocaleString("default", { month: "long" });

            // Return the transformed object
            return {
                month_name: monthName,
                price_per_person: item?.price_per_person,
            };
        });
    };

    // Assuming pDetails[0].price_and_date is the array you want to transform
    const transformedArray = transformPriceAndDate(pDetails?.[0]?.price_and_date);

    const gotPercentageValue = (baseValue, percentage) => {
        const result = Number(baseValue) * (Number(percentage) / 100);
        return isNaN(result) ? 0 : result;
    };

    const gotFinalValue = (baseValue, percentage) => {
        const validPercentage = percentage != null ? Number(percentage) : 0; // Handle undefined or null percentage
        const percentageValue = Number(baseValue) * (validPercentage / 100);
        const result = percentageValue + Number(baseValue);
        return isNaN(result) ? 0 : result;
    };



    const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false)

    const handleCloseDeleteDialogue = () => {
        setOpenDeleteDialogue(false)
    }

    return (
        <>
            <Header2 />
            <div className='costum_container'>
                <My_pannel />
                <ToastContainer />
                <div>
                    <ProfileSection title="My Package List" />

                    <section className='py-5 mypackage_margin' style={{ backgroundColor: "whitesmoke", display: "flex", gap: "10px" }}>
                        {pDetails && pDetails?.map((ele) => {
                            return (
                                <div className="row">
                                    <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                                        <img
                                            src={ele.Place[0].photo}
                                            alt=''
                                            className='w-100 rounded h-md-50 meadia-for-package-details-img'
                                        />

                                        <div className='mt-3 hover-buttons-media'>
                                            <div className='me-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("overview")}
                                                >
                                                    Overview
                                                </button>
                                            </div>
                                            <div className='me-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("services")}
                                                >
                                                    Services
                                                </button>
                                            </div>
                                            <div className='me-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("itenarary")}
                                                >
                                                    Itinerary
                                                </button>
                                            </div>
                                            <div className='me-3'>
                                                <button
                                                    class='button-47'
                                                    role='button'
                                                    onClick={() => scrollTo("hotels")}
                                                >
                                                    Hotels
                                                </button>
                                            </div>
                                        </div>
                                        <Uppage />
                                        <section>
                                            <div className=''>
                                                <div className='overview-btn mb-4' ref={overRef}>
                                                    <button className='w-100 over-btn font700 text18 lh19 my-3'>
                                                        <img src='/c-icon.png' className='me-3' />
                                                        Overview
                                                    </button>
                                                    <section className='text-start'>
                                                        <div className=''>
                                                            <Row>

                                                                <div className='col-12 mb-3'>
                                                                    <div className='amh_to_hima'>
                                                                        <div>
                                                                            <h6 className=' pb-2 mb-0 cmntext font700 text18'>
                                                                                <FontAwesomeIcon
                                                                                    icon={faHotel}
                                                                                    className='cmnclr'
                                                                                />{" "}
                                                                                Hotel & Food
                                                                            </h6>
                                                                        </div>
                                                                        <div className=''>
                                                                            <div className=''>
                                                                                <div className=''>
                                                                                    <div className='cmn px-4 py-2'>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faDoorClosed}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Room Sharing</b> : {ele?.room_sharing}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faBowlFood}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal</b> : {ele?.meal_required?.join(",")}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faCookie}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Meal Type</b> : {ele?.meal_type}
                                                                                        </p>
                                                                                        <p className='cmnp py-1 text14'>
                                                                                            <FontAwesomeIcon
                                                                                                icon={faMountainCity}
                                                                                                className='cmnclr'
                                                                                            />{" "}
                                                                                            <b>Sightseeing</b> : {ele?.sightseeing}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='mt-3 mb-2'>
                                                                            {ele.travel_by === "Bus" && (
                                                                                <>
                                                                                    <h6 className='cmntext font700 text18'>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faBus}
                                                                                            className='cmnclr'
                                                                                        />{" "}
                                                                                        Travel By
                                                                                    </h6>
                                                                                </>
                                                                            )}
                                                                            {ele.travel_by === "Train" && (
                                                                                <>
                                                                                    <h6 className='cmntext font700 text18'>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faTrainSubway}
                                                                                            className='cmnclr'
                                                                                        />{" "}
                                                                                        Travel By
                                                                                    </h6>
                                                                                </>
                                                                            )}
                                                                            {ele.travel_by === "Flight" && (
                                                                                <>
                                                                                    <h6 className='cmntext font700 text18'>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faPlane}
                                                                                            className='cmnclr'
                                                                                        />{" "}
                                                                                        Travel By
                                                                                    </h6>
                                                                                </>
                                                                            )}
                                                                            {ele.travel_by === "Car" && (
                                                                                <>
                                                                                    <h6 className='cmntext font700 text18'>
                                                                                        <FontAwesomeIcon
                                                                                            icon={faTaxi}
                                                                                            className='cmnclr'
                                                                                        />{" "}
                                                                                        Travel By
                                                                                    </h6>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className='cmn'>
                                                                            <p className='cmnp ms-4 py-2 text14'>{ele?.travel_by}</p>
                                                                        </div>
                                                                        <div>
                                                                            <div className='mt-3 mb-2'>
                                                                                <h6 className='cmntext font700 text18'>
                                                                                    <FontAwesomeIcon
                                                                                        icon={faCalendarDays}
                                                                                        className='cmnclr'
                                                                                    />{" "}
                                                                                    Package Valid Between{" "}
                                                                                </h6>
                                                                            </div>
                                                                            <div className='cmn'>
                                                                                {pDetails?.[0]?.price_and_date && pDetails?.[0]?.price_and_date?.map((e) => (
                                                                                    <p className='cmnp ms-4 py-2 text14'>
                                                                                        {convertToRight(e?.price_start_date?.slice(0, 10))} to {convertToRight(e?.price_end_date?.slice(0, 10))}
                                                                                    </p>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Row>

                                                            <div className='amh_to_hima text-start mt-3'>
                                                                <div className='mb-2 text-start cmnp pb-2'>
                                                                    <h6 className='cmntext mb-1 font700 text18'>
                                                                        <FontAwesomeIcon
                                                                            icon={faHouseFloodWaterCircleArrowRight}
                                                                            className='cmnclr'
                                                                        />{" "}
                                                                        Other Details
                                                                    </h6>
                                                                </div>
                                                                <p className='cmnp pt-2 text14'>{ele?.more_details}</p>

                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <div className='mx-xl-0 mx-sm-0 mx-1'>
                                                        {ele.more_details}
                                                    </div> */}
                                                </div>
                                                <div className='details-service' ref={services}>
                                                    <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon className='me-3' icon={faBrush} /> Services</button>
                                                    <div className='media-services IE-border'>
                                                        <div className='row gx-0 padding-inside-row mx-4 mt-3 mb-4 '>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='hotel-Included me-3'>
                                                                    <h4 className="font700 text18 text-start">
                                                                        <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                                                        Included
                                                                    </h4>
                                                                    <div class='content-green'>
                                                                        {ele.include_service.map((e) => {
                                                                            return (
                                                                                <>
                                                                                    <p className="text14 d-flex align-items-start mb-0">
                                                                                        <FontAwesomeIcon icon={faCheck} className="text16 me-2" />
                                                                                        <span>
                                                                                            {e.include_services_value}
                                                                                        </span>
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-12'>
                                                                <div className='hotel-excluded ms-3'>
                                                                    <h4 className="font700 text18 text-start">
                                                                        <FontAwesomeIcon icon={faCircleXmark} />{" "}
                                                                        Excluded
                                                                    </h4>
                                                                    <div className='content-red'>
                                                                        {ele.exclude_service.map((e1) => {
                                                                            return (
                                                                                <>
                                                                                    <p className="text14 d-flex align-items-start mb-0">
                                                                                        <FontAwesomeIcon icon={faXmark} className="me-2 text16" />
                                                                                        <span>
                                                                                            {e1.exclude_services_value}
                                                                                        </span>
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className='details-service mt-4'
                                                    ref={itenarary}
                                                >
                                                    <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                                                        icon={faHandshakeAlt}
                                                        className='me-2'
                                                    />Itinerary</button>
                                                </div>

                                                {ele.Itinaries.length < Math.max(ele.total_days, ele.total_nights) &&
                                                    <div>
                                                        <button className="vendoraddpackageitinerary-add-button mb-3"
                                                            onClick={() => {
                                                                handleShow1();
                                                                setFormData({
                                                                    day: ele.Itinaries.length + 1,
                                                                });
                                                            }}
                                                        >Add Itinerary</button>
                                                    </div>
                                                }
                                                <div className='amh_to_hima'>
                                                    {ele.Itinaries
                                                        .sort((a, b) => a.day - b.day)
                                                        .slice(0, Math.max(ele.total_days, ele.total_nights))
                                                        .map((itinerary, index) => (
                                                            <div className="pb-2">
                                                                <h6 className="cmntext mb-2 font700 text18">Day {index + 1} <span>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        style={{ fontSize: "12px", color: "#09646D", margin: "10px" }}
                                                                        onClick={() => {
                                                                            setIsEdit(true);
                                                                            setFormData({
                                                                                title: itinerary.title,
                                                                                photoURL: itinerary.photo,
                                                                                hotelName: itinerary.hotel_name,
                                                                                description: itinerary.activity,
                                                                                day: itinerary.day,
                                                                                itineraryid: itinerary._id,
                                                                            });
                                                                            const hotelId = itinerary?.hotel_itienrary_id;
                                                                            setSelectedHotelId(hotelId)
                                                                            const selectedHotel = ele?.hotel_itienrary?.find((e) => e?._id === hotelId)
                                                                            setSelectedHotelName(selectedHotel.hotel_name)

                                                                            handleShow();
                                                                        }}
                                                                    />
                                                                </span> </h6>
                                                                <div className='cmn p-2'>

                                                                    <Row className='align-items-start'>
                                                                        <div className='col-lg-9'>
                                                                            <div className='py-1'>
                                                                                <h6 className='cmnp cmnclr1 text14 font600'>Title</h6>
                                                                                <p className='cmnp cmnclr font-weight-bolder text14'>
                                                                                    {itinerary.title}{" "}
                                                                                </p>
                                                                            </div>
                                                                            <div className='py-1'>
                                                                                <h6 className='cmnp cmnclr1 text14 font600'>Activity</h6>
                                                                                <p className='cmnp cmnclr font-weight-bolder text14'>
                                                                                    <div
                                                                                        style={{ textAlign: "justify" }}
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: itinerary.activity,
                                                                                        }}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        {itinerary.photo && <div className='col-lg-3'>
                                                                            <div className='text-end py-2'>
                                                                                <img src={itinerary.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                                                            </div>
                                                                        </div>}
                                                                    </Row>


                                                                </div>
                                                            </div>
                                                        ))}



                                                    <Modal show={open} onHide={handleClose}>
                                                        <Modal.Body>
                                                            <Modal.Title className="text20 font700">{isEdit ? 'Edit Itinerary' : 'Add Itinerary'}</Modal.Title>
                                                            <form>
                                                                <div className="form-group mt-4">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Title </p>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Title"
                                                                        name="title"
                                                                        value={formData.title}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-input"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Select Photos </p>
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={handleChange}
                                                                        name="photoFile"
                                                                        className="vendoraddpackageitinerary-photo-input"
                                                                    />
                                                                    {formData.photoFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(formData.photoFile)}
                                                                            alt="Preview"
                                                                            className="vendoraddpackageitinerary-preview-image mt-2"
                                                                        />
                                                                    ) :
                                                                        (

                                                                            formData.photoURL && (<img
                                                                                src={formData.photoURL}
                                                                                alt="Preview"
                                                                                className="vendoraddpackageitinerary-preview-image"
                                                                            />)

                                                                        )}
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <p className="mb-1 vendoraddpackage-label text12">Select Hotel </p>
                                                                    <div className='d-flex align-items-center'>
                                                                        <input
                                                                            type='text'
                                                                            name='select_hotel'
                                                                            className='vendoraddpackageitinerary-input text14'
                                                                            value={selectedHotelName}
                                                                            readOnly
                                                                        />
                                                                        <Button
                                                                            variant='primary'
                                                                            className='ms-2'
                                                                            onClick={() => setShowModal(true)}
                                                                            style={{
                                                                                backgroundColor: "#155E75"
                                                                            }}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Description </p>
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        name="description"
                                                                        value={formData.description}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-textarea"
                                                                        rows="4"
                                                                    />
                                                                </div>
                                                            </form>
                                                            <div className="d-flex justify-content-end">
                                                                <Button variant="secondary" onClick={handleClose}>
                                                                    Close
                                                                </Button>
                                                                {loading ? (
                                                                    <Button variant="primary" style={{ backgroundColor: "#155E75", marginLeft: "5px" }}>
                                                                        <img src={loader} alt="" className="spinner" style={{ height: "25px" }} />
                                                                    </Button>
                                                                ) : (
                                                                    <Button variant="primary" style={{ backgroundColor: "#155E75", marginLeft: "5px" }} onClick={handleSave}>
                                                                        Submit
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Modal.Body>

                                                    </Modal>


                                                    <Modal show={open1} onHide={handleClose1}>
                                                        <Modal.Body>
                                                            <Modal.Title className="text20 font700">{isEdit ? 'Edit Itinerary' : 'Add Itinerary'}</Modal.Title>
                                                            <form>
                                                                <div className="form-group mt-4">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Title </p>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Title"
                                                                        name="title"
                                                                        value={formData.title}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-input"
                                                                    />
                                                                </div>
                                                                <div className="form-group">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Select Photos </p>
                                                                    <input
                                                                        type="file"
                                                                        accept="image/*"
                                                                        onChange={handleChange}
                                                                        name="photoFile"
                                                                        className="vendoraddpackageitinerary-photo-input"
                                                                    />
                                                                    {formData.photoFile ? (
                                                                        <img
                                                                            src={URL.createObjectURL(formData.photoFile)}
                                                                            alt="Preview"
                                                                            className="vendoraddpackageitinerary-preview-image mt-2"
                                                                        />
                                                                    ) :
                                                                        (

                                                                            formData.photoURL && (<img
                                                                                src={formData.photoURL}
                                                                                alt="Preview"
                                                                                className="vendoraddpackageitinerary-preview-image"
                                                                            />)

                                                                        )}
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <p className="mb-1 vendoraddpackage-label text12">Select Hotel </p>
                                                                    <div className='d-flex align-items-center'>
                                                                        <input
                                                                            type='text'
                                                                            name='select_hotel'
                                                                            className='vendoraddpackageitinerary-input text14'
                                                                            value={selectedHotelName}
                                                                            readOnly
                                                                        />
                                                                        <Button
                                                                            variant='primary'
                                                                            className='ms-2'
                                                                            onClick={() => setShowModal(true)}
                                                                            style={{
                                                                                backgroundColor: "#155E75"
                                                                            }}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p className="mb-1 vendoraddpackage-label text12">Description </p>
                                                                    <textarea
                                                                        placeholder="Description"
                                                                        name="description"
                                                                        value={formData.description}
                                                                        onChange={handleChange}
                                                                        className="vendoraddpackageitinerary-textarea"
                                                                        rows="4"
                                                                    />
                                                                </div>
                                                            </form>
                                                            <div className="d-flex justify-content-end">
                                                                <Button variant="secondary" onClick={handleClose1}>
                                                                    Close
                                                                </Button>
                                                                {loading ? (
                                                                    <Button variant="primary" style={{ backgroundColor: "#155E75", marginLeft: "5px" }}>
                                                                        <img src={loader} alt="" className="spinner" style={{ height: "25px" }} />
                                                                    </Button>
                                                                ) : (
                                                                    <Button variant="primary" style={{ backgroundColor: "#155E75", marginLeft: "5px" }} onClick={handleInsert}>
                                                                        Submit
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </Modal.Body>

                                                    </Modal>

                                                </div>

                                            </div>
                                        </section>

                                        <div
                                            className='details-service mt-4'
                                            ref={hotels}
                                        >
                                            <button className='mb-3 font700 text18 lh19'><FontAwesomeIcon
                                                icon={faHotel}
                                                className='me-2'
                                            />Hotels</button>
                                        </div>

                                        <section className=''>
                                            <div className='hotels-section'>
                                                <div className='last-hotels'>

                                                    {/* <!--------------------------- part-1 ---------------------------------------------> */}

                                                    <div className=''>
                                                        {ele.hotel_itienrary && ele.hotel_itienrary
                                                            ?.sort((a, b) => {
                                                                // Convert the first value in the days string to a number and sort by that value
                                                                const firstDayA = Math.min(...a.days.split(',').map(Number));
                                                                const firstDayB = Math.min(...b.days.split(',').map(Number));
                                                                return firstDayA - firstDayB;
                                                            })?.map((e, index) => (
                                                                <div className={`cmn ${index !== ele?.hotel_itienrary?.length - 1 ? 'mb-4' : ''}`}>
                                                                    <div className='row gx-5 p-2 second-changes'>
                                                                        <div className='col-xl-6 col-12'>
                                                                            <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                                                                <div className="carousel-indicators">
                                                                                    {e?.hotel_photo?.map((_, idx) => (
                                                                                        <button
                                                                                            key={idx}
                                                                                            type="button"
                                                                                            data-bs-target={`#carouselExampleCaptions-${index}`}
                                                                                            data-bs-slide-to={idx}
                                                                                            className={idx === 0 ? "active" : ""}
                                                                                            aria-current={idx === 0 ? "true" : "false"}
                                                                                            aria-label={`Slide ${idx + 1}`}
                                                                                        ></button>
                                                                                    ))}
                                                                                </div>
                                                                                <div className="carousel-inner">
                                                                                    {e?.hotel_photo?.map((ele, idx) => (
                                                                                        <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                                                                            <img src={ele} className="d-block w-100" alt="..." style={{ height: '180px', objectFit: 'cover' }} />
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                                <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                    <span className="visually-hidden">Previous</span>
                                                                                </button>
                                                                                <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                    <span className="visually-hidden">Next</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-xl-6 col-12 first-change'>
                                                                            <div className='mb-md-2'>
                                                                                <span className='d-flex day1-2  text-left align-items-center'>
                                                                                    <div className='day-1-2 py-1 font-changes'>
                                                                                        <h6 className="font700 text18">
                                                                                            Day <br /> {e?.days?.split(',').sort((a, b) => a - b).join(', ')}
                                                                                        </h6>
                                                                                    </div>
                                                                                    <div className=' font-changes'>
                                                                                        <h6 className='mb-1 pe-5 font700 text18'>
                                                                                            {e?.hotel_name}
                                                                                        </h6>
                                                                                        <p className='mb-0 text-start text14'>
                                                                                            {e?.hotel_address}
                                                                                        </p>
                                                                                    </div>
                                                                                </span>
                                                                            </div>
                                                                            <div className='hotels-star'>
                                                                                <FontAwesomeIcon
                                                                                    icon={faStar}
                                                                                    className='p-1'
                                                                                    style={{ color: "yellow" }}
                                                                                />
                                                                                <FontAwesomeIcon
                                                                                    icon={faStar}
                                                                                    className='p-1'
                                                                                    style={{ color: "yellow" }}
                                                                                />
                                                                                <FontAwesomeIcon
                                                                                    icon={faStar}
                                                                                    className='p-1'
                                                                                    style={{ color: "yellow" }}
                                                                                />
                                                                                <FontAwesomeIcon
                                                                                    icon={faStar}
                                                                                    className='p-1'
                                                                                    style={{ color: "yellow" }}
                                                                                />
                                                                                <FontAwesomeIcon
                                                                                    icon={faStar}
                                                                                    className='p-1'
                                                                                    style={{ color: "yellow" }}
                                                                                />
                                                                            </div>
                                                                            <div className='hotels-footer'>
                                                                                <p className="text14">
                                                                                    {e?.hotel_description?.length > 100
                                                                                        ? e.hotel_description.slice(0, 100) + '...'  // Show first 100 characters with ellipsis if longer
                                                                                        : e?.hotel_description}  // Show the full description if it's 100 characters or less
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>


                                    </div>

                                    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                                        <Modal.Body>
                                            <div>
                                                <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
                                                <hr />
                                                {hotelList && hotelList.map((e, index) => (
                                                    <div key={index} className="d-flex align-items-start mb-3 mx-4 gap-3">
                                                        <input
                                                            type="radio"
                                                            name="selectedHotel"
                                                            value={e._id}
                                                            onChange={() => (setSelectedHotel(e), setSelectedHotelName(e?.hotel_name), setSelectedHotelId(e?._id))}
                                                            checked={selectedHotelId === e._id}
                                                            className="mt-2"
                                                        />
                                                        <div>
                                                            <p className="mb-1 cmnp">{e.hotel_name}</p>
                                                            <p className="mb-1 cmnp">{e?.hotel_address}</p>
                                                            <p className="mb-1 cmnp">{e?.hotel_city},{e?.hotel_state}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                                {hotelList?.length < 1 && (
                                                    <Button
                                                        variant="primary"
                                                        style={{
                                                            backgroundColor: "#155E75",
                                                        }}
                                                        onClick={() => navigate("/vendor/addhotels")}
                                                    >
                                                        Add Hotel
                                                    </Button>
                                                )}
                                                {hotelList?.length > 0 && (
                                                    <div className="d-flex justify-content-center mt-4 mb-3">
                                                        <Button
                                                            variant="primary"
                                                            style={{
                                                                backgroundColor: "#155E75",
                                                            }}
                                                            onClick={() => setShowModal(false)}
                                                        >
                                                            Select
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    <Modal show={openDeleteDialogue} onHide={handleCloseDeleteDialogue} centered>
                                        <Modal.Body className="px-4 py-5">
                                            <Modal.Title className="text24 font600 text-center">Are you sure you want to delete?</Modal.Title>
                                            <div className="d-flex justify-content-center gap-2 mt-4">
                                                <Button variant="secondary" onClick={handleCloseDeleteDialogue}>
                                                    No
                                                </Button>
                                                <Button variant="danger" onClick={handleDelete}>
                                                    Yes
                                                </Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>

                                    <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second'>
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <button
                                                style={{ backgroundColor: "red", padding: "10px 20px", border: "none", borderRadius: "10px", color: "white", margin: "0px 10px 10px 10px" }}
                                                onClick={() => setOpenDeleteDialogue(true)}
                                            >Delete Package</button>
                                            <button
                                                style={{ backgroundColor: "#09646D", padding: "10px 20px", border: "none", borderRadius: "10px", color: "white", margin: "0px 10px 10px 10px" }}
                                                onClick={() => navigate(`/vendor/editmypackage/${ele._id}`)}
                                            >Edit Package</button>
                                        </div>
                                        <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3 position_fixed'>
                                            <div className='inner_packega'>
                                                <div className=''>
                                                    <div className='border_bottom'>
                                                        <p className='text-19 mb-1 font700 text18 mb-3'>{ele.name}</p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="mb-2 d-flex align-items-start">
                                                                <p className='fw-bold pt-1 me-2 text14 mb-1'>
                                                                    {ele.destination[0].destination_name}
                                                                </p>
                                                                <p className='mb-0'>
                                                                    <button class='button-13' role='button'>
                                                                        {ele.total_days}D/{ele.total_nights}N
                                                                    </button>
                                                                </p>
                                                            </div>
                                                            {/* <div className='for-18000 mb-2'>
                                                                <div className='d-flex justify-content-end'>
                                                                    <p className=''></p>
                                                                    <p className='ms-2 mb-0 price-per-person-media text26 font700'>
                                                                        <FontAwesomeIcon
                                                                            className='pe-2'
                                                                            icon={faIndianRupeeSign}
                                                                        />
                                                                        {pricePerPerson}
                                                                    </p>
                                                                    <div className='per-person-media text-end'>
                                                                        per person
                                                                    </div>
                                                                </div>
                                                                <div className='Per_Person text-end'>
                                                                    *Excluding applicable taxes
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <div className="mb-3 d-flex align-items-center">
                                                            <p
                                                                className='mb-0 ms-1 me-2 text14 font700'
                                                            >
                                                                Package Type :
                                                            </p>
                                                            <button
                                                                class='button-17 me-1'
                                                                role='button'
                                                            >
                                                                {ele.package_type}
                                                            </button>
                                                        </div>
                                                        {ele.destination_category_id.length > 0 ? (
                                                            <>
                                                                <div className='mb-4 d-flex align-items-center flex-wrap gap-2'>
                                                                    <p
                                                                        className='mb-0 ms-1 me-2 text14 font700'
                                                                    >
                                                                        Package Best For :
                                                                    </p>
                                                                    {ele.destination_category_id.map((e) => {
                                                                        return (
                                                                            <>
                                                                                <button
                                                                                    class='button-17'
                                                                                    role='button'
                                                                                >
                                                                                    {e.category_name}
                                                                                </button>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <div className='pad_img d-flex text-center justify-content-start gap-2 ms-2'>
                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={faHotel}
                                                                    className='media-for-icon text22'
                                                                />
                                                                <br />
                                                                {ele.hotel_type.map(a =>
                                                                    <div style={{ fontSize: "12px" }}>{a} Stars</div>
                                                                )}
                                                            </div>
                                                            <div className='mx-3'>
                                                                <FontAwesomeIcon
                                                                    icon={faUtensils}
                                                                    className='media-for-icon text22'
                                                                />
                                                                <br />
                                                                <span>Meals ({ele.meal_type})</span>
                                                                <div title={ele.meal_required} style={{ fontSize: "12px" }}>
                                                                    {ele.meal_required.map(a => a.charAt(0)).join(', ')}
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <FontAwesomeIcon
                                                                    icon={faBinoculars}
                                                                    className='media-for-icon text22'
                                                                />
                                                                <br />
                                                                <p className="text12 font500 mt-1 mb-0">Sightseeing</p>
                                                                <p className="text12 font500  mb-0">{ele.sightseeing}</p>
                                                            </div>
                                                            <div className='mx-3'>
                                                                {ele.travel_by === "Bus" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faBus}
                                                                            className='media-for-icon text22'
                                                                        />
                                                                        <br />
                                                                        <span className="text12">Bus</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Train" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faTrainSubway}
                                                                            className='media-for-icon text22'
                                                                        />
                                                                        <br />
                                                                        <span className="text12">Train</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Flight" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faPlane}
                                                                            className='media-for-icon text22'
                                                                        />
                                                                        <br />
                                                                        <span className="text12">Flight</span>
                                                                    </>
                                                                )}
                                                                {ele.travel_by === "Car" && (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faTaxi}
                                                                            className='media-for-icon text22'
                                                                        />
                                                                        <br />
                                                                        <span className="text12">Car</span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3'>
                                            <div className='inner_packega'>
                                                <div className="margin-pricing-agency">
                                                    <div className="margin-pricing-agency-header">
                                                        <h5 className="text13 text-center">Date</h5>
                                                        <h5 className="text13 d-flex justify-content-center">Price per Person</h5> {/* Updated header */}
                                                        <h5 className="text13 d-flex justify-content-center">Admin Margin</h5>
                                                        <h5 className="text13 d-flex justify-content-center">Final Price</h5>
                                                    </div>
                                                    <hr />

                                                    {pDetails?.[0]?.price_and_date?.map((updatedItem) => {

                                                        const matching = getMonthWithMostDaysInRange(updatedItem.price_start_date, updatedItem?.price_end_date)

                                                        const matchingItem = ele?.profitMargin?.[0]?.month_and_margin_user?.find(
                                                            (e) => e.month_name === matching
                                                        );

                                                        return (
                                                            <div className="margin-pricing-agency-body mb-3" key={updatedItem?.month_name}>
                                                                <p className="d-flex align-items-center justify-content-center mb-0 text13 font600">{formatDate(updatedItem.price_start_date)} <br /> to <br /> {formatDate(updatedItem?.price_end_date)}</p>
                                                                <div className="d-flex flex-column align-items-center">
                                                                    <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{updatedItem.price_per_person}(A)</p>
                                                                    <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{updatedItem.child_price}(C)</p>
                                                                    <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{updatedItem.infant_price}(I)</p>
                                                                </div>

                                                                {matchingItem ? (
                                                                    <>
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotPercentageValue(updatedItem.price_per_person, matchingItem.margin_percentage)}({matchingItem.margin_percentage ? matchingItem.margin_percentage : 0}%)</p>
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotPercentageValue(updatedItem.child_price, matchingItem.margin_percentage)}({matchingItem.margin_percentage ? matchingItem.margin_percentage : 0}%)</p>
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotPercentageValue(updatedItem.infant_price, matchingItem.margin_percentage)}({matchingItem.margin_percentage ? matchingItem.margin_percentage : 0}%)</p>
                                                                        </div>
                                                                        <div className="d-flex flex-column align-items-center">
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotFinalValue(updatedItem.price_per_person, matchingItem.margin_percentage)}</p>
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotFinalValue(updatedItem.child_price, matchingItem.margin_percentage)}</p>
                                                                            <p className="d-flex align-items-center mb-0 text13 font600"><FontAwesomeIcon icon={faIndianRupeeSign} />{gotFinalValue(updatedItem.infant_price, matchingItem.margin_percentage)}</p>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <p>N/A</p>
                                                                        <p>N/A</p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                    </section>
                </div>
            </div>
        </>
    )
}

export default ViewMyPackage;
