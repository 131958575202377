import React, { useState, useEffect } from "react"
import Header from "./Header"
import { Container, Row } from "react-bootstrap"
import "./css/Blog.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAddressBook, faMessage } from "@fortawesome/free-solid-svg-icons"
import { BASE_URL } from "../BASE_URL"
import { useNavigate, useParams } from "react-router-dom"
import Footer from "./Footer"

function Blog1(props) {
  const [blogLits, setBlogLists] = useState([])
  const { id } = useParams()

  const BlogList = async () => {
    const res = await fetch(`${BASE_URL}blogger/detail?_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBlogLists(data.data)
  }

  useEffect(() => {
    BlogList()
  }, [])

  return (
    <div>
      <Header />
      <section>
        {blogLits.map((ele) => {
          return (
            <>
              <Container>
                <div className='heading pt-xl-5 pt-lg-5 pt-md-4 pt-3'>
                  <Row>
                    <div className='col-12 part-1 mb-xl-2 mb-md-2'>
                      <div>
                        <h1 className='part-1 text30 font700'>{ele?.blog_title}</h1>
                        <p className='part-2'>Published on {ele?.createdAt?.slice(0, 10)}</p>
                      </div>
                    </div>

                    <div className='col-lg-10 col-md-10'>
                      <div className='part_4 pt-2 text16' dangerouslySetInnerHTML={{ __html: ele.blog_content }}></div>
                    </div>
                    <div className='col-lg-2 col-md-2 text-center'>
                      {ele.blogger_syt.map((e) => {
                        return (
                          <>
                            <img
                              src={e?.blog_owner_photo}
                              alt=''
                              className='img-fluid'
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                              }}
                            />
                            <h5 className='pt-2 m-0 p-0'>
                              {ele?.blogger_syt?.map((e) => {
                                return <p className="font600 mb-1">{e?.blog_owner_name}</p>
                              })}
                            </h5>
                            <p className='part-5 text15'>{ele?.blog_category}</p>
                          </>
                        )
                      })}
                    </div>
                  </Row>
                </div>

                <div className='section blog-1'>
                  <div>
                    <div className='text-center pt-2'>
                      <img
                        src={ele?.blog_title_photo}
                        className='img-fluid'
                        alt={ele?.blog_title}
                      />
                    </div>
                  </div>
                </div>

                <div className='section-1'>
                  <div className='part-7 py-2'>
                    <ul>
                      {ele?.blog_title_points?.map((e) => {
                        return (
                          <>
                            <li className="text14">{e}</li>
                          </>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </Container>
            </>
          )
        })}
      </section>
      <hr />
      <Footer />
    </div>
  )
}

export default Blog1
