import React from "react"
import { Container } from "react-bootstrap"
import "./css/index1.css"
import Footer from "./Footer"
import Header from "./Header"

import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

function Contact(props) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <><div>
      <Header />
      <section className='my-4'>
        <div className='container'>
          <div className='contact_us_header'>
            <div className='text-center'>
              <img src='/contact.png' alt='' className='img-fluid' />
            </div>
            <div className='Cont-Us'>
              <h1 className='text-white mb-0 ' style={{ lineHeight: "1" }}>
                CONTACT
              </h1>
              <h1>US</h1>
            </div>
          </div>
          <div className='row'>
            <div
              className='col-md-6 col-12 pt-5 ps-5'
              style={{ borderRight: mobile ? "unset" : "1px solid grey" }}
            >
              <div className='pt-4'>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src='contact1.png' alt='' classname='img-fluid' />
                </div>
                {/* <h6 classname="is-short mt-3 ">LIFE <span>---------</span><br />
                                    IS SHORT <br />
                                    BOOK A TRIP
                                </h6> */}
              </div>
            </div>
            <div className='col-md-6 col-12 pt-5 ps-4'>
              <div>
                <div classname='d-flex'>
                  <div classname='me-2'>
                    <i classname='fa-solid fa-location-dot'></i>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "600", fontSize: "22px" }}>
                      Company Address
                    </h6>
                    <p>
                      22 Cradle, EDII,Gandhinagar - Ahmedabad Rd,Next to Apollo
                      Hospital, Bhat, Ahmedabad, Gujarat 382428
                    </p>
                  </div>
                </div>
                <div classname='d-flex'>
                  <div classname='me-2 text-dark'>
                    <i
                      classname='fa-solid fa-phone'
                      style={{ height: "10px", width: "10px" }}
                    ></i>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "600", fontSize: "22px" }}>
                      Contact Number
                    </h6>
                    <p>
                      <a
                        href='tel:+919033251903'
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        +91 90332 51903
                      </a>
                    </p>
                  </div>
                </div>
                <div classname='d-flex'>
                  <div classname='me-2'>
                    <i classname='fa-solid fa-envelope'></i>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "600", fontSize: "22px" }}>
                      Email ID
                    </h6>
                    <p>
                      <a
                        href='mailto:info@startyourtour.com'
                        style={{ color: "black", textDecoration: "none" }}
                      >
                        info@startyourtour.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section classname='py-4'>
        <Container>
          <div classname='row'>
            <div classname='col-12'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.5289413004757!2d72.62640827477291!3d23.114333712898596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8177958dc771%3A0x3feda1e8ce26488!2sEntrepreneurship%20Development%20Institute%20Of%20India!5e0!3m2!1sen!2sin!4v1705576344296!5m2!1sen!2sin'
                width='100%'
                height='450'
                style={{ border: "none" }}
                allowfullscreen=''
                loading='lazy'
                referrerpolicy='no-referrer-when-downgrade'
              ></iframe>
            </div>
          </div>
        </Container>
      </section>
      </div>
      <hr />
      <Footer />
    </>
  )
}

export default Contact
