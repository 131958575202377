import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendUp
} from "@fortawesome/free-solid-svg-icons";
import My_pannel from "./My_pannel";
import Header2 from "./Header2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../BASE_URL";
import construction from "../images/vendor-homepage-working.png"
import ProfileSection from "./SecondNavbar";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { Legend } from 'recharts';
import { BarChart, Bar, Rectangle } from 'recharts';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts';


const Home_page = () => {

  const navigate = useNavigate();

  const data2 = [
    {
      city: 'New York',
      bookings: 200,
      fullMark: 300,
    },
    {
      city: 'Los Angeles',
      bookings: 180,
      fullMark: 300,
    },
    {
      city: 'Chicago',
      bookings: 150,
      fullMark: 300,
    },
    {
      city: 'Houston',
      bookings: 170,
      fullMark: 300,
    },
    {
      city: 'Phoenix',
      bookings: 160,
      fullMark: 300,
    },
    {
      city: 'Philadelphia',
      bookings: 190,
      fullMark: 300,
    },
  ];


  const [dashboardCounts, setDashboardCounts] = useState("")

  const fetchDashboardData = async () => {
    const vendorToken = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/agencydashboard`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": vendorToken,
      },
    });
    const data = await res.json();
    console.log(data?.data);
    setDashboardCounts(data?.data)
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const handleViewBookingDetails = (e) => {
    navigate(`/vendor/Book-packega/details/${e}`)
  }

  return (
    <>
      <Header2 />
      <div className="costum_container">
        <My_pannel />
        <div>
          <ProfileSection title="Dashboard" />
          {/* <img src={construction} className="img-fluid mt-5" alt="" /> */}
          <div className="row mt-5 ps-4">

            <div className="col-12 col-sm-8">
              <div className="row gy-4 gx-3">
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Revenue</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalRevenue}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Booked Package</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalBooking}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Submitted Bid</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalSubmittedBid}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Accepted Bid</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalAcceptedBid}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Rejected Bid</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalRejectedBid}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div>
                    <div className="dashboard-container">
                      <div className="dashboard-flex">
                        <div>
                          <p className="dashboard-total-user">Total Ready-Made Package</p>
                          <h4 className="dashboard-user-count">{dashboardCounts?.totalReadyPackage}</h4>
                        </div>
                        <img src="" alt="" className="dashboard-image" />
                      </div>
                      <p className="dashboard-percentage">
                        <span className="dashboard-percentage-up">
                          <FontAwesomeIcon icon={faArrowTrendUp} />&nbsp;&nbsp;8.5%
                        </span>
                        &nbsp;Up from yesterday
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4 col-sm-2">
                  <div className="dashboard-container">
                    <div className="dashboard-flex">
                      <div>
                        <p className="dashboard-active-user">Active User</p>
                        <h4 className="dashboard-user-ratio">
                          <span className="dashboard-user-count">27</span>/80
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="mt-4 mb-4">
                  <div class="dashboard-overflow-x-auto">
                    <table class="dashboard-table">
                      <thead>
                        <tr class="dashboard-thead-tr">
                          <th class="dashboard-th">Fullname</th>
                          <th class="dashboard-th">Departure</th>
                          <th class="dashboard-th">Destination</th>
                          <th class="dashboard-th">Contact Number</th>
                          <th class="dashboard-th">Action</th>
                          {/* <th class="dashboard-th">Status</th> */}
                        </tr>
                      </thead>
                      <tbody>

                        {dashboardCounts?.top5Booking && dashboardCounts?.top5Booking?.map((e) => (
                          <tr class="dashboard-tbody-tr">
                            <td class="dashboard-td">{e?.user_name}</td>
                            <td class="dashboard-td">{e?.departure}</td>
                            <td class="dashboard-td">{e?.destination}</td>
                            <td class="dashboard-td">{e?.contact_number}</td>
                            <td class="dashboard-td" onClick={() => handleViewBookingDetails(e?._id)} style={{cursor: "pointer"}}>view</td>
                            {/* <td class="dashboard-td">
                              <span class="dashboard-status-button">{e?.status}</span>
                            </td> */}
                          </tr>
                        ))}

                      </tbody>
                    </table>
                  </div>


                </div>
              </div>
            </div>

            <div className="col-12 col-sm-4">

              <div className="dashboard-container">
                <h5 className="m-2">Top 6 States by Bookings</h5>
                <ResponsiveContainer width="100%" height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={dashboardCounts?.topStates}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="state" />
                    <PolarRadiusAxis angle={30} domain={[0, 50]} />
                    <Radar name="bookings" dataKey="bookings" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer>
              </div>


              <div className="dashboard-container mt-4">
                <h5 className="m-2">Top 5 Cities On This Platform</h5>
                <ResponsiveContainer width="100%" height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data2}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="city" />
                    <PolarRadiusAxis angle={30} domain={[0, 300]} />
                    <Radar name="Bookings" dataKey="bookings" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="col-12 col-sm-8 mb-4">
              <div className="dashboard-container">
                <h5 className="mx-2 mt-3 mb-4">Past 6 Months Booking Graph</h5>
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    width={550}
                    height={300}
                    data={dashboardCounts?.bookingDate} // replace with your data array
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="package" fill="#8884d8" />
                    <Bar yAxisId="right" dataKey="bid" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>

            </div>
            <div className="col-12 col-sm-8 mb-4">
              <div className="dashboard-container">
                <h5 className="mx-2 mt-3 mb-4">Past 6 Months Booking Accepted Rejected Graph</h5>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart
                    width={500}
                    height={400}
                    data={dashboardCounts?.bidStatusData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="accepted" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="rejected" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            {/* <div className="col-8 mb-5">
              <div>
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart width={800} height={250} data={salesDetails} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_page;
