import React from "react";
import Header from "../Project/Header";
import { useState } from "react";
import index1 from "../Project/css/index1.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import axios from "axios";
import loader from "../images/loading.png"

function VerifyOtp() {
  const location = useLocation();
  console.log(location)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState("");

  const handleOtp = (event) => {
    const inputNumber = event.target.value;
    if (inputNumber.length <= 6) {
      setOtp(inputNumber);
    }
  };

  const handleSubmit = async () => {
    if (!otp) {
      toast.error('Please Enter Valid OTP', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    setLoading(true)
    try {
      const response = await axios.post(`${BASE_URL}agency/verify-otp`, {
        contact: Number(location.state),
        otp: Number(otp),
      });

      if (response.status === 200) {
        setLoading(false)
        toast.success('OTP Verified Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/vendor/newpassword", { state: location?.state });
        }, 2000)
      } else {
        setLoading(false)
        toast.error('Failed to verify OTP. Please try again.', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      setLoading(false)
      toast.error('Error verifying OTP. Please check your network connection and try again.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } finally {
      setLoading(false)
    }
  };

  return (
    <>
      <Header />
      <div className='container'>
        <div className='forgot_not'>
          <div className='forgot_pass'>
            <div className='forgot_box'>
              <div className='text-center mt-3'>
                <h2 className="text28 font700">Verify Your Otp</h2>
              </div>
              <div className='mobile_number_box'>
                <div className='mobile_number_forgot text-start'>
                  <p className='mb-1 text16'>Enter OTP</p>
                  <input
                    type='tel'
                    name='otp'
                    id='otp'
                    maxLength={6} // Ensures only 6 digits can be entered
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                      handleOtp(e);
                    }}
                    value={otp}
                    onChange={handleOtp}
                  />
                  <ToastContainer />
                </div>
              </div>
              {loading ? (
                <div className='mobile_btn'>
                  <button
                    type='submit'
                    className="login-modal-btn"
                  >
                    <img src={loader} alt="" className="spinner" />
                  </button>
                </div>
              ) : (
                <div className='mobile_btn'>
                  <button onClick={handleSubmit}>Next</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyOtp;
